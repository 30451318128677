import React, { ReactNode } from 'react';
import { Flex } from 'glints-aries/es';
import { Typography, useModal } from 'glints-aries/es/@next';
import { space8, space12 } from 'glints-aries/es/@next/utilities/spacing';
import {
  defineMessages,
  FormattedDate,
  FormattedMessage,
  MessageDescriptor,
} from 'react-intl';
import styled from 'styled-components';

const messages = defineMessages({
  reviewProcessTypicallyTakes: {
    id: 'text-review-process-typically-takes',
    defaultMessage: '<b>Review Process:</b> Typically takes 1-2 business days.',
  },
  youWillReceiveNotifi: {
    id: 'text-notification-you-will-receive-an',
    defaultMessage:
      "<b>Notification:</b> You'll receive an email once verified.",
  },
  accessUponVerification: {
    id: 'text-access-upon-verification-you-will',
    defaultMessage:
      "<b>Access:</b> Upon verification, you'll have full access to all employer features.",
  },
  jobNewlyCreated: {
    id: 'text-job-your-newly-created',
    defaultMessage:
      '<b>Job:</b> Your newly created job will automatically be posted',
  },
});
const nextStepsMessages: MessageDescriptor[] = [
  messages.reviewProcessTypicallyTakes,
  messages.youWillReceiveNotifi,
  messages.accessUponVerification,
  messages.jobNewlyCreated,
];

const nextStepsMessagesForTierUpgrade: MessageDescriptor[] = [
  messages.reviewProcessTypicallyTakes,
  messages.youWillReceiveNotifi,
];

const ListItem = styled(Typography)`
  ::before {
    content: '\u2022';
    padding-inline-start: 10px;
    margin-right: ${space8};
  }
`;

type ModalProps = {
  submittedDate: string;
  isTierUpgrade: boolean;
};
function ModalContent(props: ModalProps) {
  const nextStepOptions = props.isTierUpgrade
    ? nextStepsMessagesForTierUpgrade
    : nextStepsMessages;
  return (
    <Flex flexDirection="column" gap="20px">
      <img
        src="images/tier-verification/verification-in-progress-badge.svg"
        style={{
          width: 72,
          height: 72,
        }}
      />

      <Flex flexDirection="column" gap={space12}>
        <Typography variant="body2">
          <FormattedMessage
            id="text-submission-date"
            defaultMessage="Submission Date: {date}"
            values={{
              date: (
                <FormattedDate
                  value={props.submittedDate}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              ),
            }}
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="text-thank-you-for-submitting"
            defaultMessage="Thank you for submitting your company information. Our team is reviewing your details for accuracy and compliance."
          />
        </Typography>
        <div>
          <Typography variant="body2">
            <FormattedMessage
              id="text-next-steps"
              defaultMessage="Next Steps:"
            />
          </Typography>
          {nextStepOptions.map(message => (
            <ListItem variant="body1" key={message.id}>
              <FormattedMessage
                {...message}
                values={{
                  b: (...chunks: ReactNode[]) => (
                    <Typography variant="body2" as="span">
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </ListItem>
          ))}
        </div>

        <Typography variant="body1">
          <FormattedMessage
            id="text-if-you-need-further"
            defaultMessage="If you need further assistance, please reach out to our support team at <link1>hello@glints.com</link1>"
            values={{
              link1: (chunks: ReactNode[]) => (
                <a href={`mailto:${chunks}`}>{chunks}</a>
              ),
            }}
          />
        </Typography>
      </Flex>
    </Flex>
  );
}
export default function useCompanyVerificationSubmittedModal() {
  const { open, close } = useModal();
  const openModal = (props: ModalProps) => {
    open({
      primaryAction: {
        onClick: close,
        label: (
          <Typography variant="button">
            <FormattedMessage id="interactive-close" defaultMessage="Close" />
          </Typography>
        ),
      },
      header: (
        <FormattedMessage
          id="text-company-verification-submitted"
          defaultMessage="Company Verification Submitted"
        />
      ),
      children: <ModalContent {...props} />,
      style: {
        width: '620px',
      },
      onClose: close,
      closeOnClickOutside: true,
    });
  };

  return {
    openModal,
  };
}
