import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { ROUTES } from '../../../common/routes';
import useGetAlternativeHeaderContent from './useGetAlternativeHeaderContent';

type AlternativeHeaderState =
  | {
      showAlertNativeHeader: true;
      alternativeHeaderContent: JSX.Element | null;
    }
  | {
      showAlertNativeHeader: false;
      alternativeHeaderContent: null;
    };

export default function useShowAlternativeHeader() {
  const { pathname } = useLocation();
  const [
    { showAlertNativeHeader, alternativeHeaderContent },
    setShowAlternativeHeader,
  ] = useState<AlternativeHeaderState>({
    showAlertNativeHeader: false,
    alternativeHeaderContent: null,
  });
  const alternativeHeaderContentBasedOnPathName =
    useGetAlternativeHeaderContent();

  const pathNameWithoutTrailingSlash = useMemo(() => {
    if (pathname === '/') {
      return pathname;
    }
    return pathname.replace(/\/+$/, '');
  }, [pathname]);

  const contentComponent =
    alternativeHeaderContentBasedOnPathName[pathNameWithoutTrailingSlash];

  const showHeader = Object.keys(
    alternativeHeaderContentBasedOnPathName
  ).includes(pathNameWithoutTrailingSlash);

  const routesWithNoNavigation = [
    ROUTES.companyReportStatus,
    ROUTES.onboarding,
  ];

  return {
    showAlternativeHeader: showAlertNativeHeader || showHeader,
    alternativeHeaderContent:
      alternativeHeaderContent || contentComponent?.navbarContent,
    alternativeSidebarContent: contentComponent?.sidebarContent,
    preventNavigation: routesWithNoNavigation.some(route =>
      pathname.includes(route)
    ),
    setShowAlternativeHeader,
  } as const;
}
