import React, { ReactNode } from 'react';

import useIsCountryCodeIndonesia from '../common/hooks/useIsCountryCodeIndonesia';

type Props = {
  children: ReactNode;
};

export default function GlintsVIPComponentWrapper({ children }: Props) {
  const isCountryCodeIndonesia = useIsCountryCodeIndonesia();

  return isCountryCodeIndonesia ? <>{children}</> : null;
}
