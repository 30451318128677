import { COUNTRY_CALLING_CODES, COUNTRY_DOMAINS } from '../../common/constants';
import { getConfig } from '../../config';

export const getMobileNumberCountryCode = () => {
  const countryCode = getConfig().COUNTRY;

  if (Object.values(COUNTRY_DOMAINS).includes(countryCode))
    return COUNTRY_CALLING_CODES[countryCode];

  return '';
};
