import {
  QueryClient,
  QueryKey,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { ReactQueryKeys } from '../../react-query-keys';
import { useAxiosWithAPI } from '../useAxiosWithAPI';

type Params = {
  channelIds: string[];
};

type Response = {
  data: {
    id: string;
    count: number;
  }[];
};

const isChannelsUnreadCountQueryKey = (
  queryKey: QueryKey
): queryKey is ReturnType<typeof ReactQueryKeys.channelsUnreadCount> =>
  queryKey[0] === 'channelsUnreadCount';

export const updateChannelsUnreadCountCache = (
  queryClient: QueryClient,
  channelId: string,
  count: number
) => {
  queryClient.setQueriesData<Response>(
    {
      predicate(query) {
        return Boolean(
          isChannelsUnreadCountQueryKey(query.queryKey) &&
            query.queryKey?.[1][channelId]
        );
      },
    },
    oldData => {
      if (!oldData) {
        return;
      }

      const newData = oldData.data.map(channel => {
        if (channel.id === channelId) {
          return {
            ...channel,
            count,
          };
        }

        return channel;
      });

      return {
        ...oldData,
        data: newData,
      };
    }
  );
};

function buildChannelIdsQueryFunction(
  axios: AxiosInstance,
  channelIds: string[]
) {
  return async () => {
    if (!channelIds.length) {
      return {
        data: [],
      };
    }

    const channelIdsParam = new URLSearchParams();

    channelIds.forEach(channelId => {
      channelIdsParam.append('channelIDs', channelId);
    });

    const response = await axios.get<Response>(
      `/glints-chat-v2/channels/unread?${channelIdsParam.toString()}`
    );

    return response.data;
  };
}

function transformDataToRecord(data: Response) {
  return data.data.reduce(
    (channelUnreadObject, { id, count }) => {
      channelUnreadObject[id] = count;
      return channelUnreadObject;
    },
    {} as Record<string, number>
  );
}

export default function useGetChannelsUnreadCount({ channelIds }: Params) {
  const axios = useAxiosWithAPI();

  const { data } = useSuspenseQuery({
    queryKey: ReactQueryKeys.channelsUnreadCount(channelIds),
    queryFn: buildChannelIdsQueryFunction(axios, channelIds),
    select: transformDataToRecord,
    retry: 1,
  });

  return {
    channelsUnreadCount: data,
  };
}

export function useGetChannelsUnreadCountQuery({ channelIds }: Params) {
  const axios = useAxiosWithAPI();

  const { data } = useQuery({
    queryKey: ReactQueryKeys.channelsUnreadCount(channelIds),
    queryFn: buildChannelIdsQueryFunction(axios, channelIds),
    select: transformDataToRecord,
    retry: 1,
  });

  return {
    channelsUnreadCount: data || {},
  };
}
