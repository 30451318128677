import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { VN_COUNTRY_LOCATION } from '../../../../common/constants';
import useGetCompanyVerificationInfo from '../../../../common/hooks/useGetCompanyVerificationInfo';
import { Company } from '../../../../common/interfaces/entities';
import CompanyTierVerificationModal from '../../../../components/Modal/CompanyTierVerificationModal/CompanyTierVerificationModal';
import { getSessionCompany } from '../../../../selectors/session';
import CompanyVerificationModal from './CompanyVerificationModal';
import VNCompanyVerificationModal from './VNCompanyVerificationModal';

type CompanyVerificationModalVariantControllerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CompanyVerificationModalVariantController = ({
  isOpen,
  onClose,
}: CompanyVerificationModalVariantControllerProps) => {
  const company: Company | undefined = useSelector(getSessionCompany);
  const { canShowCompanyVerificationModal } = useGetCompanyVerificationInfo();

  return (
    <Choose>
      <When condition={company?.CountryCode === VN_COUNTRY_LOCATION.code}>
        <VNCompanyVerificationModal
          company={company as Company}
          isOpen={isOpen}
          onClose={onClose}
        />
      </When>
      <Otherwise>
        <Choose>
          <When condition={canShowCompanyVerificationModal}>
            <Suspense fallback={null}>
              {isOpen && <CompanyTierVerificationModal onClose={onClose} />}
            </Suspense>
          </When>
          <Otherwise>
            <CompanyVerificationModal isOpen={isOpen} onClose={onClose} />
          </Otherwise>
        </Choose>
      </Otherwise>
    </Choose>
  );
};

export default CompanyVerificationModalVariantController;
