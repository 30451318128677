import React, { useState } from 'react';
import { Flex } from 'glints-aries/es';
import { Icon, Typography } from 'glints-aries/es/@next';
import { Blue, Orange } from 'glints-aries/es/@next/utilities/colors';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { Company } from '../../../common/interfaces/entities';
import CompanyVerificationModalVariantController from '../../../modules/CompanyDashboardV2/CompanyHeader/components/CompanyVerificationModalVariantController';
import NodesJoin from '../../NodesJoin';
import { StickyContainer, StyledBanner } from '../styles.sc';

type Props = {
  setFixedBannerHeight: (height: number) => void;
  company: Pick<Company, 'verificationInfo'>;
};

const messages = defineMessages({
  verifyCompanyToPostJob: {
    id: 'text-verify-your-company-to',
    defaultMessage:
      'Verify your company to post your job and gain access to our many features.',
  },
  verifyCompany: {
    id: 'interactive-verify-company',
    defaultMessage: 'Verify Company',
  },
});

const BannerWrapper = styled.div`
  svg {
    fill: ${Orange.S87};
  }
`;
export default function VerifyCompanyBanner({ setFixedBannerHeight }: Props) {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);

  const [openVerificationModal, closeVerificationModal] = [
    () => setIsVerificationModalOpen(true),
    () => setIsVerificationModalOpen(false),
  ];
  return (
    <StickyContainer ref={ref}>
      <CompanyVerificationModalVariantController
        isOpen={isVerificationModalOpen}
        onClose={closeVerificationModal}
      />
      <BannerWrapper>
        <StyledBanner
          type="fixed"
          status="warning"
          showIcon={false}
          dismissable={false}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            gap={space12}
            style={{
              width: '100%',
            }}
          >
            <Icon name="ri-error-warning-fill" fill={Orange.S87} />
            <Typography variant="body2" as="div">
              <NodesJoin separator={' '}>
                <FormattedMessage
                  {...messages.verifyCompanyToPostJob}
                  tagName="span"
                />
                <span onClick={openVerificationModal}>
                  <Typography
                    variant="body2"
                    color={Blue.S99}
                    as="span"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <FormattedMessage {...messages.verifyCompany} />
                  </Typography>
                </span>
              </NodesJoin>
            </Typography>
          </Flex>
        </StyledBanner>
      </BannerWrapper>
    </StickyContainer>
  );
}
