import React, { ReactNode, useState } from 'react';
import { Flex } from 'glints-aries/es';
import { Icon, Popover, Typography } from 'glints-aries/es/@next';
import { Blue } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import styled, { createGlobalStyle } from 'styled-components';

const ContentContainer = styled.div`
  padding: ${space16};
  display: flex;
  flex-direction: column;
  gap: ${space4};
`;

const ListItem = styled(Typography)`
  ::before {
    content: '\u2022';
    padding-inline-start: ${space8};
    margin-right: ${space8};
  }
`;

const NestedListItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  gap: ${space4};
  padding-inline-start: 30px;
  ::before {
    padding-inline-start: 0;
  }
`;

type PopoverProps = {
  title: ReactNode;
  items: {
    title: ReactNode;
    items: ReactNode[];
  }[];
  images: {
    src: string;
    width?: string;
    height?: string;
  }[];
  imagesDirection?: 'row' | 'column';
};

export const CustomPopoverStyles = createGlobalStyle<{
  imagesDirection: 'row' | 'column';
}>`
  .Polaris-Popover__Content {
    height: max-content !important;
    max-width: 400px !important;
  }
`;

export default function VerificationPopover({
  title,
  items,
  images,
  imagesDirection = 'column',
}: PopoverProps) {
  const [isPopoverActive, setIsPopoverActive] = useState(false);
  const [openPopover, closePopover] = [
    () => setIsPopoverActive(true),
    () => setIsPopoverActive(false),
  ];
  const activator = (
    <Icon name="ri-information-line" width={24} height={24} fill={Blue.S99} />
  );
  return (
    <div onMouseEnter={openPopover} onMouseLeave={closePopover}>
      <CustomPopoverStyles imagesDirection={imagesDirection} />
      <Popover
        active={isPopoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={closePopover}
        preferredAlignment={'left'}
        zIndexOverride={1001}
        preferredPosition="mostSpace"
        fluidContent={true}
      >
        <Popover.Pane>
          <ContentContainer>
            <Typography variant="subtitle1">{title}</Typography>
            {items.map(({ title, items }) => (
              <>
                <ListItem variant="body2">{title}</ListItem>
                {items.map((item, index) => (
                  <NestedListItem key={`nested-items-${index}`} variant="body1">
                    {item}
                  </NestedListItem>
                ))}
              </>
            ))}
            <Flex
              flexDirection={imagesDirection}
              style={{
                columnGap: space4,
                rowGap: space16,
              }}
            >
              {images.map((image, index) => (
                <img
                  key={`verification-images-${index}`}
                  src={image.src}
                  style={{
                    width: image.width,
                    height: image.height,
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              ))}
            </Flex>
          </ContentContainer>
        </Popover.Pane>
      </Popover>
    </div>
  );
}
