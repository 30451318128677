import React, { createContext, useContext, useState } from 'react';

import CompanyVerificationModalVariantController from '../../modules/CompanyDashboardV2/CompanyHeader/components/CompanyVerificationModalVariantController';

type Props = {
  children: React.ReactNode;
};
type Context = {
  openCompanyVerificationModal: () => void;
};
const Context = createContext<Context | null>(null);

export const useCompanyVerificationModalContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useCompanyVerificationModalContext must be used within a CompanyVerificationModalProvider'
    );
  }
  return context;
};

export default function CompanyVerificationModalProvider({ children }: Props) {
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  return (
    <Context.Provider
      value={{
        openCompanyVerificationModal: () => setIsVerificationModalOpen(true),
      }}
    >
      <CompanyVerificationModalVariantController
        isOpen={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
      />
      {children}
    </Context.Provider>
  );
}
