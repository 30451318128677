import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { INDONESIA_COUNTRY } from '../../JobV2/common/constants';
import { verificationMessages } from './companyHeaderMessages';

export interface CompanyNIBFormState {
  legalRegistrationNumber?: string;
  legalDocument: string | null;
}

export const DEFAULT_COMPANY_NIB_FORM_STATE: CompanyNIBFormState = {
  legalRegistrationNumber: '',
  legalDocument: null,
};

export const companyNIBValidationSchema = (
  intl: IntlShape,
  countryCode: string
) =>
  Yup.object().shape({
    legalRegistrationNumber: Yup.string().test({
      name: 'nibValidation',
      test: function (this: any, value: any) {
        if (!value || countryCode !== INDONESIA_COUNTRY.code) return true;
        if (value.length !== 13 || !/^\d+$/.test(value)) {
          return this.createError({
            message: intl.formatMessage(verificationMessages.invalidNIB),
            path: 'legalRegistrationNumber',
          });
        }
        return true;
      },
    }),

    legalDocument: Yup.string()
      .nullable()
      .required('Please upload your company legal document'),
  });
