import React, { ReactNode, useId } from 'react';
import { Flex } from 'glints-aries/es';
import { Icon, Spinner, Tooltip, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import { compact } from 'lodash-es';
import { FieldPath, useController, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { AssetType } from '../../../common/constants';
import useUploadFile from '../../../common/hooks/requests/useUploadFile';
import {
  useCompanyVerificationFormContext,
  useIsViewOnlyMode,
} from './FormContext';
import { FormContentStyles } from './styles.sc';
import { CompanyVerificationFormState } from './types';
import { getAssetFileNameFromURL, isURL } from './utils';

type Props = {
  title: ReactNode;
  required?: boolean;
  assetType: AssetType;
  fieldName: FieldPath<CompanyVerificationFormState>;
  maxImages?: number;
  tooltip?: ReactNode;
  maxFileSizeInMB?: number;
  hideTitle?: boolean;
};

const DEFAULT_MAX_FILE_SIZE = 10;
export default function MultiFileUploader({
  title,
  assetType,
  required,
  fieldName,
  maxImages = 1,
  hideTitle,
  maxFileSizeInMB = DEFAULT_MAX_FILE_SIZE,
  tooltip,
}: Props) {
  const isViewOnly = useIsViewOnlyMode();
  const { control } = useCompanyVerificationFormContext();
  const fieldValue = useWatch({
    control,
    name: fieldName,
  }) as string[];

  return (
    <Flex flexDirection="column" gap={space12} alignItems="flex-start">
      <If condition={!hideTitle}>
        <Flex gap={space4}>
          <Typography variant="body2">
            {title}
            {required && <FormContentStyles.Asterisk />}
          </Typography>
          {tooltip}
        </Flex>
      </If>

      <Flex flexDirection="column" gap={space8}>
        <Flex gap={space16}>
          {Array(isViewOnly ? compact(fieldValue).length : maxImages)
            .fill({})
            .map((_, index) => (
              <RenderButton
                key={index}
                assetType={assetType}
                fieldName={fieldName}
                index={index}
                maxFileSizeInMB={maxFileSizeInMB}
              />
            ))}
        </Flex>
        <If condition={!isViewOnly}>
          <Typography variant="subtitle2" color={Neutral.B40}>
            <FormattedMessage
              id="text-acceptable-formats-image-max"
              defaultMessage="<bold>Acceptable formats:</bold> jpg, jpeg, png | <bold>Maximum size: {maxSize}MB</bold>"
              tagName="span"
              values={{
                bold: (...chunks: ReactNode[]) => (
                  <Typography variant="caption" as="span" color={Neutral.B40}>
                    {chunks}
                  </Typography>
                ),
                maxSize: maxFileSizeInMB,
              }}
            />
          </Typography>
        </If>
      </Flex>
    </Flex>
  );
}

function RenderButton({
  fieldName,
  assetType,
  index,
  maxFileSizeInMB = DEFAULT_MAX_FILE_SIZE,
}: Pick<Props, 'fieldName' | 'assetType' | 'maxFileSizeInMB'> & {
  index: number;
}) {
  const {
    field: { onChange, value: fieldValue },
  } = useController({
    name: `${fieldName}.${index}`,
  });
  const inputElemId = useId();

  const {
    uploadFile,
    file,
    originalFile,
    loading,
    reset: resetFile,
    error,
  } = useUploadFile({
    assetType,
    maxSizeInMB: maxFileSizeInMB,
    allowedMimeTypes: ['image/jpeg', 'image/png'],
    onSuccess: uploadedFilePath => {
      onChange(uploadedFilePath);
    },
  });
  const isViewOnly = useIsViewOnlyMode();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const handleRemoveFile = () => {
    resetFile();
    onChange('');
  };

  const selectedFile = fieldValue
    ? isURL(fieldValue as string)
      ? {
          assetURL: fieldValue as string,
          assetFileName: getAssetFileNameFromURL(fieldValue as string),
        }
      : {
          assetURL:
            (file && originalFile && URL.createObjectURL(originalFile)) || '',
          assetFileName: file?.name,
        }
    : null;

  const handlePreviewImage = () => {
    if (selectedFile) {
      window.open(selectedFile.assetURL, '_blank');
    }
  };

  const UploadButtonContent = (
    <label htmlFor={inputElemId}>
      <FormContentStyles.ImageUploadButton
        onClick={e => e.currentTarget.parentElement?.click()}
        data-error={Boolean(error)}
      >
        <Choose>
          <When condition={loading}>
            <Spinner fill={Neutral.B40} width={24} height={24} />
          </When>
          <Otherwise>
            <Icon name="ri-add" fill={Neutral.B40} width={24} height={24} />
            <Typography variant="caption">
              <FormattedMessage
                id="interactive-upload"
                defaultMessage="Upload"
              />
            </Typography>
          </Otherwise>
        </Choose>
      </FormContentStyles.ImageUploadButton>
    </label>
  );

  return (
    <Choose>
      <When condition={selectedFile}>
        <FormContentStyles.ImageUploadButton
          data-ignore-padding={true}
          data-image-selected={true}
        >
          <img src={selectedFile?.assetURL} />
          <div
            className="remove-file-icon"
            onClick={isViewOnly ? handlePreviewImage : handleRemoveFile}
          >
            <Icon
              name={isViewOnly ? 'ri-eye-fill' : 'ri-delete-bin-fill'}
              fill={Neutral.B40}
              width={16}
              height={16}
            />
          </div>
        </FormContentStyles.ImageUploadButton>
      </When>
      <Otherwise>
        <input
          type="file"
          id={inputElemId}
          accept="image/jpeg, image/png"
          hidden={true}
          onChange={handleFileChange}
        />
        <Choose>
          <When condition={error}>
            <Tooltip content={error} zIndex={1001}>
              {UploadButtonContent}
            </Tooltip>
          </When>
          <Otherwise>{UploadButtonContent}</Otherwise>
        </Choose>
      </Otherwise>
    </Choose>
  );
}
