import { gql, useSuspenseQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { CompanyVerificationMethod } from '../../../../common/interfaces/entities';
import { getSessionCompanyId } from '../../../../selectors/session';

const query = gql`
  query getCompanyVerificationMethods(
    $input: GetCompanyVerificationMethodsInput!
  ) {
    getCompanyVerificationMethods(input: $input) {
      verificationMethod
      popularityTag {
        key
        defaultMessage
      }
      benefits {
        activeJobCount
        verifiedBadge
        paidFeatures
      }
    }
  }
`;

export type VerificationBenefitOutput = {
  activeJobCount: number;
  verifiedBadge: boolean;
  paidFeatures: boolean;
};

type QueryOutput = {
  getCompanyVerificationMethods: CompanyVerificationMethod[];
};

type QueryInput = {
  input: {
    companyId: string;
  };
};
export default function useGetCompanyVerificationMethods() {
  const companyId = useSelector(getSessionCompanyId);
  const { data } = useSuspenseQuery<QueryOutput, QueryInput>(query, {
    variables: {
      input: {
        companyId,
      },
    },
  });

  return {
    companyVerificationMethods: data.getCompanyVerificationMethods,
  } as const;
}
