const getMiddle = (val: number) => Math.floor(val / 2);

const truncate = (
  arr: string[],
  max: number,
  joinSymb = '',
  ellipsis = '...'
): string => {
  const str = arr.join(joinSymb);
  let mid = getMiddle(arr.length);

  if (str.length <= max) return str;
  if (arr.includes(ellipsis)) {
    arr.splice(mid, 1);
    mid = getMiddle(arr.length);
  }

  arr.splice(mid, 1, ellipsis);

  return truncate(arr, max, joinSymb, ellipsis);
};

const getExt = (fname: string) =>
  fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2);

const truncateFileName = (fileName: string, max = 30) => {
  let maxlen = max;

  const ext = getExt(fileName);
  const name = fileName.replace(`.${ext}`, '');

  let resStr = '';

  if (ext) {
    resStr = `.${ext}`;
    maxlen -= resStr.length;
  }

  const customFileName = truncate(name.split(''), maxlen);

  resStr = `${customFileName}${resStr}`;

  return resStr;
};

export default truncateFileName;
