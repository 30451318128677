import React from 'react';
import { ScreenSize } from 'glints-aries/es';
import { debounce } from 'lodash-es';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../common/routes';
import { getIsLoggedIn } from '../../../selectors/session';
import { useAppDispatch } from '../../../store';
import { toggleSidebar } from '../Actions';
import { NavbarWrapper } from '../Navbar.sc';
import { Hamburger } from '../NavbarMobile.sc';
import {
  NavbarHeaderWithLogoContainer,
  NavbarLogo,
} from './AlternativeNavbar.sc';

type Props = {
  children?: React.ReactNode;
  setHeaderHeight(height: number): void;
  top: number;
  canNavigate?: boolean;
  hasSidebar?: boolean;
};

const AlternativeNavbar = ({
  children,
  setHeaderHeight,
  top,
  canNavigate,
  hasSidebar,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  React.useLayoutEffect(() => {
    if (ref.current) {
      setHeaderHeight(ref.current.clientHeight);
    }
  }, [setHeaderHeight]);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setHeaderHeight(ref.current.clientHeight);
      }
    };
    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [setHeaderHeight]);

  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <NavbarWrapper ref={ref} top={top}>
      <NavbarHeaderWithLogoContainer>
        <Choose>
          <When condition={canNavigate}>
            <Link to={isLoggedIn ? `/${ROUTES.dashboardRoute}` : '/'}>
              <NavbarLogo />
            </Link>
          </When>
          <Otherwise>
            <NavbarLogo />
          </Otherwise>
        </Choose>

        <If condition={hasSidebar}>
          <MediaQuery maxWidth={ScreenSize.tablet - 1}>
            <Hamburger onClick={() => dispatch(toggleSidebar())} />
          </MediaQuery>
        </If>
        <MediaQuery minWidth={ScreenSize.tablet}>{children}</MediaQuery>
      </NavbarHeaderWithLogoContainer>
    </NavbarWrapper>
  );
};

export default React.memo(AlternativeNavbar);
