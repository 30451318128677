import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  whatIsYourCompany: {
    id: 'text-what-is-your-company',
    defaultMessage: 'What is your company?',
  },
  joinExistingOrCreateNew: {
    id: 'text-join-existing-or-create-new',
    defaultMessage:
      'You can join an existing company or create a new company profile.{br}Use the company or brand name registered on official documents.',
  },
  companyName: {
    id: 'text-company-name',
    defaultMessage: 'Company Name',
  },
  searchYourCompanyName: {
    id: 'interactive-search-your-company-name',
    defaultMessage: 'Search your company name',
  },
  create: {
    id: 'interactive-create',
    defaultMessage: 'Create',
  },
  somethingWentWrong: {
    id: 'text-something-went-wrong',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
  brandName: {
    id: 'text-brand-name',
    defaultMessage: 'Brand Name',
  },
});

export const errorMessages = defineMessages({
  companyNameIsRequired: {
    id: 'text-company-name-is-required',
    defaultMessage: 'Company name is required',
  },
  enterAtLeastThreeCharacters: {
    id: 'text-please-enter-at-least-3-char',
    defaultMessage: 'Please enter at least 3 characters',
  },
  companyExistsCreateNew: {
    id: 'text-company-exists-create-new',
    defaultMessage:
      'Company name already exists. Try a different name to create a new company.',
  },
  thisFieldIsRequired: {
    id: 'text-this-field-is-required',
    defaultMessage: 'This field is required',
  },
  enterValidWebsite: {
    id: 'text-enter-valid-website',
    defaultMessage: 'Please enter a valid website.',
  },
  hasForbiddenKeywords: {
    id: 'text-field-cannot-include-keyword',
    defaultMessage: `{field} can't include "{keyword}"`,
  },
  cannotExceedMaxLength: {
    id: 'text-the-company-description-cannot',
    defaultMessage: `The company description field cannot exceed {count} characters.`,
  },
  invalidReferralCode: {
    id: 'text-referral-code-error',
    defaultMessage:
      'The referral code entered is incorrect. Please try again or leave it blank.',
  },
});
