import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Divider,
  Icon,
  InlineError,
  OutlineButton,
  OutlineMonochromeButton,
  PrimaryButton,
  Typography,
  useAlert,
} from 'glints-aries/es/@next';
import { Blue, Neutral, Red } from 'glints-aries/es/@next/utilities/colors';
import { identity, pickBy } from 'lodash-es';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { setSessionCompany } from '../../../../actions/session';
import { useGetAssetURL } from '../../../../common/asset-manager';
import {
  ASSET_TYPES,
  CompanyStatus,
  VN_COUNTRY_LOCATION,
} from '../../../../common/constants';
import useUploadFile from '../../../../common/hooks/requests/useUploadFile';
import { useAxiosWithAPI } from '../../../../common/hooks/useAxiosWithAPI';
import { Company } from '../../../../common/interfaces/entities';
import truncateFileName from '../../../../common/truncate';
import TextField from '../../../../components/Fields/TextField';
import { getSessionCompany } from '../../../../selectors/session';
import { useAppDispatch } from '../../../../store';
import { INDONESIA_COUNTRY } from '../../../JobV2/common/constants';
import { verificationMessages } from '../companyHeaderMessages';
import {
  CompanyNIBFormState,
  companyNIBValidationSchema,
  DEFAULT_COMPANY_NIB_FORM_STATE,
} from '../consts';
import { getFileMetadata } from '../helper';
import {
  ModalWrapper,
  StyledBanner,
  StyledFile,
  StyledFileWrapper,
  StyledNIB,
  StyledUploadButtonWrapper,
  StyledUploadDocument,
} from './CompanyVerificationModal.sc';

interface CompanyVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CompanyVerificationModalComponent = ({
  isOpen,
  onClose,
}: CompanyVerificationModalProps) => {
  const axios = useAxiosWithAPI();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const sessionCompany: Company | undefined = useSelector(getSessionCompany);

  const [isIndonesianCompany, isVietnamCompany] = [
    sessionCompany?.CountryCode === INDONESIA_COUNTRY.code,
    sessionCompany?.CountryCode === VN_COUNTRY_LOCATION.code,
  ];

  const {
    control,
    setValue,
    formState: { errors, isValid },
    reset,
    handleSubmit,
    trigger,
  } = useForm<CompanyNIBFormState>({
    mode: 'all',
    resolver: yupResolver(
      companyNIBValidationSchema(intl, sessionCompany?.CountryCode as string)
    ),
    defaultValues: DEFAULT_COMPANY_NIB_FORM_STATE,
  });

  const { open } = useAlert();

  const {
    uploadFile,
    error,
    file,
    loading,
    setFile,
    reset: resetFile,
  } = useUploadFile({
    assetType: ASSET_TYPES.COMPANY_LEGAL_DOCUMENT,
    maxSizeInMB: 50,
    allowedMimeTypes: ['application/pdf', 'image/jpeg', 'image/png'],
    onSuccess: uploadedFilePath => {
      setValue('legalDocument', uploadedFilePath);
      trigger();
    },
  });

  const verificationInProgress = useMemo(
    () =>
      sessionCompany?.status === CompanyStatus.UNVERIFIED &&
      sessionCompany?.legalDocument,
    [sessionCompany?.legalDocument, sessionCompany?.status]
  );

  const legalDocument = useWatch({ control, name: 'legalDocument' });

  const { getAssetURL } = useGetAssetURL();

  useEffect(() => {
    const onFormReset = async () => {
      const company = sessionCompany as Company;

      if (company && company.legalDocument) {
        const metadata = await getFileMetadata(
          company.legalDocument,
          ASSET_TYPES.COMPANY_LEGAL_DOCUMENT
        );

        setFile(
          new File(
            [],
            `${metadata.name} (${(metadata.size / 1024 / 1024).toFixed(2)} MB)`
          )
        );

        reset({
          legalDocument: company.legalDocument,
          legalRegistrationNumber: company.legalRegistrationNumber || '',
        });
      }
    };
    onFormReset();
  }, [reset, sessionCompany, setFile, isOpen, axios]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const handleDeleteFile = () => {
    resetFile();
    setValue('legalDocument', null);
  };

  const handleClose = () => {
    resetFile();
    reset(DEFAULT_COMPANY_NIB_FORM_STATE);
    onClose();
  };

  const handleSubmitForm = async (data: CompanyNIBFormState) => {
    const payload = pickBy(data, identity);

    const response = await axios.put(`/companies/${sessionCompany?.id}`, {
      data: payload,
    });

    try {
      const responseData = response.data.data;
      dispatch(
        setSessionCompany({
          ...sessionCompany,
          legalDocument: responseData.legalDocument,
          legalRegistrationNumber: responseData.legalRegistrationNumber,
        })
      );
      handleClose();
      open({
        status: 'success',
        title: intl.formatMessage(verificationMessages.documentUploaded),
        content: intl.formatMessage(
          verificationMessages.companyVerificationTakesTwoDays,
          {
            br: <br />,
          }
        ),
      });
    } catch (e) {
      open({
        status: 'error',
        title: intl.formatMessage(verificationMessages.error),
        content: intl.formatMessage(verificationMessages.somethingWentWrong),
      });
    }
  };

  if (!sessionCompany) {
    return <></>;
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={handleClose}
      closeOnClickOutside={true}
      header={intl.formatMessage(verificationMessages.verifyCompany)}
      customActions={
        <Choose>
          <When condition={verificationInProgress}>
            <PrimaryButton onClick={handleClose}>
              <FormattedMessage id="interactive-close" defaultMessage="Close" />
            </PrimaryButton>
          </When>
          <Otherwise>
            <PrimaryButton
              onClick={handleSubmit(handleSubmitForm)}
              disabled={!isValid || loading}
            >
              <FormattedMessage
                id="interactive-send-for-verification"
                defaultMessage="Send for verification"
              />
            </PrimaryButton>
          </Otherwise>
        </Choose>
      }
    >
      <Choose>
        <When condition={verificationInProgress}>
          <StyledBanner status="info" dismissable={false}>
            <Typography variant="body1" color={Neutral.B18}>
              {intl.formatMessage(verificationMessages.bannerInProgress)}
            </Typography>
          </StyledBanner>
        </When>
        <When condition={sessionCompany.status === CompanyStatus.REJECTED}>
          <StyledBanner status="critical" dismissable={false}>
            <Typography variant="body1" color={Neutral.B18}>
              {intl.formatMessage(verificationMessages.bannerRejected)}
            </Typography>
          </StyledBanner>
        </When>
      </Choose>
      <Typography variant="body2" color={Neutral.B18}>
        {intl.formatMessage(
          isIndonesianCompany
            ? verificationMessages.uploadCompanyNPWP
            : verificationMessages.uploadCompanyLegalDocument
        )}
        <Typography as="span" variant="body2" color={Red.B93}>
          *
        </Typography>
      </Typography>
      <Typography variant="body1" color={Neutral.B18}>
        {intl.formatMessage(
          isIndonesianCompany
            ? verificationMessages.ifYourCompany
            : verificationMessages.verifyYourCompany
        )}
      </Typography>
      <Choose>
        <When condition={file}>
          <StyledFileWrapper>
            {legalDocument && (
              <StyledFile
                href={getAssetURL(legalDocument, 'company-legal-document')}
                target="_blank"
              >
                <Icon
                  name="ri-file-line"
                  width={24}
                  height={24}
                  fill={
                    sessionCompany.status !== CompanyStatus.UNVERIFIED
                      ? Blue.S99
                      : Neutral.B40
                  }
                />
                <Typography
                  variant="subtitle2"
                  color={
                    sessionCompany.status !== CompanyStatus.UNVERIFIED
                      ? Blue.S99
                      : Neutral.B18
                  }
                >
                  {truncateFileName(file?.name || '', 50)}
                </Typography>
              </StyledFile>
            )}
            <Choose>
              <When condition={verificationInProgress}>
                <OutlineButton disabled={true}>
                  <FormattedMessage
                    id="interactive-remove"
                    defaultMessage="Remove"
                  />
                </OutlineButton>
              </When>
              <Otherwise>
                <OutlineMonochromeButton onClick={handleDeleteFile}>
                  <FormattedMessage
                    id="interactive-remove"
                    defaultMessage="Remove"
                  />
                </OutlineMonochromeButton>
              </Otherwise>
            </Choose>
          </StyledFileWrapper>
        </When>
        <Otherwise>
          <StyledUploadDocument>
            <input
              type="file"
              id="modal-company-document"
              accept="application/pdf, image/jpeg, image/png"
              hidden={true}
              onChange={handleFileChange}
            />
            <StyledUploadButtonWrapper>
              <label htmlFor="modal-company-document">
                <Button
                  onClick={e => e.currentTarget.parentElement?.click()}
                  loading={loading}
                >
                  {intl.formatMessage(verificationMessages.selectFile)}
                </Button>
              </label>
              <InlineError text={error || ''} data-cy={'error-message'} />
            </StyledUploadButtonWrapper>
            <Typography variant="subtitle2" color={Neutral.B40}>
              {intl.formatMessage(verificationMessages.acceptableFormat)}
            </Typography>
          </StyledUploadDocument>
        </Otherwise>
      </Choose>

      <If condition={!isVietnamCompany}>
        <Divider />
        <StyledNIB>
          <Controller
            control={control}
            name="legalRegistrationNumber"
            render={({ field: { onChange, value, onBlur } }) => (
              <TextField
                fullWidth={true}
                label={intl.formatMessage(
                  isIndonesianCompany
                    ? verificationMessages.nib
                    : verificationMessages.companyIdentificationNumber
                )}
                subLabel={intl.formatMessage(
                  isIndonesianCompany
                    ? verificationMessages.enterNIB
                    : verificationMessages.enterCompanyIdentificationNumber
                )}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorText={errors.legalRegistrationNumber?.message?.toString()}
                disabled={Boolean(verificationInProgress)}
              />
            )}
          />
        </StyledNIB>
      </If>
    </ModalWrapper>
  );
};

const CompanyVerificationModal = ({
  isOpen,
  onClose,
}: CompanyVerificationModalProps) => {
  if (!isOpen) {
    return null;
  }

  return <CompanyVerificationModalComponent isOpen={true} onClose={onClose} />;
};

export default CompanyVerificationModal;
