import React, { ReactNode } from 'react';
import { Flex } from 'glints-aries/es';
import { Typography, useModal } from 'glints-aries/es/@next';
import { space24 } from 'glints-aries/es/@next/utilities/spacing';
import { ComponentAction } from 'glints-aries/es/types/componentAction';
import { FormattedMessage, useIntl } from 'react-intl';

import { useCompanyVerificationModalContext } from '../../../components/CompanyVerificationModals/CompanyVerificationModalProvider';
import { CompanyStatus, UnverifiedCompanyType } from '../../constants';
import { useGetSessionCompany } from '../useGetSessionCompany';
import { unverifiedCompanyRestrictionMessages } from './unverifiedCompanyRestrictionMessages';
import { Container } from './unverifiedCompanyRestrictionModal.sc';

type Props = {
  type: UnverifiedCompanyType;
};

const UploadDocument = () => (
  <Typography variant="body1" as="span">
    <FormattedMessage
      {...unverifiedCompanyRestrictionMessages.uploadDocuments}
      values={{
        link1: (chunks: ReactNode[]) => (
          <a href={`mailto:${chunks}`}>{chunks}</a>
        ),
      }}
    />
  </Typography>
);

const renderUnverifiedCompanyBody = {
  [UnverifiedCompanyType.APPLICATION]: (
    <>
      <Typography variant="body1" style={{ marginBottom: space24 }}>
        <FormattedMessage
          {...unverifiedCompanyRestrictionMessages.needVerificationCandidate}
        />
      </Typography>
      <UploadDocument />
    </>
  ),
  [UnverifiedCompanyType.TALENT]: (
    <>
      <Typography variant="body1" style={{ marginBottom: space24 }}>
        <FormattedMessage
          {...unverifiedCompanyRestrictionMessages.needVerificationTalent}
        />
      </Typography>
      <UploadDocument />
    </>
  ),
  [UnverifiedCompanyType.TALENT_SEARCH]: (
    <>
      <Typography variant="body1">
        <FormattedMessage
          {...unverifiedCompanyRestrictionMessages.needVerificationAndUploadDocument}
          values={{
            br: (
              <>
                <br />
                <br />
              </>
            ),
            link1: (chunks: ReactNode[]) => (
              <a href={`mailto:${chunks}`}>{chunks}</a>
            ),
          }}
        />
      </Typography>
    </>
  ),
};

export const useUnverifiedCompanyRestrictionModal = ({ type }: Props) => {
  const { open, close } = useModal();
  const { formatMessage } = useIntl();
  const { sessionCompany } = useGetSessionCompany();
  const { openCompanyVerificationModal } = useCompanyVerificationModalContext();
  const companyStatus = sessionCompany?.status;

  const getOpenRestrictionModalFunc = () => {
    const header = formatMessage(unverifiedCompanyRestrictionMessages.header);
    const Content = () => (
      <Container>
        <Flex flexDirection="column" gap="10px">
          <Flex gap="13px" alignItems="center" justifyContent="center">
            <img
              src="images/tier-verification/company-unverified-badge.svg"
              style={{
                width: '76px',
                height: '76px',
              }}
            />
            <img
              src="images/tier-verification/company-verification-arrow.svg"
              style={{
                width: '60px',
                height: '60px',
              }}
            />
            <img
              src="images/tier-verification/company-verified-badge.svg"
              style={{
                width: '76px',
                height: '76px',
              }}
            />
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            {renderUnverifiedCompanyBody[type]}
          </Flex>
        </Flex>
      </Container>
    );
    const primaryAction: ComponentAction = {
      label: formatMessage(unverifiedCompanyRestrictionMessages.verifyCompany),
      onClick: openCompanyVerificationModal,
    };
    const secondaryAction: ComponentAction = {
      label: formatMessage(unverifiedCompanyRestrictionMessages.close),
      onClick: close,
    };

    if (companyStatus === CompanyStatus.VERIFIED) {
      return null;
    }

    return () =>
      open({
        header,
        children: <Content />,
        primaryAction,
        secondaryAction,
      });
  };

  return { getOpenRestrictionModalFunc };
};
