import {
  BusinessVerificationLocationType,
  VerificationMethod,
} from '../../../common/interfaces/entities';
export const VerifyWithProofPersonalDocumentType = {
  NPWP: 'NPWP',
  KTP: 'KTP',
} as const;

export type VerifyWithProofPersonalDocumentType =
  (typeof VerifyWithProofPersonalDocumentType)[keyof typeof VerifyWithProofPersonalDocumentType];

export type CompanyVerificationFormState = {
  verificationMethod: VerificationMethod;
  isViewOnly: boolean;
  verifyWithLegalDocuments: {
    companyNPWPDoc: string;
    companyNPWPNumber: string;
    companyNIBNumber: string | null;
  };
  verifyWithProofOfOwnership: {
    isWhatsAppVerified: boolean;
    personalDocumentType: VerifyWithProofPersonalDocumentType;
    personalNPWPDoc: string;
    personalNPWPNumber: string;
    ktpDoc: string;
    ktpNumber: string;
    personalNIBNumber: string;
    businessLocationType: BusinessVerificationLocationType;
    businessActivityImages: string[];
  };
};
