export const UnleashFeatureNames = {
  empIsNewLoginVerificationEnabled: 'empIsNewLoginVerificationEnabled',
  empNewResumeViewer: 'empNewResumeViewer',
  empEmployerInitChat: 'empEmployerInitChat',
  empIsVerificationPromptEnabled: 'empIsVerificationPromptEnabled',
  empShowWhatsappIcon: 'empShowWhatsappIcon',
  empCSVDownloadEnabled: 'empCSVDownloadEnabled',
  empDashboardStatistics: 'empDashboardStatistics',
  empWhatsappIntegration: 'empWhatsappIntegration',
  empPDFInvoice: 'empPDFInvoice',
  empAllowCollegeDegreeInVN: 'empAllowCollegeDegreeInVN',
  webEmpDisableJobCopy: 'webEmpDisableJobCopy',
  empJobTrafficWarning: 'empJobTrafficWarning',
  empAppDownloadCTA: 'empAppDownloadCTA',
  allScreeningQuestion: 'allScreeningQuestion',
  empNotifyNewVNMonetizedFeatures: 'empNotifyNewVNMonetizedFeatures',
  empPremiumFilters: 'empPremiumFilters',
  empHideMajorPremiumFilter: 'empHideMajorPremiumFilter',
  empUseMobileAppPrompt: 'empUseMobileAppPrompt',
  allUseOSS: 'allUseOSS',
  empJobTitleAndJobRoleRecommendations: 'empJobTitleAndJobRoleRecommendations',
  empPremiumJob: 'empPremiumJob',
  empPDFInvoiceForCreditPurchase: 'empPDFInvoiceForCreditPurchase',
  allCustomSQPlainText: 'allCustomSQPlainText',
  allUseApplicationsBulkApiV2: 'allUseApplicationsBulkApiV2',
  empDownloadAppPromoCTA: 'empDownloadAppPromoCTA',
  empTierVerificationEnabled: 'empTierVerificationEnabled',
  empUseApplicationsListV2: 'empUseApplicationsListV2',
  empSimplifiedOnboardingFlow: 'empSimplifiedOnboardingFlow',
};
