import React, { ReactNode } from 'react';
import { Flex } from 'glints-aries/es';
import {
  OutlineButton,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { CompanyStatus } from '../../../../common/constants';
import { useGetSessionCompany } from '../../../../common/hooks/useGetSessionCompany';
import { EmployerPurchaseProductType } from '../../../../common/interfaces/entities';
import { ROUTES } from '../../../../common/routes';
import { ConditionalProductUsageComponent } from '../../SharedComponents/ConditionalProductUsageComponent';
import { UnverifiedCompanyProductUsageWarningBanner } from '../../SharedComponents/UnverifiedCompanyProductUsageWarningBanner';
import { CreditProductPurchaseOrder } from '../../types';
import { verticalSpacing } from '../spacing';
import { ProductIcon, StyledButtonGroup } from './styles.sc';

const messages = defineMessages({
  unlocksAdded: {
    id: 'text-unlocks-added',
    defaultMessage: 'Unlocks Added',
  },
  purchaseSuccessfully: {
    id: 'text-purchased-successfully',
    defaultMessage: 'Purchased successfully',
  },
  yourCVFinder: {
    id: 'text-your-cv-finder-unlocks',
    defaultMessage:
      'Your CV Finder unlocks will be valid till <bold>{date}.</bold>',
  },
  nowYouHaveUnlocks: {
    id: 'text-now-you-have-unlocks',
    defaultMessage:
      'Now you have <b>{amount}</b> unlocks. Check your <link1>unlock balance</link1> for more details.',
  },
});

type Props = {
  creditProductPurchaseOrder: Pick<
    CreditProductPurchaseOrder,
    'companyProductInventory'
  >;
  onClose: () => void;
};
const ModalTitle = () => (
  <FormattedMessage {...messages.purchaseSuccessfully} />
);

const ModalFooter = ({ onClose }: { onClose: () => void }) => {
  const { pathname } = useLocation();
  const talentSearchRegExp = RegExp(`^/talent-search$`);
  const isTalentSearchPath = talentSearchRegExp.test(pathname);
  const { sessionCompany } = useGetSessionCompany();
  const isCompanyVerified = sessionCompany?.status === CompanyStatus.VERIFIED;
  return (
    <StyledButtonGroup>
      <OutlineButton onClick={onClose}>
        <Typography variant="button">
          <FormattedMessage id="interactive-close" defaultMessage="Close" />
        </Typography>
      </OutlineButton>
      <If condition={!isTalentSearchPath}>
        <ConditionalProductUsageComponent
          isCompanyVerified={isCompanyVerified}
          destination={ROUTES.talentSearch}
        >
          <PrimaryButton onClick={onClose} disabled={!isCompanyVerified}>
            <Typography variant="button">
              <FormattedMessage
                id="interactive-explore-cv-finder-talent"
                defaultMessage="Explore CV Finder"
              />
            </Typography>
          </PrimaryButton>
        </ConditionalProductUsageComponent>
      </If>
    </StyledButtonGroup>
  );
};

const Bold = (...chunks: ReactNode[]) => (
  <Typography variant="body2" as="span">
    {chunks}
  </Typography>
);

function TalentSearchSuccessModalContentComponent({
  creditProductPurchaseOrder: { companyProductInventory },
  onClose,
}: Props) {
  const { formatDate, formatNumber } = useIntl();
  return (
    <Flex
      flexDirection="column"
      style={{
        gap: 20,
      }}
    >
      <ProductIcon
        src="/images/credit-system/talent-search-icon.svg"
        alt="CV Finder"
      />
      <div>
        <Typography variant="body2">
          <FormattedMessage {...messages.unlocksAdded} />
        </Typography>
        {verticalSpacing(space4)}
        <Typography variant="body1" as="div" color={Neutral.B40}>
          <FormattedMessage
            {...messages.yourCVFinder}
            values={{
              date: companyProductInventory?.expiryDateTime
                ? formatDate(companyProductInventory.expiryDateTime, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })
                : '-',
              bold: Bold,
            }}
            tagName="div"
          />
          {verticalSpacing(space4)}
          <FormattedMessage
            {...messages.nowYouHaveUnlocks}
            values={{
              amount: formatNumber(companyProductInventory?.amount || 0),
              b: Bold,
              link1: (chunks: React.ReactNode) => (
                <Link
                  to={`/${ROUTES.features}?tab=PURCHASES&purchasedTab=${EmployerPurchaseProductType.TALENT_SEARCH}`}
                  onClick={onClose}
                >
                  {chunks}
                </Link>
              ),
            }}
            tagName="div"
          />
        </Typography>
      </div>
      <UnverifiedCompanyProductUsageWarningBanner />
    </Flex>
  );
}

export const TalentSearchSuccessModalContent = Object.assign(
  TalentSearchSuccessModalContentComponent,
  {
    ModalTitle,
    ModalFooter,
  }
);
