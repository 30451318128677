import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { getEmailVerificationStatus } from '../selectors/user';

export const verify = WrappedComponent => {
  const Verified = props => {
    const isVerified = useSelector(getEmailVerificationStatus);
    if (isVerified) {
      return <WrappedComponent {...props} />;
    }
    if (isVerified === false) {
      return <Redirect to="/email-verification" />;
    }
    return <Redirect to="/500" />;
  };

  return Verified;
};
