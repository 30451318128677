import React from 'react';
import { Flex } from 'glints-aries/es';
import { Badge, Icon, Typography } from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space32 } from 'glints-aries/es/@next/utilities/spacing';
import { compact } from 'lodash-es';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { VerificationMethod } from '../../../common/interfaces/entities';
import { useIsViewOnlyMode } from './FormContext';
import { VerificationBenefitOutput } from './hooks/useGetCompanyVerificationMethods';
import { VerificationMethodCardStyles } from './styles.sc';

const VerificationMethodToComponentMap = {
  [VerificationMethod.LEGAL_DOCUMENTS]: (
    <FormattedMessage
      id="text-verify-with-legal-documents"
      defaultMessage="Verify with Legal Documents"
    />
  ),
  [VerificationMethod.PROOF_OF_OWNERSHIP]: (
    <FormattedMessage
      id="text-verify-with-proof-of"
      defaultMessage="Verify with Proof of Ownership"
    />
  ),
};
const VerificationMethodToIconMap = {
  [VerificationMethod.LEGAL_DOCUMENTS]:
    'images/tier-verification/verify-with-legal-documents-icon.svg',
  [VerificationMethod.PROOF_OF_OWNERSHIP]:
    'images/tier-verification/verify-with-proof-of-ownership-icon.svg',
};

const BenefitsComponents = {
  ActiveJobs: (props: { activeJobs: number }) => (
    <div>
      <Typography variant="headline6" color={Neutral.B40}>
        {props.activeJobs}
      </Typography>
      <Typography variant="subtitle2" color={Neutral.B40}>
        <FormattedMessage id="text-active-jobs" defaultMessage="Active jobs" />
      </Typography>
    </div>
  ),
  VerifiedBadge: () => (
    <div>
      <Icon name="ri-shield-check" fill={Blue.Brand} width={24} height={24} />
      <Typography variant="subtitle2" color={Neutral.B40}>
        <FormattedMessage
          id="text-verified-badge"
          defaultMessage="Verified badge"
        />
      </Typography>
    </div>
  ),
  PaidFeatures: () => (
    <div>
      <Icon
        name="ri-shopping-cart-line"
        fill={Neutral.B40}
        width={24}
        height={24}
      />
      <Typography variant="subtitle2" color={Neutral.B40}>
        <FormattedMessage
          id="text-paid-features"
          defaultMessage="Paid features"
        />
      </Typography>
    </div>
  ),
};
export function VerificationMethodCard({
  benefits,
  verificationMethod,
  popularityTag,
  active,
  onClick,
}: {
  benefits: VerificationBenefitOutput;
  verificationMethod: VerificationMethod;
  popularityTag: MessageDescriptor | null;
  active: boolean;
  onClick: (() => void) | undefined;
}) {
  const title = VerificationMethodToComponentMap[verificationMethod];
  const imageSrc = VerificationMethodToIconMap[verificationMethod];
  const benefitsComponents = compact([
    benefits.activeJobCount > 0 && (
      <BenefitsComponents.ActiveJobs activeJobs={benefits.activeJobCount} />
    ),
    benefits.verifiedBadge && <BenefitsComponents.VerifiedBadge />,
    benefits.paidFeatures && <BenefitsComponents.PaidFeatures />,
  ]);
  const isViewOnly = useIsViewOnlyMode();

  return (
    <VerificationMethodCardStyles.Container
      data-active={active}
      data-view-only={isViewOnly}
      onClick={onClick}
    >
      <VerificationMethodCardStyles.TagContainer>
        {popularityTag && !isViewOnly && (
          <Badge status="enticing">
            <Typography variant="overline" color={Neutral.B100}>
              <FormattedMessage {...popularityTag} />
            </Typography>
          </Badge>
        )}
      </VerificationMethodCardStyles.TagContainer>
      <Flex
        gap="20px"
        alignItems="center"
        style={isViewOnly ? { flex: 1 } : undefined}
      >
        <img
          style={{
            width: 48,
            height: 48,
          }}
          src={imageSrc}
        />
        <Typography variant="body2">{title}</Typography>
      </Flex>
      <VerificationMethodCardStyles.Divider data-view-only={isViewOnly} />
      <Flex flexDirection="column" gap={space4}>
        <Typography variant="caption">
          <FormattedMessage
            id="text-you-will-unlock"
            defaultMessage="You will unlock:"
          />
        </Typography>
        <Flex gap={space32} alignItems="flex-end">
          {benefitsComponents}
        </Flex>
      </Flex>
    </VerificationMethodCardStyles.Container>
  );
}
