import React from 'react';
import { FormattedMessage } from 'react-intl';

import { WhatsappVerificationStep } from '../../WhatsappVerification/types';

export const ModalHeaders: Record<WhatsappVerificationStep, React.ReactNode> = {
  [WhatsappVerificationStep.InputNumber]: <></>,
  [WhatsappVerificationStep.InputOTP]: (
    <FormattedMessage id="text-verify-number" defaultMessage="Verify number" />
  ),
  [WhatsappVerificationStep.VerificationSuccess]: (
    <FormattedMessage
      id="text-verification-successful"
      defaultMessage="Verification successful"
    />
  ),
} as const;

export const FORM_ID = {
  [WhatsappVerificationStep.InputNumber]:
    'whatsapp-verification-input-number-form',
  [WhatsappVerificationStep.InputOTP]: 'whatsapp-verification-input-otp-form',
} as const;

export const OTP_RESEND_WAIT_TIME_IN_SECONDS = 60;

export const GraphQLErrorCodes = {
  INVALID_WHATSAPP_VERIFICATION_TOKEN: 'INVALID_WHATSAPP_VERIFICATION_TOKEN',
  WHATSAPP_HAS_BEEN_VERIFIED: 'WHATSAPP_HAS_BEEN_VERIFIED_BEFORE',
};
