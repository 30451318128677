import React, { ReactNode, useId } from 'react';
import { Flex } from 'glints-aries/es';
import {
  Icon,
  InlineError,
  OutlineButton,
  OutlineMonochromeButton,
  Typography,
} from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
} from 'glints-aries/es/@next/utilities/spacing';
import { FieldPath, useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { AssetType } from '../../../common/constants';
import useUploadFile from '../../../common/hooks/requests/useUploadFile';
import truncateFileName from '../../../common/truncate';
import {
  useCompanyVerificationFormContext,
  useIsViewOnlyMode,
} from './FormContext';
import { FormContentStyles } from './styles.sc';
import { CompanyVerificationFormState } from './types';
import { getAssetFileNameFromURL, isURL } from './utils';

type Props = {
  title: ReactNode;
  hideTitle?: boolean;
  buttonText: ReactNode;
  required?: boolean;
  assetType: AssetType;
  fieldName: FieldPath<CompanyVerificationFormState>;
  tooltip?: ReactNode;
  maxFileSizeInMB?: number;
};

const DEFAULT_MAX_FILE_SIZE = 10;
export default function FileUploader({
  title,
  buttonText,
  assetType,
  required,
  fieldName,
  tooltip,
  hideTitle,
  maxFileSizeInMB = DEFAULT_MAX_FILE_SIZE,
}: Props) {
  const { control } = useCompanyVerificationFormContext();
  const {
    field: { onChange, value: fieldValue },
  } = useController({
    name: fieldName,
    control,
  });
  const inputElemId = useId();
  const {
    uploadFile,
    file,
    loading,
    originalFile,
    error,
    reset: resetFile,
  } = useUploadFile({
    assetType,
    maxSizeInMB: maxFileSizeInMB,
    allowedMimeTypes: ['application/pdf', 'image/jpeg', 'image/png'],
    onSuccess: uploadedFilePath => {
      onChange(uploadedFilePath);
    },
  });
  const isViewOnly = useIsViewOnlyMode();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const handleRemoveFile = () => {
    resetFile();
    onChange('');
  };

  const selectedFile = fieldValue
    ? isURL(fieldValue as string)
      ? {
          assetURL: fieldValue as string,
          assetFileName: getAssetFileNameFromURL(fieldValue as string),
        }
      : {
          assetURL:
            (file && originalFile ? URL.createObjectURL(originalFile) : '') ||
            (fieldValue as string),
          assetFileName: file?.name || (fieldValue as string),
        }
    : null;

  return (
    <Flex flexDirection="column" gap={space12} alignItems="flex-start">
      <If condition={!hideTitle}>
        <Flex gap={space4}>
          <Typography variant="body2">
            {title}
            {required && <FormContentStyles.Asterisk />}
          </Typography>
          {tooltip}
        </Flex>
      </If>
      <Choose>
        <When condition={selectedFile}>
          {selectedFile && (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: '100%',
              }}
            >
              <a href={selectedFile.assetURL} target="_blank" rel="noreferrer">
                <Flex gap={space8} alignItems="center">
                  <Icon
                    name="ri-file-line"
                    width={24}
                    height={24}
                    fill={Neutral.B40}
                  />
                  <Typography
                    variant="subtitle2"
                    color={isViewOnly ? Blue.S99 : Neutral.B18}
                  >
                    {truncateFileName(selectedFile.assetFileName || '', 50)}
                  </Typography>
                </Flex>
              </a>

              <OutlineMonochromeButton
                onClick={handleRemoveFile}
                disabled={isViewOnly}
              >
                <Typography variant="button">
                  <FormattedMessage
                    id="interactive-remove"
                    defaultMessage="Remove"
                  />
                </Typography>
              </OutlineMonochromeButton>
            </Flex>
          )}
        </When>
        <Otherwise>
          <Flex flexDirection="column" gap={space8}>
            <input
              type="file"
              id={inputElemId}
              accept="application/pdf, image/jpeg, image/png"
              hidden={true}
              onChange={handleFileChange}
            />
            <label htmlFor={inputElemId}>
              <OutlineButton
                icon={<Icon name="ri-upload-fill" />}
                style={{
                  maxWidth: 'max-content',
                }}
                loading={loading}
                onClick={e => e.currentTarget.parentElement?.click()}
              >
                <Typography variant="button">{buttonText}</Typography>
              </OutlineButton>
            </label>
            {error && <InlineError text={error} />}
            <Typography variant="subtitle2" color={Neutral.B40}>
              <FormattedMessage
                id="text-acceptable-formats-max"
                defaultMessage="<bold>Acceptable formats:</bold> pdf, jpg, jpeg, png | <bold>Maximum size: {maxSize}MB</bold>"
                values={{
                  bold: (...chunks: ReactNode[]) => (
                    <Typography variant="caption" as="span" color={Neutral.B40}>
                      {chunks}
                    </Typography>
                  ),
                  maxSize: maxFileSizeInMB,
                }}
              />
            </Typography>
          </Flex>
        </Otherwise>
      </Choose>
    </Flex>
  );
}
