import { defineMessages } from 'react-intl';

export const errorMessages = defineMessages({
  invalidEmailPassword: {
    id: 'text-invalid-email-or-password',
    defaultMessage: 'Invalid email or password',
  },
  invalidVerificationToken: {
    id: 'modules.Login.Constants.invalid.verification.token',
    defaultMessage: 'The verification token is invalid',
  },
  invalidVerificationCode: {
    id: 'modules.Login.Constants.invalid.verification.code',
    defaultMessage: 'The code you entered is invalid',
  },
  expiredVerificationCode: {
    id: 'modules.Login.Constants.expired.verification.code',
    defaultMessage: 'The code you entered has expired. Please resend below.',
  },
  verificationCodeRequired: {
    id: 'modules.Login.Constants.verification.code.required',
    defaultMessage: 'You need a verification code',
  },
  enterCorrectEmailPassword: {
    id: 'text-authentication-error-login',
    defaultMessage:
      'Please enter the correct email and password or <resetpass>reset your password</resetpass>.',
  },
  engineersNotified: {
    id: 'modules.Login.Constants.engineers.notified',
    defaultMessage:
      'Our engineers have been notified. Please try again by refreshing the page.',
  },
  errorOccurred: {
    id: 'modules.Login.Constants.error.occurred',
    defaultMessage: 'An error has occurred',
  },
  networkError: {
    id: 'modules.Login.Constants.network.error',
    defaultMessage: 'Network Error',
  },
  checkIfConnected: {
    id: 'modules.Login.Constants.check.if.connected',
    defaultMessage: "Please check if you're connected to the Internet.",
  },
  loginRateLimitExceeded: {
    id: 'modules.Login.Constants.rate.limit.exceeded',
    defaultMessage: 'Rate limit exceeded',
  },
  loginRateLimitExceededRetry: {
    id: 'modules.Login.Constants.rate.limit.exceeded.retry',
    defaultMessage:
      'You have reached the limit for logging in. Please re-try again after three to five minutes.',
  },
});

export const serverErrorMessages = {
  INVALID_MFA_TOKEN: 'Invalid MFA token',
  EXPIRED_OTP: 'Expired OTP',
  INVALID_OTP: 'Invalid OTP',
  MFA_REQUIRED: 'mfa_required',
};

export const RESEND_TIMEOUT_SECONDS = 180;

export const OTP_LENGTH = 6;
