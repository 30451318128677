import { compact } from 'lodash-es';
import { defineMessages, IntlShape, MessageDescriptor } from 'react-intl';
import * as yup from 'yup';

import {
  BusinessVerificationLocationType,
  VerificationMethod,
} from '../../../common/interfaces/entities';
import { VerifyWithProofPersonalDocumentType } from './types';

const messages = defineMessages({
  nibLengthInvalid: {
    id: 'text-nib-length-is-13',
    defaultMessage: 'NIB number is invalid, it should be 13 digits.',
  },
  nibInvalid: {
    id: 'text-nib-number-invalid',
    defaultMessage: 'Invalid NIB number.',
  },
  npwpInvalid: {
    id: 'text-npwp-number-invalid',
    defaultMessage: 'Invalid NPWP number.',
  },
  ktpInvalid: {
    id: 'text-invalid-ktp-number',
    defaultMessage: 'Invalid KTP number.',
  },
  ktpDocumentRequired: {
    id: 'text-ktp-document-required',
    defaultMessage: 'KTP document required.',
  },
  ktpNumberRequired: {
    id: 'text-ktp-number-required',
    defaultMessage: 'KTP number is required.',
  },
  npwpDocumentRequired: {
    id: 'text-npwp-document-required',
    defaultMessage: 'NPWP document required.',
  },
  npwpNumberRequired: {
    id: 'text-npwp-number-required',
    defaultMessage: 'NPWP number is required.',
  },
  businessImagesRequired: {
    id: 'text-min-business-images-required',
    defaultMessage: 'Min {count} business images required.',
  },
});

const MIN_BUSINESS_IMAGES_REQUIRED = 2;

const nibNumberValidation = (intl: IntlShape) =>
  yup
    .string()
    .trim()
    .optional()
    .test('isValidNib', intl.formatMessage(messages.nibInvalid), value => {
      if (!value) return true;
      return /^\d+$/.test(value);
    })
    .test(
      'hasCorrectLength',
      intl.formatMessage(messages.nibLengthInvalid),
      value => {
        if (!value) return true;
        return value.length === 13;
      }
    );

const npwpOrKTPNumberValidation = (
  intl: IntlShape,
  errorMessage: MessageDescriptor
) => {
  const formattedErrorMessage = intl.formatMessage(errorMessage);
  return yup
    .string()
    .optional()
    .matches(/^\d+$/, formattedErrorMessage)
    .min(15, formattedErrorMessage)
    .max(16, formattedErrorMessage);
};

export const companyVerificationFormValidator = (intl: IntlShape) =>
  yup.object().shape({
    verificationMethod: yup
      .string()
      .oneOf(Object.values(VerificationMethod))
      .required(),
    isViewOnly: yup.boolean().optional(),
    verifyWithLegalDocuments: yup.object().when('verificationMethod', {
      is: VerificationMethod.LEGAL_DOCUMENTS,
      then: yup.object().shape({
        companyNPWPDoc: yup
          .string()
          .trim()
          .required(intl.formatMessage(messages.npwpDocumentRequired)),
        companyNPWPNumber: npwpOrKTPNumberValidation(
          intl,
          messages.npwpInvalid
        ).required(intl.formatMessage(messages.npwpNumberRequired)),
        companyNIBNumber: nibNumberValidation(intl),
      }),
    }),
    verifyWithProofOfOwnership: yup.object().when('verificationMethod', {
      is: VerificationMethod.PROOF_OF_OWNERSHIP,
      then: yup.object().shape({
        isWhatsAppVerified: yup.boolean().oneOf([true]),
        personalDocumentType: yup
          .string()
          .oneOf(Object.values(VerifyWithProofPersonalDocumentType))
          .required(),
        businessLocationType: yup
          .string()
          .oneOf(Object.values(BusinessVerificationLocationType))
          .required(),
        businessActivityImages: yup
          .array()
          .required()
          .test(
            'businessActivityMin',
            intl.formatMessage(messages.businessImagesRequired, {
              count: MIN_BUSINESS_IMAGES_REQUIRED,
            }),
            values => compact(values).length >= MIN_BUSINESS_IMAGES_REQUIRED
          ),
        personalNPWPDoc: yup
          .string()
          .trim()
          .when('personalDocumentType', {
            is: VerifyWithProofPersonalDocumentType.NPWP,
            then: yup
              .string()
              .required(intl.formatMessage(messages.npwpDocumentRequired)),
          }),
        personalNPWPNumber: yup.string().when('personalDocumentType', {
          is: VerifyWithProofPersonalDocumentType.NPWP,
          then: npwpOrKTPNumberValidation(intl, messages.npwpInvalid).required(
            intl.formatMessage(messages.npwpNumberRequired)
          ),
        }),
        ktpDoc: yup
          .string()
          .trim()
          .when('personalDocumentType', {
            is: VerifyWithProofPersonalDocumentType.KTP,
            then: yup
              .string()
              .required(intl.formatMessage(messages.ktpDocumentRequired)),
          }),
        ktpNumber: yup.string().when('personalDocumentType', {
          is: VerifyWithProofPersonalDocumentType.KTP,
          then: npwpOrKTPNumberValidation(intl, messages.ktpInvalid).required(
            intl.formatMessage(messages.ktpNumberRequired)
          ),
        }),
        personalNIBNumber: nibNumberValidation(intl),
      }),
    }),
  });
