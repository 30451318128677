import React, { ComponentProps } from 'react';
import { apm } from '@elastic/apm-rum';
import { ErrorBoundary } from 'react-error-boundary';

import EmptyErrorBoundaryFallbackComponent from '../EmptyErrorBoundaryFallbackComponent';
type ErrorBoundaryWithCrashReportingProps = {
  children: React.ReactNode;
  /**
   Override the default onError behavior
   **/
  onError?: ComponentProps<typeof ErrorBoundary>['onError'];
  transactionName?: 'global-error-boundary' | 'ats-recommended-talents';
} & (
  | { fallback: ComponentProps<typeof ErrorBoundary>['fallback'] }
  | { fallbackRender: ComponentProps<typeof ErrorBoundary>['fallbackRender'] }
);

export default function ErrorBoundaryWithCrashReporting({
  children,
  onError,
  transactionName,
  ...props
}: ErrorBoundaryWithCrashReportingProps) {
  const getFallbackProps = () => {
    if ('fallback' in props) {
      return {
        fallback: props.fallback || <EmptyErrorBoundaryFallbackComponent />,
      };
    }
    return {
      fallbackRender:
        props.fallbackRender || EmptyErrorBoundaryFallbackComponent,
    };
  };

  return (
    <ErrorBoundary
      {...getFallbackProps()}
      onError={(error, info) => {
        if (onError) {
          return onError(error, info);
        }
        const transaction = transactionName
          ? apm.startTransaction(transactionName, 'page-load', {
              managed: true,
            })
          : undefined;
        // components names will still be minified, but at least we can see the stack trace
        // where the error was actually originating from
        const componentError = new Error(error.message);
        componentError.name = `React ErrorBoundary: ${error.name}`;
        componentError.stack = info.componentStack;
        error.cause = componentError;
        apm.captureError(error);
        transaction?.end();
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
