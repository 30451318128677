import { useCallback, useState } from 'react';
import { apm } from '@elastic/apm-rum';
import { includes } from 'lodash-es';
import { useIntl } from 'react-intl';

import { isResponseSuccess } from '../../axios-ts';
import { AssetType } from '../../constants';
import useUploadFileToS3 from '../useUploadfileToS3';
interface UseUploadFileProps {
  maxSizeInMB: number;
  allowedMimeTypes: string[];
  allowedFileExtensions?: string[];
  assetType: AssetType;
  onSuccess?: (uploadedFilePath: string) => void;
}

const useUploadFile = ({
  maxSizeInMB,
  allowedMimeTypes,
  allowedFileExtensions,
  onSuccess,
  assetType,
}: UseUploadFileProps) => {
  const fileTypes = allowedMimeTypes.map(type => type.split('/')[1]).join(',');
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [originalFile, setOriginalFile] = useState<File | null>(null);

  const uploadFileToS3 = useUploadFileToS3();

  const reset = useCallback(() => {
    setFile(null);
    setOriginalFile(null);
    setError(null);
  }, [setFile, setOriginalFile, setError]);

  const uploadFile = async (file: File) => {
    setLoading(true);
    setError(null);

    const fileSize = (file.size / 1024 / 1024).toFixed(2);
    const fileName = file.name;
    const fileType = file.type;

    try {
      if (!allowedMimeTypes.includes(fileType)) {
        apm.captureError(`Invalid file type: ${fileType}`);
        throw new Error(
          formatMessage(
            {
              id: 'text-invalid-file-type',
              defaultMessage: 'File must be {fileFormat}. Try another file.',
            },
            { fileFormat: fileTypes }
          )
        );
      }

      if (
        allowedFileExtensions &&
        allowedFileExtensions.length > 0 &&
        !includes(
          allowedFileExtensions,
          fileName.split('.').pop()?.toLowerCase()
        )
      ) {
        throw new Error(
          formatMessage(
            {
              id: 'text-invalid-file-type',
              defaultMessage: 'File must be {fileFormat}. Try another file.',
            },
            {
              fileFormat: allowedFileExtensions
                .map(value => `.${value}`)
                .join('/'),
            }
          )
        );
      }

      if (Number(fileSize) > maxSizeInMB) {
        throw new Error(
          formatMessage(
            {
              id: 'text-file-too-large',
              defaultMessage:
                'File cannot be more than {fileSize} MB. Try another file.',
            },
            { fileSize: maxSizeInMB }
          )
        );
      }

      const { signed, response } = await uploadFileToS3(file, {
        name: fileName,
        type: fileType,
        label: assetType,
      });

      if (isResponseSuccess(response)) {
        setFile({
          ...file,
          name: `${fileName} (${fileSize} MB)`,
        });
        setOriginalFile(file);
        onSuccess?.(signed.name);
      } else {
        throw new Error('Failed to upload');
      }
    } catch (error) {
      setFile(null);
      setOriginalFile(null);
      setError((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  return {
    uploadFile,
    error,
    loading,
    file,
    setFile,
    originalFile,
    setOriginalFile,
    reset,
  };
};

export default useUploadFile;
