import {
  gql,
  skipToken,
  SuspenseQueryHookFetchPolicy,
  useApolloClient,
  useSuspenseQuery,
} from '@apollo/client';
import { useSelector } from 'react-redux';

import { EmployerPurchaseProductType } from '../../../common/interfaces/entities';
import { fragmentRegistry } from '../../../graphql/fragmentRegistry';
import { VIPMembershipCompanyProductInventory } from '../../../modules/CreditSystem/Features/FeaturesAndPurchasesTabs/PurchasedProducts/types';
import { getSessionCompanyId } from '../../../selectors/session';

fragmentRegistry.register(gql`
  fragment vipMembershipInfoFragment on CompanyProductInventory {
    amount
    expiryDateTime
    isActive
    hasPendingProduct
    activeFreeCreditBenefit {
      amount
      nextAllocationDate
    }
    product {
      type
      creditBenefit {
        amount
      }
    }
    trialInformationDetail {
      startDate
      endDate
      isActive
      isExtended
    }
    activeSubscriptionInformation {
      ...activeSubscriptionInformationFragment
    }
    activeSubscriptionFreeTrialOffer {
      ...subscriptionFreeTrialOfferFragment
    }
  }
`);

const query = gql`
  query getVIPMembershipInfo($input: GetCompanyProductInventoryInput!) {
    getVIPMembershipInfo: getCompanyProductInventory(input: $input) {
      ...vipMembershipInfoFragment
    }
  }
`;

type GetVIPMembershipInfoInput = {
  input: {
    companyId: string;
    productType: typeof EmployerPurchaseProductType.VIP_MEMBERSHIP;
  };
};

export type GetVIPMembershipInfoResponse = {
  getVIPMembershipInfo: Pick<
    VIPMembershipCompanyProductInventory,
    | 'amount'
    | 'expiryDateTime'
    | 'product'
    | 'activeFreeCreditBenefit'
    | 'trialInformationDetail'
    | 'isActive'
    | 'hasPendingProduct'
    | 'activeSubscriptionInformation'
    | 'activeSubscriptionFreeTrialOffer'
  > | null;
};

export const useWriteVIPMembershipInfoToCache = () => {
  const apolloClient = useApolloClient();
  const companyId: string | undefined = useSelector(getSessionCompanyId);

  const writeVIPMembershipInfoToCache = (
    data: NonNullable<GetVIPMembershipInfoResponse['getVIPMembershipInfo']>
  ) => {
    apolloClient.writeQuery<
      {
        getVIPMembershipInfo: GetVIPMembershipInfoResponse['getVIPMembershipInfo'] & {
          __typename: string;
        };
      },
      GetVIPMembershipInfoInput
    >({
      query: query,
      data: {
        getVIPMembershipInfo: {
          ...data,
          __typename: 'CompanyProductInventory',
        },
      },
      variables: {
        input: {
          companyId: companyId || '',
          productType: EmployerPurchaseProductType.VIP_MEMBERSHIP,
        },
      },
    });
  };

  return {
    writeVIPMembershipInfoToCache,
  } as const;
};

export const useGetVIPMembershipInfo = (
  fetchPolicy: SuspenseQueryHookFetchPolicy = 'cache-and-network',
  queryKey = 'getVIPMembershipInfo'
) => {
  const companyId: string | undefined = useSelector(getSessionCompanyId);

  const { data, client } = useSuspenseQuery<
    GetVIPMembershipInfoResponse,
    GetVIPMembershipInfoInput
  >(
    query,
    companyId
      ? {
          variables: {
            input: {
              companyId,
              productType: EmployerPurchaseProductType.VIP_MEMBERSHIP,
            },
          },
          fetchPolicy: fetchPolicy,
          queryKey,
        }
      : skipToken
  );

  const getVIPMembershipInfoFromCache = () => {
    const result = client.readQuery<
      GetVIPMembershipInfoResponse,
      GetVIPMembershipInfoInput
    >({
      query,
      variables: {
        input: {
          companyId: companyId || '',
          productType: EmployerPurchaseProductType.VIP_MEMBERSHIP,
        },
      },
    });

    return result;
  };

  return {
    data,
    getVIPMembershipInfoFromCache,
  };
};
