import React, { Suspense } from 'react';
import { apm } from '@elastic/apm-rum';
import { Button, Icon, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { UnverifiedCompanyType } from '../../common/constants';
import { useUnverifiedCompanyRestrictionModal } from '../../common/hooks/useUnverifiedCompanyRestrictionModal/useUnverifiedCompanyRestrictionModal';
import ChatButton from '../../components/ChatButton';
import MobileChatButton from '../../components/ChatButton/MobileChatButton';
import ErrorBoundaryWithCrashReporting from '../../components/Error/ErrorBoundaryWithCrashReporting';
import {
  ChatClickedEventLocation,
  trackGlobalChatClickedEvent,
} from '../EmployerATSV2/tracking';
import { trackChatPageViewedEvent } from '../GlintsChat/Channel/components/ChannelDetail/ChannelHeader/tracking';
import { MessagingUIMode } from '../GlintsChat/constants';
import { useIsMobileVersion } from '../GlintsChat/hooks/useIsMobileVersion';
import useGetApproximateTotalUnreadCount from './hooks/useGetApproximateTotalUnreadCount';

const CTAChatComponent = () => {
  const { totalGlobalUnreadCount } = useGetApproximateTotalUnreadCount();
  const isMobileVersion = useIsMobileVersion('TopNavCTA');
  const { getOpenRestrictionModalFunc } = useUnverifiedCompanyRestrictionModal({
    type: UnverifiedCompanyType.APPLICATION,
  });
  const openRestrictionModalFunc = getOpenRestrictionModalFunc();
  return (
    <Link
      data-cy="chat-cta-menu"
      to="/messages"
      onClick={e => {
        if (openRestrictionModalFunc) {
          e.preventDefault();
          openRestrictionModalFunc();
          return;
        }
        trackGlobalChatClickedEvent(ChatClickedEventLocation.HEADER);
        trackChatPageViewedEvent({
          source: 'navbar',
          uiType: MessagingUIMode.FULL,
        });
      }}
    >
      <Choose>
        <When condition={isMobileVersion}>
          <MobileChatButton unreadCount={totalGlobalUnreadCount} />
        </When>
        <Otherwise>
          <ChatButton
            unreadCount={totalGlobalUnreadCount}
            icon={<Icon name="ri-message-line" />}
            iconPosition="left"
            style={{
              textTransform: 'uppercase',
            }}
          />
        </Otherwise>
      </Choose>
    </Link>
  );
};

const CTAChatLoading = () => (
  <Button loading={true}>
    <Typography variant="button">
      <FormattedMessage id="button-text_chat" defaultMessage="Chat" />
    </Typography>
  </Button>
);

const CTAChat = () => (
  <ErrorBoundaryWithCrashReporting
    onError={e => {
      apm.setCustomContext({ ctaChatNavbar: 'error' });
      apm.captureError(e);
      apm.setCustomContext({ ctaChatNavbar: '' });
    }}
    fallback={null}
  >
    <Suspense fallback={<CTAChatLoading />}>
      <CTAChatComponent />
    </Suspense>
  </ErrorBoundaryWithCrashReporting>
);

export default CTAChat;
