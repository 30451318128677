import React, { ComponentProps, useState } from 'react';
import { Flex } from 'glints-aries/es';
import {
  Icon,
  Menu as AriesMenu,
  Popover,
  Typography,
} from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import { get } from 'lodash-es';

import ErrorBoundaryWithCrashReporting from '../../components/Error/ErrorBoundaryWithCrashReporting';
import { getConfig } from '../../config';
import {
  appLanguagesBasedOnCountry,
  AppLanguageStorageKey,
  appLanguageToContentMap,
  SupportedLanguages,
} from './constants';

type AppLanguageSwitcherProps = {
  hideDivider?: boolean;
};
function AppLanguageSwitcherComponent({
  hideDivider,
}: AppLanguageSwitcherProps) {
  const [isActive, setIsActive] = useState(false);

  const [openPopover, closePopover] = [
    () => setIsActive(true),
    () => setIsActive(false),
  ];

  const config = getConfig();
  const configCountry = config.COUNTRY;
  const configLanguage: SupportedLanguages = config.LANG ?? 'en';
  const languagesForCountry = appLanguagesBasedOnCountry[configCountry];
  const { shortForm } = get(appLanguageToContentMap, configLanguage);
  const activator = (
    <Flex
      style={{
        gap: space8,
        marginLeft: hideDivider ? 0 : space16,
      }}
      onClick={openPopover}
      justifyContent="center"
    >
      <Flex
        style={{
          gap: space4,
        }}
      >
        <Icon
          name="ri-global-line"
          width={20}
          fill={isActive ? Blue.S99 : Neutral.B40}
        />
        <Typography
          variant={hideDivider ? 'subtitle1' : 'caption'}
          color={isActive ? Blue.S99 : Neutral.B18}
        >
          {shortForm}
        </Typography>
      </Flex>
      <Icon
        name={isActive ? 'ri-arrow-m-up-line' : 'ri-arrow-m-down-line'}
        width={20}
        fill={isActive ? Blue.S99 : Neutral.B00}
      />
    </Flex>
  );

  const handleOnSelectMenu: ComponentProps<typeof AriesMenu>['onClick'] = ({
    value: selectedLang,
  }) => {
    if (selectedLang === configLanguage) {
      return;
    }
    localStorage.setItem(AppLanguageStorageKey, selectedLang);
    location.reload();
  };

  return (
    <Flex
      style={{
        height: '100%',
      }}
      alignItems="center"
      justifyContent={hideDivider ? 'flex-start' : 'center'}
    >
      <If condition={!hideDivider}>
        <div
          style={{
            width: 1,
            height: '100%',
            backgroundColor: Neutral.B85,
          }}
        />
      </If>

      <div
        style={{
          cursor: 'pointer',
        }}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
      >
        <Popover
          active={isActive}
          activator={activator}
          onClose={closePopover}
          zIndexOverride={1200}
          preferredAlignment="right"
          preferredPosition="below"
        >
          <Popover.Pane>
            <div
              style={{
                margin: `${space8} 0`,
              }}
            >
              <AriesMenu
                selectedValues={[configLanguage]}
                onClick={handleOnSelectMenu}
                options={languagesForCountry.map(language => {
                  const { shortForm, longForm } = get(
                    appLanguageToContentMap,
                    language
                  );
                  const isActive = language === configLanguage;
                  return {
                    value: language,
                    label: (
                      <div
                        style={
                          isActive
                            ? {
                                cursor: 'not-allowed',
                              }
                            : undefined
                        }
                      >
                        <Typography
                          variant="body2"
                          color={isActive ? Blue.S99 : Neutral.B18}
                        >
                          {shortForm}
                        </Typography>
                        <Typography
                          variant="overline"
                          color={isActive ? Blue.S99 : Neutral.B40}
                        >
                          {longForm}
                        </Typography>
                      </div>
                    ),
                  };
                })}
              />
            </div>
          </Popover.Pane>
        </Popover>
      </div>
    </Flex>
  );
}

export default function AppLanguageSwitcher(
  props: AppLanguageSwitcherProps = {}
) {
  return (
    <ErrorBoundaryWithCrashReporting fallback={null}>
      <AppLanguageSwitcherComponent {...props} />
    </ErrorBoundaryWithCrashReporting>
  );
}
