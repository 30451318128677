import React, { useState } from 'react';
import { Banner, Link, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { CompanyStatus } from '../../../common/constants';
import useGetCompanyVerificationInfo from '../../../common/hooks/useGetCompanyVerificationInfo';
import CompanyVerificationModalVariantController from '../../CompanyDashboardV2/CompanyHeader/components/CompanyVerificationModalVariantController';

const BannerComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space12};
`;

export const UnverifiedCompanyProductUsageWarningBanner = () => {
  const { formatMessage } = useIntl();
  const { company, canVerifyCompany } = useGetCompanyVerificationInfo();
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);

  if (company?.status === CompanyStatus.VERIFIED) return null;

  return (
    <Banner
      status="warning"
      dismissable={false}
      title={formatMessage({
        id: 'text-company-verification-required',
        defaultMessage: 'Company Verification Required',
      })}
    >
      <BannerComponent>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-to-use-this-feature"
            defaultMessage="To use this feature, please verify your company."
          />
        </Typography>
        <Choose>
          <When condition={canVerifyCompany}>
            <Link
              onClick={() => setIsVerificationModalOpen(true)}
              style={{ width: 'fit-content' }}
            >
              <Typography variant="button">
                <FormattedMessage
                  id="interactive-verify-company"
                  defaultMessage="Verify Company"
                />
              </Typography>
            </Link>
          </When>
          <Otherwise>
            <Typography variant="button" color={Neutral.B40}>
              <FormattedMessage
                id="text-verification-in-progress"
                defaultMessage="Verification In Progress"
              />
            </Typography>
          </Otherwise>
        </Choose>
      </BannerComponent>
      <CompanyVerificationModalVariantController
        isOpen={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
      />
    </Banner>
  );
};
