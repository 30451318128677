import { compact, isEqual } from 'lodash-es';
import { useWatch } from 'react-hook-form';

import useGetCompanyVerificationInfo from '../../../../common/hooks/useGetCompanyVerificationInfo';
import { VerificationMethod } from '../../../../common/interfaces/entities';
import {
  useCompanyVerificationFormContext,
  useCompanyVerificationFormState,
} from '../FormContext';

export default function useSubmitButtonEnabled() {
  const { isValid } = useCompanyVerificationFormState();
  const { control } = useCompanyVerificationFormContext();
  const { verificationInfo } = useGetCompanyVerificationInfo();
  const [verificationMethod, legalDocuments, verifyWithProofOfOwnership] =
    useWatch({
      control,
      name: [
        'verificationMethod',
        'verifyWithLegalDocuments',
        'verifyWithProofOfOwnership',
      ],
    });

  const lastVerificationRequest = verificationInfo?.lastVerificationRequest;

  if (!verificationInfo?.lastVerificationRequest && isValid) {
    return true;
  }

  if (
    verificationMethod === VerificationMethod.LEGAL_DOCUMENTS &&
    lastVerificationRequest?.document.verificationMethod ===
      VerificationMethod.LEGAL_DOCUMENTS
  ) {
    const isDataChanged = !isEqual(legalDocuments, {
      companyNPWPDoc: lastVerificationRequest.document.companyNpwpDocument,
      companyNPWPNumber: lastVerificationRequest.document.companyNpwpNumber,
      companyNIBNumber: lastVerificationRequest.document.companyNibNumber || '',
    });
    return isDataChanged && isValid;
  }

  if (
    verificationMethod === VerificationMethod.PROOF_OF_OWNERSHIP &&
    lastVerificationRequest?.document.verificationMethod ===
      VerificationMethod.PROOF_OF_OWNERSHIP
  ) {
    const sortedBusinessActivityImages = [
      ...lastVerificationRequest.document.businessImages,
    ].sort();
    const sortedBusinessActivityImagesFromForm = compact([
      ...verifyWithProofOfOwnership.businessActivityImages,
    ]).sort();

    const isDataChanged = !isEqual(
      {
        personalNPWPDoc: verifyWithProofOfOwnership.personalNPWPDoc,
        personalNPWPNumber: verifyWithProofOfOwnership.personalNPWPNumber,
        personalNIBNumber: verifyWithProofOfOwnership.personalNIBNumber,
        businessActivityImages: sortedBusinessActivityImagesFromForm,
        ktpDoc: verifyWithProofOfOwnership.ktpDoc,
        ktpNumber: verifyWithProofOfOwnership.ktpNumber,
        businessLocationType: verifyWithProofOfOwnership.businessLocationType,
      },
      {
        personalNPWPDoc:
          lastVerificationRequest.document.personalNpwpDocument || '',
        personalNPWPNumber:
          lastVerificationRequest.document.personalNpwpNumber || '',
        personalNIBNumber:
          lastVerificationRequest.document.personalNibNumber || '',
        businessActivityImages: sortedBusinessActivityImages,
        ktpDoc: lastVerificationRequest.document.ktpDoc || '',
        ktpNumber: lastVerificationRequest.document.ktpNumber || '',
        businessLocationType:
          lastVerificationRequest.document.businessLocationType,
      }
    );

    return isDataChanged && isValid;
  }

  return isValid;
}
