import React, { Suspense } from 'react';
import { Flex } from 'glints-aries/es';
import { PlainButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space8 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { EmployerPurchaseProductType } from '../../../common/interfaces/entities';
import { ROUTES } from '../../../common/routes';
import { useCompanyVerificationModalContext } from '../../../components/CompanyVerificationModals/CompanyVerificationModalProvider';
import ErrorBoundaryWithCrashReporting from '../../../components/Error/ErrorBoundaryWithCrashReporting';
import GlintsVIPComponentWrapper from '../../../components/GlintsVIPComponentWrapper';
import {
  useGlintsVIPMembershipInfo,
  VIPMembershipInfo,
} from '../../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import NodesJoin from '../../../components/NodesJoin';
import RenderVIPCTALabelBasedOnVIPFreeTrial from '../../../components/VIPFreeTrial/RenderVIPCTALabelBasedOnVIPFreeTrial';
import { useGlintsVIPFreeTrialOrPurchaseModal } from '../PurchaseModals/GlintsVIPPurchaseModal/hooks/useGlintsVIPFreeTrialOrPurchaseModal';
import VIPLimitedTimeOfferBanner from '../VIPLimitedTImeOfferBanner/VIPLimitedTimeOfferBanner';

const messages = defineMessages({
  membershipActive: {
    id: 'text-vip-membership-active',
    defaultMessage: 'VIP Membership: Active',
  },
  membershipInactive: {
    id: 'text-vip-membership-inactive',
    defaultMessage: 'VIP Membership: Inactive',
  },
  membershipPending: {
    id: 'text-vip-membership-pending-verification-profile',
    defaultMessage: 'VIP Membership: Pending Verification',
  },
});

const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledButton = styled(PlainButton)`
  min-width: 50px;
  height: auto;
  display: inline;
  padding: 0;
`;

type VIPMembershipInfoMenuItemProps = {
  closePopover: () => void;
};

type MenuItemProps = {
  vipMembershipInfo: VIPMembershipInfo;
  disableButton: boolean;
  handleOpenGlintsVIPPurchaseModal: (e: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => void;
};

function CreditBenefitComponent({
  freeCreditsCount,
  purchaseType,
}: {
  freeCreditsCount?: number;
  purchaseType: 'extend' | 'upgrade';
}) {
  if (!freeCreditsCount) {
    return <></>;
  }

  return (
    <div
      style={{
        marginTop: space4,
      }}
    >
      <VIPLimitedTimeOfferBanner
        freeCreditsCount={freeCreditsCount}
        variant="navbarBanner"
        purchaseType={purchaseType}
      />
    </div>
  );
}

function PendingVIPMenuItemComponent({
  handleClosePopover,
}: {
  handleClosePopover: (e: { stopPropagation: () => void }) => void;
}) {
  const { openCompanyVerificationModal } = useCompanyVerificationModalContext();
  const handleOpenCompanyVerificationModal = (e: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    handleClosePopover(e);
    e.preventDefault();
    openCompanyVerificationModal();
  };

  return (
    <>
      <Typography variant="body1" color={Neutral.B18}>
        <FormattedMessage {...messages.membershipPending} />
      </Typography>
      <StyledButton onClick={handleOpenCompanyVerificationModal}>
        <Typography variant="button">
          <FormattedMessage
            id="text-verify-company"
            defaultMessage="Verify Company"
          />
        </Typography>
      </StyledButton>
    </>
  );
}

function InactiveVIPMenuItemComponent({
  vipMembershipInfo,
  disableButton,
  handleOpenGlintsVIPPurchaseModal,
}: MenuItemProps) {
  const { creditBenefit } = vipMembershipInfo;
  return (
    <>
      <Typography variant="body1" color={Neutral.B18}>
        <FormattedMessage {...messages.membershipInactive} />
      </Typography>
      <StyledButton
        onClick={handleOpenGlintsVIPPurchaseModal}
        disabled={disableButton}
      >
        <Typography variant="button">
          <RenderVIPCTALabelBasedOnVIPFreeTrial>
            <FormattedMessage
              id="interactive-upgrade-to-vip"
              defaultMessage="Upgrade to VIP"
            />
          </RenderVIPCTALabelBasedOnVIPFreeTrial>
        </Typography>
      </StyledButton>
      <CreditBenefitComponent
        freeCreditsCount={creditBenefit?.amount}
        purchaseType="upgrade"
      />
    </>
  );
}

function ActiveVIPMenuItemComponent({
  vipMembershipInfo,
  disableButton,
  handleOpenGlintsVIPPurchaseModal,
}: MenuItemProps) {
  const { formatDate } = useIntl();
  const {
    expiryDateTime,
    isExpiring,
    creditBenefit,
    trialInformationDetail,
    activeSubscriptionInformation,
  } = vipMembershipInfo;

  return (
    <>
      <Typography variant="body1" color={Neutral.B18}>
        <NodesJoin separator={' '}>
          <FormattedMessage {...messages.membershipActive} />
          {trialInformationDetail?.isActive && (
            <FormattedMessage id="text-trial" defaultMessage=" (Trial)" />
          )}
        </NodesJoin>
      </Typography>
      {expiryDateTime && !activeSubscriptionInformation && (
        <Flex
          alignItems="center"
          style={{
            gap: space4,
          }}
        >
          <Typography variant="subtitle2" color={Neutral.B40}>
            <FormattedMessage
              id="text-expires-on"
              defaultMessage="Expires on: {expiryDate}"
              values={{
                expiryDate: formatDate(
                  trialInformationDetail.isActive &&
                    trialInformationDetail.endDate
                    ? trialInformationDetail.endDate
                    : expiryDateTime,
                  {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }
                ),
              }}
            />
            <If condition={isExpiring}>
              <span>{` - `}</span>
            </If>
          </Typography>
          <If condition={isExpiring}>
            <StyledButton
              onClick={handleOpenGlintsVIPPurchaseModal}
              disabled={disableButton}
            >
              <Typography variant="button">
                <FormattedMessage
                  id="interactive-extend"
                  defaultMessage="Extend"
                />
              </Typography>
            </StyledButton>
          </If>
        </Flex>
      )}
      <CreditBenefitComponent
        freeCreditsCount={creditBenefit?.amount}
        purchaseType="extend"
      />
    </>
  );
}

function VIPMembershipInfoMenuItemComponent({
  closePopover,
}: VIPMembershipInfoMenuItemProps) {
  const { vipMembershipInfo } = useGlintsVIPMembershipInfo('cache-first');
  const { openGlintsVIPPurchaseModal, isModalChunkLoadPending } =
    useGlintsVIPFreeTrialOrPurchaseModal();
  const { pathname, search } = useLocation();

  const isActivePage = (() => {
    const isCorrectPath = new RegExp(`^(/${ROUTES.features}).*`).test(pathname);
    const params = new URLSearchParams(search);
    const purchasedTab =
      (params.get('purchasedTab') || '') ===
      EmployerPurchaseProductType.VIP_MEMBERSHIP;
    return isCorrectPath && purchasedTab;
  })();

  const handleOpenGlintsVIPPurchaseModal = (e: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    handleClosePopover(e);
    e.preventDefault();
    openGlintsVIPPurchaseModal();
  };
  const handleClosePopover = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    closePopover();
  };

  const content = (
    <Flex
      style={{
        padding: space8,
        gap: space8,
        cursor: 'pointer',
      }}
    >
      <StyledIcon
        src={
          vipMembershipInfo.isActive
            ? '/images/credit-system/glints-vip-icon.svg'
            : '/images/credit-system/glints-vip-inactive-icon.svg'
        }
        alt="Glints VIP"
      />
      <div>
        <Choose>
          <When condition={vipMembershipInfo.hasPendingProduct}>
            <PendingVIPMenuItemComponent
              handleClosePopover={handleClosePopover}
            />
          </When>
          <When condition={vipMembershipInfo.isActive}>
            <ActiveVIPMenuItemComponent
              vipMembershipInfo={vipMembershipInfo}
              disableButton={isModalChunkLoadPending}
              handleOpenGlintsVIPPurchaseModal={
                handleOpenGlintsVIPPurchaseModal
              }
            />
          </When>
          <Otherwise>
            <InactiveVIPMenuItemComponent
              vipMembershipInfo={vipMembershipInfo}
              disableButton={isModalChunkLoadPending}
              handleOpenGlintsVIPPurchaseModal={
                handleOpenGlintsVIPPurchaseModal
              }
            />
          </Otherwise>
        </Choose>
      </div>
    </Flex>
  );

  if (isActivePage) {
    return (
      <div style={{ cursor: 'pointer' }} onClick={handleClosePopover}>
        {content}
      </div>
    );
  }

  return (
    <Link
      to={`/${ROUTES.features}?purchasedTab=${EmployerPurchaseProductType.VIP_MEMBERSHIP}&tab=PURCHASES`}
      onClick={handleClosePopover}
    >
      {content}
    </Link>
  );
}

export default function VIPMembershipInfoMenuItem(
  props: VIPMembershipInfoMenuItemProps
) {
  return (
    <GlintsVIPComponentWrapper>
      <ErrorBoundaryWithCrashReporting fallback={null}>
        <Suspense fallback={null}>
          <VIPMembershipInfoMenuItemComponent {...props} />
        </Suspense>
      </ErrorBoundaryWithCrashReporting>
    </GlintsVIPComponentWrapper>
  );
}
