import React, { useCallback, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { loadable } from '../../../common/loadable';
import { Variant } from './components/VerificationSuccessStep/constants';
import { ModalHeaders } from './consts';

type OpenModalParam = {
  initialPhoneNumber?: string;
  successStepVariant?: Variant;
  successButtonText?: MessageDescriptor;
};

const WhatsappVerificationModal = loadable(
  () =>
    import('./WhatsappVerificationModal').then(
      module => module.WhatsappVerificationModalWithContextProvider
    ),
  { fallback: undefined }
);

export const useWhatsappVerificationModal = () => {
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalParams, setModalParams] = useState<OpenModalParam | null>(null);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const openModal = useCallback(
    (param?: OpenModalParam) => {
      setModalParams(param || null);
      if (!shouldRenderModal) {
        setShouldRenderModal(true);
      }
      setIsModalOpen(true);
    },
    [shouldRenderModal]
  );

  return {
    isModalOpen,
    openModal,
    closeModal,
    // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
    whatsappVerificationModal: shouldRenderModal ? (
      <WhatsappVerificationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        initialPhoneNumber={modalParams?.initialPhoneNumber}
        successButtonText={
          modalParams?.successButtonText || {
            id: 'interactive-close',
            defaultMessage: 'Close',
          }
        }
        closeButtonText={{
          id: 'interactive-close',
          defaultMessage: 'Close',
        }}
        showCloseButton={true}
        successStepVariant={
          modalParams?.successStepVariant || Variant.LOGIN_OTP
        }
        headers={ModalHeaders}
      />
    ) : null,
  } as const;
};
