import { Dayjs } from 'dayjs';

import { USER_ROLES } from '../../../common/constants';
import { GlintsChatAssetProperties } from './asset-properties';
import { GlintsChatMemberContactRequest } from './member';

export const GlintsChatMessageContentType = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  FILE: 'FILE',
  SYSTEM_NOTIFICATION: 'SYSTEM_NOTIFICATION',
  ACTION_CARD: 'ACTION_CARD',
} as const;

export type GlintsChatMessageContentType =
  (typeof GlintsChatMessageContentType)[keyof typeof GlintsChatMessageContentType];

export const GlintsChatMessageStatus = {
  NORMAL: 'NORMAL',
  RECALLED: 'RECALLED',
  EDITED: 'EDITED',
} as const;

export type GlintsChatMessageStatus =
  (typeof GlintsChatMessageStatus)[keyof typeof GlintsChatMessageStatus];

export const GlintsChatMessageDeliveryStatus = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  READ: 'READ',
  FAILED: 'FAILED',
} as const;

export type GlintsChatMessageDeliveryStatus =
  (typeof GlintsChatMessageDeliveryStatus)[keyof typeof GlintsChatMessageDeliveryStatus];

type GlintsChatMessageSender = {
  id: string;
  firstName: string;
  lastName: string;
  loginRole: USER_ROLES;
  avatarUrl: string | null;
};

const GlintsChatMessageContentTextType = {
  INTRO_MESSAGE: 'INTRO_MESSAGE',
} as const;

export type GlintsChatMessageContentTextType =
  (typeof GlintsChatMessageContentTextType)[keyof typeof GlintsChatMessageContentTextType];

type EmptyRecord = Record<string, never>;
export type GlintsChatMessageContentText = {
  contentType: typeof GlintsChatMessageContentType.TEXT;
  content:
    | {
        text: string;
        type?: GlintsChatMessageContentTextType | null;
        lokaliseID?: string | null;
        namedArgs?: Record<string, string> | null;
      }
    | EmptyRecord;
};

export type GlintsChatMessageContentImage = {
  contentType: typeof GlintsChatMessageContentType.IMAGE;
  content:
    | {
        image: GlintsChatAssetProperties;
        thumbnail: GlintsChatAssetProperties | null;
      }
    | EmptyRecord;
};

export type GlintsChatMessageContentVideo = {
  contentType: typeof GlintsChatMessageContentType.VIDEO;
  content: {
    video: GlintsChatAssetProperties;
    thumbnail: GlintsChatAssetProperties | null;
  };
};

export type GlintsChatMessageContentFile = {
  contentType: typeof GlintsChatMessageContentType.FILE;
  content:
    | {
        file: GlintsChatAssetProperties;
      }
    | EmptyRecord;
};

export const GlintsChatMessageContentSystemNotificationType = {
  NORMAL: 0,
  HIDE_FOR_EMP: 1,
} as const;

export type GlintsChatMessageContentSystemNotificationType =
  (typeof GlintsChatMessageContentSystemNotificationType)[keyof typeof GlintsChatMessageContentSystemNotificationType];

export type GlintsChatMessageContentSystemNotification = {
  contentType: typeof GlintsChatMessageContentType.SYSTEM_NOTIFICATION;
  content: {
    text: string;
    type: GlintsChatMessageContentSystemNotificationType;
    avatarUrl: string | null;
    lokaliseID: string | null;
    namedArgs: Record<string, string> | null;
    externalUrl: string | null;
    roleSpecificMessage: RoleSpecificMessage | null;
  };
};

export type RoleSpecificMessage = {
  emp: RoleSpecificMessageBody | null;
};

export type RoleSpecificMessageBody = {
  lokaliseID: string;
  text: string;
  namedArgs: Record<string, string> | null;
};

export const GlintsChatMessageContentActionCardType = {
  CV_REQUEST_CDD_AUTO: 'CV_REQUEST_CDD_AUTO',
  CV_REQUEST_CDD: 'CV_REQUEST_CDD',
  CV_REQUEST_EMP: 'CV_REQUEST_EMP',
  CONTACTS_REQUEST_CDD: 'CONTACTS_REQUEST_CDD',
  CONTACTS_REQUEST_EMP: 'CONTACTS_REQUEST_EMP',
} as const;

export type GlintsChatMessageContentActionCardType =
  (typeof GlintsChatMessageContentActionCardType)[keyof typeof GlintsChatMessageContentActionCardType];

export const GlintsChatMessageContentActionCardStatus = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
} as const;

export type GlintsChatMessageContentActionCardStatus =
  (typeof GlintsChatMessageContentActionCardStatus)[keyof typeof GlintsChatMessageContentActionCardStatus];

export type GlintsChatMessageContentActionCard = {
  contentType: typeof GlintsChatMessageContentType.ACTION_CARD;
  content: {
    actionType: GlintsChatMessageContentActionCardType;
    actionStatus: GlintsChatMessageContentActionCardStatus;
    requestUserID: string;
    respondentUserID: string | null;
    contact: GlintsChatMemberContactRequest | null;
    file: GlintsChatAssetProperties | null;
  };
};

export type GlintsChatMessageContents =
  | GlintsChatMessageContentText
  | GlintsChatMessageContentImage
  | GlintsChatMessageContentVideo
  | GlintsChatMessageContentFile
  | GlintsChatMessageContentSystemNotification
  | GlintsChatMessageContentActionCard;

export type GlintsChatMessage = {
  id: string;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  messageStatus?: GlintsChatMessageStatus;
  sender: GlintsChatMessageSender;
  channelID: string;

  // custom local properties, populated through message-pipeline
  deliveryStatus?: GlintsChatMessageDeliveryStatus;
} & GlintsChatMessageContents;

export const UpdateMessageOperation = {
  RECALL: 'RECALL',
  EDIT: 'EDIT',
} as const;

export type UpdateMessageOperation =
  (typeof UpdateMessageOperation)[keyof typeof UpdateMessageOperation];
