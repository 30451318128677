import axios, { AxiosInstance } from 'axios';

import { ApplicationSource, ApplicationStatus } from '../common/constants';
import { ApplicationDetails } from '../common/interfaces/entities';
import {
  ApplicationDetailsResponse,
  ApplicationListWithBreakdownResponse,
  SurroundingApplicationIdsResponse,
} from '../common/interfaces/responses';
import { getConfig } from '../config';

function isApplicationLockedForStatusChange(
  application: Pick<ApplicationDetails, 'source' | 'status'>
) {
  return (
    application.source === ApplicationSource.RECOMMENDED_TALENT &&
    application.status === ApplicationStatus.NEW
  );
}
export function applyApplicationsMiddleware(customInstance?: AxiosInstance) {
  const apiBase = getConfig().API_BASE;
  const applicationDetailsRegExp = new RegExp(
    `^${apiBase}/jobs/([^/]+?)/applications/([^/]+?)$`
  );
  const applicationsRegExp = new RegExp(
    `^${apiBase}/jobs/([^/]+?)/applications$`
  );

  (customInstance || axios).interceptors.response.use(response => {
    const urlToTest = `${response.config.baseURL || ''}${
      response.config.url
    }`.split('?')[0];

    if (applicationDetailsRegExp.test(urlToTest)) {
      const applicationDetailsResponse =
        response.data as ApplicationDetailsResponse;
      const { data: applicationDetail } = applicationDetailsResponse;
      const modifiedData: ApplicationDetailsResponse = {
        ...applicationDetailsResponse,
        data: {
          ...applicationDetailsResponse.data,
          isApplicationLockedForStatusChange:
            isApplicationLockedForStatusChange(applicationDetail),
        },
      };
      response.data = modifiedData;
    } else if (applicationsRegExp.test(urlToTest)) {
      const applicationsResponse = response.data as
        | ApplicationListWithBreakdownResponse
        | SurroundingApplicationIdsResponse;
      if ('data' in applicationsResponse) {
        const modifiedData: ApplicationListWithBreakdownResponse = {
          ...applicationsResponse,
          data: {
            ...applicationsResponse.data,
            applications: applicationsResponse.data.applications.map(
              application => ({
                ...application,
                isApplicationLockedForStatusChange:
                  isApplicationLockedForStatusChange(application),
              })
            ),
          },
        };
        response.data = modifiedData;
      }
    }
    return response;
  });
}
