import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import { CompanyVerificationFormState } from './types';

export const useCompanyVerificationFormContext = () =>
  useFormContext<CompanyVerificationFormState>();

export const useCompanyVerificationFormState = () =>
  useFormState<CompanyVerificationFormState>();

export const useIsViewOnlyMode = () => {
  const { control } = useCompanyVerificationFormContext();
  return useWatch({
    control,
    name: 'isViewOnly',
  });
};
