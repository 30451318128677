import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from 'react-use';

import {
  checkIsSubscriptionPlanExpiring,
  isSubscriptionJobPostUsageSoftLimitReached,
} from '../../common/helpers/monetisationUtils';
import useGetCompanyVerificationInfo from '../../common/hooks/useGetCompanyVerificationInfo';
import { useGetSessionCompany } from '../../common/hooks/useGetSessionCompany';
import { useResetVIPMembershipExpiredFirstSeen } from '../../common/hooks/useResetVIPMembershipExpiredFirstSeen';
import { useVIPMembershipExpiredLocalStorage } from '../../common/hooks/useVIPMembershipExpiredLocalStorage';
import { NextPaymentStatus } from '../../common/interfaces/company-product-subscription';
import { SubscriptionPlanType } from '../../common/interfaces/entities';
import ErrorBoundaryWithCrashReporting from '../Error/ErrorBoundaryWithCrashReporting';
import { useGlintsVIPMembershipInfo } from '../GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import CompanyTierUpgradeBanner from './CompanyTierUpgradeBanner/CompanyTierUpgradeBanner';
import { useMonetisationBannerClosedLocalStorage } from './hooks/useMonetisationBannerClosedLocalStorage';
import { SubscriptionPlanExceedingBanner } from './SubscriptionPlanExceedingBanner/SubscriptionPlanExceedingBanner';
import { SubscriptionPlanExpiringBanner } from './SubscriptionPlanExpiringBanner/SubscriptionPlanExpiringBanner';
import { SubscriptionUpgradeToPremiumBanner } from './SubscriptionUpgradeToPremiumBanner/SubscriptionUpgradeToPremiumBanner';
import VerifyCompanyBanner from './VerifyCompanyBanner/VerifyCompanyBanner';
import { VIPMembershipExpiredBanner } from './VIPMembershipExpiredBanner/VIPMembershipExpiredBanner';
import { VIPMembershipExpiringBanner } from './VIPMembershipExpiringBanner/VIPMembershipExpiringBanner';
import VIPSubscriptionPaymentFailedBanner from './VIPSubscriptionPaymentFailedBanner/VIPSubscriptionPaymentFailedBanner';

type Props = {
  setFixedBannerHeight: (height: number) => void;
};

const pageRegxPaths = {
  dashboardRegExp: new RegExp('^/dashboard/?$'),
  manageCandidatesRegExp: new RegExp('^/manage-candidates/?$'),
  featuresPage: new RegExp('^/features/?$'),
};

const MonetisationFixedInfoBannerComponent = ({
  setFixedBannerHeight,
}: Props) => {
  const { currentCompanyJobSlotsSubscription, sessionCompany } =
    useGetSessionCompany();
  const { pathname } = useLocation();
  const [isBannerClosed, setIsBannerClosed] = useState(false);
  const prevSessionCompanyId = usePrevious(sessionCompany?.id);
  const [
    {
      canShowSubscriptionPlanExceedingBanner,
      canShowSubscriptionPlanExpiringBanner,
      canShowSubscriptionUpgradeToPremiumBanner,
      canShowVIPMembershipExpiringBanner,
      canShowVIPMembershipExpiredBanner,
    },
    {
      setSubscriptionPlanExceedingBannerClosed,
      setSubscriptionPlanExpiringBannerClosed,
      setSubscriptionUpgradeToPremiumBannerClosed,
      setVIPMembershipExpiringBannerClosed,
      setVIPMembershipExpiredBannerClosed,
    },
  ] = useMonetisationBannerClosedLocalStorage();

  const closeBanner = () => {
    setIsBannerClosed(true);
  };

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function resetBannerOpenState() {
      if (prevSessionCompanyId !== sessionCompany?.id) setIsBannerClosed(false);
    },
    [prevSessionCompanyId, sessionCompany?.id]
  );

  const isDashboardOrManageCandidatesPage = useMemo(() => {
    const regex = [
      pageRegxPaths.dashboardRegExp,
      pageRegxPaths.manageCandidatesRegExp,
    ];
    return regex.some(exp => exp.test(pathname));
  }, [pathname]);

  const isFeaturesPage = useMemo(() => {
    const { featuresPage } = pageRegxPaths;
    return featuresPage.test(pathname);
  }, [pathname]);

  const hasSubscriptionPlanAndIsSoftLimitReached = useMemo(() => {
    if (!currentCompanyJobSlotsSubscription) return false;
    const isSoftLimitReached = isSubscriptionJobPostUsageSoftLimitReached(
      currentCompanyJobSlotsSubscription
    );
    return isSoftLimitReached;
  }, [currentCompanyJobSlotsSubscription]);

  const isSubscriptionPlanExpiring =
    currentCompanyJobSlotsSubscription &&
    currentCompanyJobSlotsSubscription.expiryDateTime &&
    checkIsSubscriptionPlanExpiring(
      currentCompanyJobSlotsSubscription.expiryDateTime
    );

  const { vipMembershipInfo } = useGlintsVIPMembershipInfo();
  const vipMembershipExpiredLocalStorage =
    useVIPMembershipExpiredLocalStorage();
  useResetVIPMembershipExpiredFirstSeen();
  const {
    canShowVerificationBanner,
    canShowTierUpgradeBanner,
    verificationInfo: companyVerificationInfo,
  } = useGetCompanyVerificationInfo();

  if (isBannerClosed) return null;

  return (
    <Choose>
      <When condition={canShowVerificationBanner}>
        <VerifyCompanyBanner
          company={{ verificationInfo: companyVerificationInfo }}
          setFixedBannerHeight={setFixedBannerHeight}
        />
      </When>
      <When condition={canShowTierUpgradeBanner}>
        <CompanyTierUpgradeBanner
          company={{ verificationInfo: companyVerificationInfo }}
          setFixedBannerHeight={setFixedBannerHeight}
        />
      </When>
      <When
        condition={
          vipMembershipInfo.activeSubscriptionInformation &&
          vipMembershipInfo.activeSubscriptionInformation.nextPaymentStatus ===
            NextPaymentStatus.RETRYING
        }
      >
        {vipMembershipInfo.activeSubscriptionInformation && (
          <VIPSubscriptionPaymentFailedBanner
            setFixedBannerHeight={setFixedBannerHeight}
            subscription={vipMembershipInfo.activeSubscriptionInformation}
          />
        )}
      </When>
      <When
        condition={
          hasSubscriptionPlanAndIsSoftLimitReached &&
          isDashboardOrManageCandidatesPage &&
          canShowSubscriptionPlanExceedingBanner
        }
      >
        {currentCompanyJobSlotsSubscription && (
          <SubscriptionPlanExceedingBanner
            setFixedBannerHeight={setFixedBannerHeight}
            closeBanner={() => {
              setSubscriptionPlanExceedingBannerClosed();
              closeBanner();
            }}
          />
        )}
      </When>
      <When
        condition={
          !hasSubscriptionPlanAndIsSoftLimitReached &&
          isSubscriptionPlanExpiring &&
          (isDashboardOrManageCandidatesPage || isFeaturesPage) &&
          canShowSubscriptionPlanExpiringBanner
        }
      >
        {currentCompanyJobSlotsSubscription &&
          currentCompanyJobSlotsSubscription.expiryDateTime && (
            <SubscriptionPlanExpiringBanner
              closeBanner={() => {
                setSubscriptionPlanExpiringBannerClosed();
                closeBanner();
              }}
              setFixedBannerHeight={setFixedBannerHeight}
              expiryDate={currentCompanyJobSlotsSubscription.expiryDateTime}
              planName={currentCompanyJobSlotsSubscription.translatedPlanName}
            />
          )}
      </When>
      <When
        condition={
          !hasSubscriptionPlanAndIsSoftLimitReached &&
          vipMembershipInfo.isExpiring &&
          (isDashboardOrManageCandidatesPage || isFeaturesPage) &&
          canShowVIPMembershipExpiringBanner
        }
      >
        {vipMembershipInfo.expiryInDays !== null && (
          <VIPMembershipExpiringBanner
            isTrialActive={Boolean(
              vipMembershipInfo.trialInformationDetail?.isActive
            )}
            closeBanner={() => {
              setVIPMembershipExpiringBannerClosed();
              closeBanner();
            }}
            setFixedBannerHeight={setFixedBannerHeight}
            expiryInDays={vipMembershipInfo.expiryInDays}
            activeSubscriptionFreeTrialOffer={
              vipMembershipInfo.activeSubscriptionFreeTrialOffer
            }
            activeSubscriptionInformation={
              vipMembershipInfo.activeSubscriptionInformation
            }
          />
        )}
      </When>
      <When
        condition={
          !hasSubscriptionPlanAndIsSoftLimitReached &&
          vipMembershipInfo.isExpired &&
          (isDashboardOrManageCandidatesPage || isFeaturesPage) &&
          canShowVIPMembershipExpiredBanner &&
          vipMembershipExpiredLocalStorage.canShowNotificationAndBanner
        }
      >
        <VIPMembershipExpiredBanner
          closeBanner={() => {
            setVIPMembershipExpiredBannerClosed();
            closeBanner();
          }}
          isExpiredMembershipForTrial={
            vipMembershipInfo.isExpiredMembershipForTrial
          }
          activeSubscriptionFreeTrialOffer={
            vipMembershipInfo.activeSubscriptionFreeTrialOffer
          }
          activeSubscriptionInformation={
            vipMembershipInfo.activeSubscriptionInformation
          }
          setFixedBannerHeight={setFixedBannerHeight}
        />
      </When>
      <When
        condition={
          !hasSubscriptionPlanAndIsSoftLimitReached &&
          isDashboardOrManageCandidatesPage &&
          currentCompanyJobSlotsSubscription?.planType ===
            SubscriptionPlanType.FREE &&
          canShowSubscriptionUpgradeToPremiumBanner &&
          !companyVerificationInfo?.isInitiated &&
          !companyVerificationInfo?.tierUpgrade?.isUpgradeInitiated
        }
      >
        {currentCompanyJobSlotsSubscription && (
          <SubscriptionUpgradeToPremiumBanner
            closeBanner={() => {
              setSubscriptionUpgradeToPremiumBannerClosed();
              closeBanner();
            }}
            setFixedBannerHeight={setFixedBannerHeight}
            companyJobSlotSubscription={currentCompanyJobSlotsSubscription}
          />
        )}
      </When>
    </Choose>
  );
};

export const MonetisationFixedInfoBanner = (props: Props) => (
  <ErrorBoundaryWithCrashReporting fallback={null}>
    <Suspense fallback={null}>
      <MonetisationFixedInfoBannerComponent {...props} />
    </Suspense>
  </ErrorBoundaryWithCrashReporting>
);
