import React from 'react';
import { Flex } from 'glints-aries/es';
import {
  ButtonGroup,
  OutlineButton,
  Typography,
  TypographyProps,
} from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space24 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { COUNTRY_DOMAINS } from '../../../common/constants';
import { ROUTES } from '../../../common/routes';
import { getConfig } from '../../../config';
import { getIsLoggedIn } from '../../../selectors/session';
import { useAppDispatch } from '../../../store';
import AppLanguageSwitcher from '../../AppLanguage/AppLanguageSwitcher';
import { toggleSidebar } from '../Actions';

function RenderJobSeekerButton(
  props: {
    typographyProps?: Omit<TypographyProps, 'children'>;
  } = {}
) {
  const authenticated = useSelector(getIsLoggedIn);
  const config = getConfig();
  const domain = config?.COUNTRY ?? COUNTRY_DOMAINS.default;
  const candidateUrl =
    domain && domain !== COUNTRY_DOMAINS.default
      ? `${config.CANDIDATE_URL}/${domain}`
      : config.CANDIDATE_URL;

  return (
    <If condition={!authenticated}>
      <a href={candidateUrl}>
        <Typography
          {...props.typographyProps}
          variant={props.typographyProps?.variant ?? 'button'}
          color={Neutral.B18}
          style={{
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage
            id="interactive-job-seeker-navbar"
            defaultMessage="JOB SEEKER"
          />
        </Typography>
      </a>
    </If>
  );
}
function SidebarContent() {
  const dispatch = useAppDispatch();
  const closeSidebar = () => dispatch(toggleSidebar());
  return (
    <Flex flexDirection="column" gap={space24}>
      <Link to={`/${ROUTES.login}`} onClick={closeSidebar}>
        <Typography
          variant="subtitle1"
          style={{
            textTransform: 'uppercase',
            color: Neutral.B18,
          }}
        >
          <FormattedMessage id="interactive-login" defaultMessage="Login" />
        </Typography>
      </Link>
      <RenderJobSeekerButton
        typographyProps={{
          variant: 'subtitle1',
        }}
      />
      <AppLanguageSwitcher hideDivider={true} />
    </Flex>
  );
}
function AuthPageMenuItemContent() {
  return (
    <ButtonGroup
      style={{
        gap: 20,
      }}
    >
      <RenderJobSeekerButton />
      <Link to={`/${ROUTES.login}`}>
        <OutlineButton>
          <Typography
            variant="button"
            style={{
              textTransform: 'uppercase',
            }}
          >
            <FormattedMessage id="interactive-login" defaultMessage="Login" />
          </Typography>
        </OutlineButton>
      </Link>
      <AppLanguageSwitcher />
    </ButtonGroup>
  );
}

export default Object.assign(
  {},
  {
    NavbarContent: AuthPageMenuItemContent,
    SidebarContent,
  }
);
