import React, { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider as ReactHookFormProvider,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useGetCompanyVerificationInfo from '../../../common/hooks/useGetCompanyVerificationInfo';
import {
  BusinessVerificationLocationType,
  VerificationMethod,
} from '../../../common/interfaces/entities';
import { User } from '../../../models/User';
import { getUser } from '../../../selectors/user';
import useGetCompanyVerificationMethods from './hooks/useGetCompanyVerificationMethods';
import {
  CompanyVerificationFormState,
  VerifyWithProofPersonalDocumentType,
} from './types';
import { companyVerificationFormValidator } from './validation';

type Props = {
  children: React.ReactNode;
};
export default function FormProvider({ children }: Props) {
  const intl = useIntl();
  const { companyVerificationMethods } = useGetCompanyVerificationMethods();
  const { verificationInfo } = useGetCompanyVerificationInfo();
  const user: User = useSelector(getUser);
  const prefilledFormValues = useMemo<
    Partial<CompanyVerificationFormState>
  >(() => {
    const lastVerificationRequest = verificationInfo?.lastVerificationRequest;
    if (!lastVerificationRequest) {
      return {
        verificationMethod: companyVerificationMethods[0].verificationMethod,
        verifyWithProofOfOwnership: {
          personalDocumentType: VerifyWithProofPersonalDocumentType.NPWP,
          businessLocationType: BusinessVerificationLocationType.ONLINE,
        },
      } as Partial<CompanyVerificationFormState>;
    }

    const { document, companyVerificationMethod } = lastVerificationRequest;
    const verificationInProgress =
      verificationInfo?.isInitiated && !verificationInfo?.isRejected;
    const tierUpgradeInProgress =
      verificationInfo.tierUpgrade?.isUpgradeInitiated &&
      !verificationInfo.tierUpgrade?.isUpgradeRejected;

    const isViewOnly = verificationInProgress || tierUpgradeInProgress;

    const preSelectedVerificationMethod = isViewOnly
      ? companyVerificationMethod.verificationMethod
      : verificationInfo?.tierUpgrade?.availableTierUpgrade[0]
          ?.verificationMethod ?? companyVerificationMethod.verificationMethod;

    return {
      verificationMethod: preSelectedVerificationMethod,
      isViewOnly: isViewOnly,
      ...(document.verificationMethod === VerificationMethod.LEGAL_DOCUMENTS
        ? ({
            verifyWithLegalDocuments: {
              companyNIBNumber: document.companyNibNumber || '',
              companyNPWPDoc: document.companyNpwpDocument,
              companyNPWPNumber: document.companyNpwpNumber,
            },
            verifyWithProofOfOwnership: {
              personalDocumentType: VerifyWithProofPersonalDocumentType.NPWP,
              businessLocationType: BusinessVerificationLocationType.ONLINE,
            },
          } as Partial<CompanyVerificationFormState>)
        : {
            verifyWithProofOfOwnership: {
              businessActivityImages: document.businessImages,
              personalNIBNumber: document.personalNibNumber || '',
              personalNPWPDoc: document.personalNpwpDocument || '',
              personalNPWPNumber: document.personalNpwpNumber || '',
              personalDocumentType: document.ktpDoc
                ? VerifyWithProofPersonalDocumentType.KTP
                : VerifyWithProofPersonalDocumentType.NPWP,
              isWhatsAppVerified: Boolean(user.isWhatsappVerified),
              ktpDoc: document.ktpDoc || '',
              ktpNumber: document.ktpNumber || '',
              businessLocationType: document.businessLocationType,
            },
          }),
    };
  }, [
    companyVerificationMethods,
    user.isWhatsappVerified,
    verificationInfo?.isInitiated,
    verificationInfo?.isRejected,
    verificationInfo?.lastVerificationRequest,
    verificationInfo?.tierUpgrade?.availableTierUpgrade,
    verificationInfo?.tierUpgrade?.isUpgradeInitiated,
    verificationInfo?.tierUpgrade?.isUpgradeRejected,
  ]);
  const methods = useForm<CompanyVerificationFormState>({
    mode: 'all',
    resolver: yupResolver(companyVerificationFormValidator(intl)),
    defaultValues: prefilledFormValues,
  });
  return <ReactHookFormProvider {...methods}>{children}</ReactHookFormProvider>;
}
