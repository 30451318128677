import '@glints/poppins';
// Ordering is important here. We need to load the debug utils before any other imports
import './debugUtils';
import './modules/AppLanguage/initializeAppLanguage';
import './common/apm';
import './lazysizes';
import './polyfills';

import React from 'react';
import { ThumborProvider } from '@glints/fast-image';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { ConnectedRouter } from 'connected-react-router';
import {
  AlertProvider,
  AlertWithProvider,
  ModalProvider,
  ModalWithProvider,
} from 'glints-aries/es/@next';
import { parse, stringify } from 'query-string';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { fetchFeatures } from './actions/features';
import { ApolloConfig } from './apollo-client';
import { App } from './app';
import ErrorBoundaryWithCrashReporting from './components/Error/ErrorBoundaryWithCrashReporting';
import SessionInitializer from './components/SessionInitializer';
import config from './config';
import IntlProvider from './IntlProvider';
import { Error500Page } from './modules/ErrorPages';
import { reactQueryClient } from './react-query-client';
import store, { browserHistory } from './store';
import { SWRConfig } from './swr';

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],

  // Release information, used for Sentry to decipher source maps.
  release: process.env.BUILD_ID,

  // Adjust this value in production, or use tracesSampler for greater control
  // https://docs.sentry.io/platforms/javascript/configuration/sampling/
  tracesSampleRate: 0.3,

  // Environment
  environment: config.ENVIRONMENT,

  // Disable on development and feature review app
  enabled: Boolean(config.SENTRY_DSN),

  // Adjust this value in the case of sending Redux state
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
  normalizeDepth: 10,

  beforeBreadcrumb: excludeGraphQLFetch,
});

async function renderApp() {
  const container = document.getElementById('app');
  const root = createRoot(container as Element);
  store.dispatch(fetchFeatures() as any);

  root.render(
    <Provider store={store}>
      <IntlProvider>
        <ErrorBoundaryWithCrashReporting
          fallback={<Error500Page />}
          transactionName="global-error-boundary"
        >
          {/* @ts-ignore */}
          <ThumborProvider thumborServerURL={config.THUMBOR_BASE}>
            <ApolloConfig>
              <QueryClientProvider client={reactQueryClient}>
                <SWRConfig store={store}>
                  {/* @ts-ignore */}
                  <ConnectedRouter history={browserHistory}>
                    <CompatRouter>
                      <QueryParamProvider
                        adapter={ReactRouter5Adapter}
                        options={{
                          searchStringToObject: parse,
                          objectToSearchString: stringify,
                        }}
                      >
                        <Switch>
                          <Route>
                            <ConfigProvider locale={enUS}>
                              <div className="app">
                                <SessionInitializer>
                                  <AlertProvider>
                                    <AlertWithProvider />
                                    <ModalProvider>
                                      <ModalWithProvider />
                                      <App />
                                    </ModalProvider>
                                  </AlertProvider>
                                </SessionInitializer>
                              </div>
                            </ConfigProvider>
                          </Route>
                        </Switch>
                      </QueryParamProvider>
                    </CompatRouter>
                  </ConnectedRouter>
                </SWRConfig>
              </QueryClientProvider>
            </ApolloConfig>
          </ThumborProvider>
        </ErrorBoundaryWithCrashReporting>
      </IntlProvider>
    </Provider>
  );
}

renderApp();
