import { EmployerPurchaseProductType } from '../../../../common/interfaces/entities';
import { TrackingService } from '../../../../common/tracking/trackingService';
import { productTypeToTrackingNameMapping } from './constants';

export const trackUserAgreeToTOS = (
  productType: EmployerPurchaseProductType,
  quantity: number
) => {
  TrackingService.logEvent('gc_user_agree_to_tos', {
    product_name: productTypeToTrackingNameMapping[productType],
    product_quantity: quantity,
  });
};

export const trackPaidFeatureSuccessfullyAdded = (
  productType: EmployerPurchaseProductType,
  quantity: number
) => {
  TrackingService.logEvent('gc_paid_features_successfully_added', {
    product_name: productTypeToTrackingNameMapping[productType],
    product_quantity: quantity,
  });
};
