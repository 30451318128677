import React, { ReactNode, useId } from 'react';
import { Flex } from 'glints-aries/es';
import { RadioButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space12,
  space24,
} from 'glints-aries/es/@next/utilities/spacing';
import { FieldPath, useController } from 'react-hook-form';

import {
  useCompanyVerificationFormContext,
  useIsViewOnlyMode,
} from './FormContext';
import { FormContentStyles } from './styles.sc';
import { CompanyVerificationFormState } from './types';

type RadioGroupProps = {
  label: ReactNode;
  tooltip?: ReactNode;
  value: string;
}[];

type TextFieldProps = {
  title: ReactNode;
  isRequired?: boolean;
  fieldName: FieldPath<CompanyVerificationFormState>;
  options: RadioGroupProps;
  direction?: 'row' | 'column';
};

export default function RadioGroup({
  title,
  isRequired,
  fieldName,
  options,
  direction = 'row',
}: TextFieldProps) {
  const { control } = useCompanyVerificationFormContext();
  const { trigger } = useCompanyVerificationFormContext();
  const {
    field: { onBlur, onChange, ref, value: selectedValue },
  } = useController({
    name: fieldName,
    control,
  });
  const id = useId();
  const isViewOnly = useIsViewOnlyMode();
  return (
    <Flex flexDirection="column" gap={space12}>
      <Typography variant="body2">
        {title}
        {isRequired && <FormContentStyles.Asterisk />}
      </Typography>
      <Flex
        flexDirection={direction}
        gap={space24}
        style={{
          rowGap: space4,
        }}
      >
        {options.map(option => {
          const isChecked = selectedValue === option.value;
          return (
            <RadioButton
              key={option.value}
              ref={ref}
              name={id}
              checked={isChecked}
              label={
                <Flex gap={space4}>
                  <span
                    style={{
                      color: isChecked ? Neutral.B18 : Neutral.B40,
                    }}
                  >
                    {option.label}
                  </span>
                  {option.tooltip}
                </Flex>
              }
              value={option.value}
              onChange={e => {
                onChange(e);
                trigger(fieldName);
              }}
              onBlur={onBlur}
              disabled={isViewOnly}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}
