import axios from '../../../common/axios-ts';
import { AssetType } from '../../../common/constants';
import config from '../../../config';

export const getFileMetadata = async (name: string, label: AssetType) => {
  try {
    const response = await axios(null, config.AWS_BASE).get(`/s3Metadata`, {
      params: {
        name,
        label,
      },
    });

    return response.data;
  } catch (err) {
    return false;
  }
};
