import { ApolloError, gql, useMutation } from '@apollo/client';
import { noop } from 'lodash-es';
import { useSelector } from 'react-redux';

import {
  BusinessVerificationLocationType,
  CompanyVerificationInfo,
  VerificationMethod,
} from '../../../../common/interfaces/entities';
import { getSessionCompanyId } from '../../../../selectors/session';

const query = gql`
  mutation requestCompanyVerification(
    $input: RequestCompanyVerificationInput!
  ) {
    requestCompanyVerification(input: $input) {
      ...companyVerificationInfoFragment
    }
  }
`;

type LegalDocumentInput = {
  verificationMethod: typeof VerificationMethod.LEGAL_DOCUMENTS;
  legalDocumentsData: {
    companyNPWPDoc: string;
    companyNPWPNumber: string;
    companyNIBNumber: string | null;
  };
};

type ProofOfOwnershipInput = {
  verificationMethod: typeof VerificationMethod.PROOF_OF_OWNERSHIP;
  proofOfOwnershipData: {
    personalNIBNumber: string | null;
    businessActivityImages: string[];
    businessLocationType: BusinessVerificationLocationType;
  } & (
    | {
        personalNPWPDoc: string;
        personalNPWPNumber: string;
      }
    | {
        ktpDoc: string;
        ktpNumber: string;
      }
  );
};

type QueryInput = {
  input: {
    companyId: string;
  } & (LegalDocumentInput | ProofOfOwnershipInput);
};

type QueryOutput = {
  requestCompanyVerification: CompanyVerificationInfo;
};
export default function useRequestCompanyVerification() {
  const [mutation, { loading, error, client }] = useMutation<
    QueryOutput,
    QueryInput
  >(query);
  const companyId: string = useSelector(getSessionCompanyId);

  const requestCompanyVerificationRequest = async ({
    input,
    onCompleted,
    onError,
  }: {
    input: LegalDocumentInput | ProofOfOwnershipInput;
    onCompleted?: (data: QueryOutput['requestCompanyVerification']) => void;
    onError?: (error: ApolloError) => void;
  }) => {
    await mutation({
      variables: {
        input: {
          ...input,
          companyId,
        },
      },
      onCompleted: data => {
        onCompleted?.(data.requestCompanyVerification);
        client.cache.modify({
          id: client.cache.identify({
            __typename: 'Company',
            id: companyId,
          }),
          fields: {
            status() {
              return (
                data.requestCompanyVerification?.lastVerificationRequest
                  ?.company.status || null
              );
            },
            verificationInfo() {
              return data.requestCompanyVerification;
            },
          },
        });
      },
      onError: onError ?? noop,
    });
  };

  return {
    loading,
    error,
    requestCompanyVerificationRequest,
  };
}
