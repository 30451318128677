import dayjs from 'dayjs';

import { CompanyStatus } from '../constants';
import useGetCompanyById from './useGetCompanyById';
import useIsTierVerificationEnabled from './useIsTierVerificationEnabled';

const REQUEST_VERIFICATION_THRESHOLD_DAYS = 2;
const SUCCESS_VERIFICATION_THRESHOLD_DAYS = 7;
export default function useGetCompanyVerificationInfo() {
  const { company } = useGetCompanyById();
  const isTierVerificationEnabled = useIsTierVerificationEnabled();
  const verificationInfo = company?.verificationInfo;
  const isUnverified = company && company.status === CompanyStatus.UNVERIFIED;

  const canShowInitiateVerification = Boolean(
    verificationInfo && !verificationInfo.isInitiated && isUnverified
  );

  const isVerificationInitiatedWithTierVerification =
    verificationInfo &&
    verificationInfo.isInitiated &&
    verificationInfo.lastVerificationRequest;

  const canShowCompanyVerificationModal = Boolean(
    isTierVerificationEnabled &&
      verificationInfo &&
      (!verificationInfo.isInitiated ||
        isVerificationInitiatedWithTierVerification)
  );
  const companyVerificationInProgress =
    isUnverified && verificationInfo?.isInitiated;
  const canShowAssistanceActionForVerificationInProgress = Boolean(
    isTierVerificationEnabled &&
      companyVerificationInProgress &&
      verificationInfo?.lastVerificationRequest &&
      dayjs(verificationInfo.lastVerificationRequest.createdAt).diff(
        dayjs(),
        'days'
      ) > REQUEST_VERIFICATION_THRESHOLD_DAYS
  );

  const hasTierUpgrade =
    isTierVerificationEnabled &&
    verificationInfo?.tierUpgrade &&
    verificationInfo.lastVerificationRequest;

  const isCompanyVerificationRejected =
    !verificationInfo?.tierUpgrade && verificationInfo?.isRejected;

  return {
    company,
    verificationInfo: verificationInfo,
    canVerifyCompany:
      canShowInitiateVerification || isCompanyVerificationRejected,
    canShowVerificationBanner:
      isTierVerificationEnabled &&
      (canShowInitiateVerification || isCompanyVerificationRejected),

    canShowTierUpgradeBanner:
      hasTierUpgrade &&
      (verificationInfo?.tierUpgrade?.isUpgradeRejected ||
        !verificationInfo?.tierUpgrade?.isUpgradeInitiated),

    dashboardNotification: {
      canShowVerifyCompanyNotification: canShowInitiateVerification,
      canShowCompanyVerificationInProgressNotification:
        companyVerificationInProgress,
      canShowAssistanceActionForVerificationInProgress,
      canShowCompanyVerificationRejectedNotification: Boolean(
        verificationInfo?.isRejected ||
          verificationInfo?.tierUpgrade?.isUpgradeRejected
      ),
      canShowTierUpgradeNotification: Boolean(
        hasTierUpgrade && !verificationInfo?.tierUpgrade?.isUpgradeInitiated
      ),
      canShowTierUpgradeInProgressNotification:
        verificationInfo?.tierUpgrade &&
        verificationInfo.tierUpgrade.isUpgradeInitiated &&
        !verificationInfo.tierUpgrade.isUpgradeRejected,
      canShowVerificationRejectedStatusInToolbar: Boolean(
        verificationInfo?.isRejected && !verificationInfo?.tierUpgrade
      ),
    },
    modals: {
      canShowCompanyVerificationRejectedModal: Boolean(
        isTierVerificationEnabled &&
          !verificationInfo?.tierUpgrade &&
          verificationInfo?.lastVerificationRequest &&
          verificationInfo.isRejected
      ),
      canShowCompanyVerificationSuccessModal:
        isTierVerificationEnabled &&
        verificationInfo?.lastVerificationRequest &&
        company?.status === CompanyStatus.VERIFIED &&
        verificationInfo.lastVerificationRequest.approvedAt &&
        dayjs(verificationInfo.lastVerificationRequest.approvedAt).diff(
          dayjs(),
          'days'
        ) <= SUCCESS_VERIFICATION_THRESHOLD_DAYS,
    },
    canShowCompanyVerificationModal,
  };
}
