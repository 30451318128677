export function getAssetFileNameFromURL(url: string) {
  const _url = new URL(url);
  return _url.pathname.split('/').pop();
}
export function isURL(value: string) {
  try {
    return Boolean(new URL(value));
  } catch (_) {
    return false;
  }
}
