import { ContentState, RawDraftContentState } from 'draft-js';
import { IntlShape, MessageDescriptor } from 'react-intl';
import * as Yup from 'yup';

import {
  countryCodeIsIndonesia,
  isHierarchicalLocationSupportedForCountryCode,
} from '../../../common/helpers/countryCodeHelper';
import { getFirstForbiddenKeywordByCountry } from '../../../common/validationHelper';
import config from '../../../config';
import { COMPANY_SIZES } from '../SignUpPage/constants';
import { errorMessages, messages } from './companyCreationMessages';

export const COMPANY_CREATION_STAGE = {
  COMPANY_NAME: 'COMPANY_NAME',
  COMPANY_FORM: 'COMPANY_FORM',
  USER_WHATSAPP_VERIFICATION: 'USER_WHATSAPP_VERIFICATION',
};

const COMPANY_DESCRIPTION_MAX_LENGTH = 1000;

export interface CompanyFormState {
  name: string;
  logo: string;
  website?: string;
  brandName?: string;
  size: (typeof COMPANY_SIZES)[keyof typeof COMPANY_SIZES];
  tagline: string;
  description: ContentState;
  descriptionRaw: RawDraftContentState;
  IndustryId: string;
  CountryCode: string;
  locationProvinceId?: string;
  locationCityId?: string;
  CityId?: number;
  labelForCityId?: string;
  LocationId?: number;
  address?: string;
  acquisitionReferralCode?: string;
  stage: (typeof COMPANY_CREATION_STAGE)[keyof typeof COMPANY_CREATION_STAGE];
  companyExistsError?: string;
}

export const initialValues: Partial<CompanyFormState> = {
  name: '',
  logo: '',
  website: '',
  size: COMPANY_SIZES.BETWEEN_11_AND_50,
  tagline: '',
  IndustryId: '',
  CountryCode: config.COUNTRY?.toUpperCase(),
  locationProvinceId: undefined,
  locationCityId: undefined,
  CityId: undefined,
  LocationId: undefined,
  brandName: undefined,
  companyExistsError: undefined,
  address: '',
  stage: COMPANY_CREATION_STAGE.COMPANY_NAME,
};

export const referralCodeRegex = new RegExp('^GLVN-\\d{3}$');
const MIN_COMPANY_NAME_LENGTH = 3;
function testForForbiddenKeywords({
  fieldName,
  formatMessage,
  stringSchema,
  path,
}: {
  formatMessage: IntlShape['formatMessage'];
  stringSchema: Yup.StringSchema;
  fieldName: MessageDescriptor;
  path: string;
}) {
  return stringSchema.test({
    name: 'noForbiddenKeywords',
    test: function (this: any, name: any) {
      const firstForbiddenWord = getFirstForbiddenKeywordByCountry(
        name,
        (config.COUNTRY as string).toUpperCase(),
        false
      );
      return (
        (firstForbiddenWord &&
          this.createError({
            message: formatMessage(errorMessages.hasForbiddenKeywords, {
              field: formatMessage(fieldName),
              keyword: firstForbiddenWord,
            }),
            path: path,
          })) ||
        true
      );
    },
  });
}
export const companyFormValidationSchema = (
  formatMessage: IntlShape['formatMessage'],
  isSimplifiedOnboaringEnabled: boolean
) =>
  Yup.object().shape({
    name: testForForbiddenKeywords({
      path: 'name',
      stringSchema: Yup.string()
        .required(formatMessage(errorMessages.companyNameIsRequired))
        .min(
          MIN_COMPANY_NAME_LENGTH,
          formatMessage(errorMessages.enterAtLeastThreeCharacters)
        ),
      fieldName: messages.companyName,
      formatMessage,
    }),
    logo: Yup.string().when([], {
      is: () => !isSimplifiedOnboaringEnabled,
      then: Yup.string().required(
        formatMessage(errorMessages.thisFieldIsRequired)
      ),
      otherwise: Yup.string().optional(),
    }),
    brandName: testForForbiddenKeywords({
      path: 'brandName',
      stringSchema: Yup.string()
        .optional()
        .test(
          'brandNameLen',
          formatMessage(errorMessages.enterAtLeastThreeCharacters),
          value => {
            if (!value) return true;
            return value.length >= MIN_COMPANY_NAME_LENGTH;
          }
        ),
      fieldName: messages.brandName,
      formatMessage,
    }),
    size: Yup.string().required(
      formatMessage(errorMessages.thisFieldIsRequired)
    ),
    IndustryId: Yup.string().required(
      formatMessage(errorMessages.thisFieldIsRequired)
    ),
    website: Yup.string().matches(
      /^(((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?|)$/,
      formatMessage(errorMessages.enterValidWebsite)
    ),
    tagline: Yup.string().notRequired(),
    description: Yup.mixed().when([], {
      is: () => !isSimplifiedOnboaringEnabled,
      then: Yup.mixed()
        .test({
          name: 'checkMaxDescription',
          test: function (this: any, value: any) {
            return value?.getPlainText()?.length >
              COMPANY_DESCRIPTION_MAX_LENGTH
              ? this.createError({
                  message: formatMessage(errorMessages.cannotExceedMaxLength, {
                    count: COMPANY_DESCRIPTION_MAX_LENGTH,
                  }),
                  path: 'description',
                })
              : true;
          },
        })
        .test({
          name: 'checkDescriptionRequired',
          test: function (this: any, value: any) {
            return value?.getPlainText()?.trim().length === 0
              ? this.createError({
                  message: formatMessage(errorMessages.thisFieldIsRequired),
                  path: 'description',
                })
              : true;
          },
        }),
      otherwise: Yup.mixed().optional(),
    }),
    CountryCode: Yup.string().required(
      formatMessage(errorMessages.thisFieldIsRequired)
    ),
    CityId: Yup.mixed().when('CountryCode', (countryCode, schema) =>
      isHierarchicalLocationSupportedForCountryCode(countryCode)
        ? schema.optional()
        : schema.required(formatMessage(errorMessages.thisFieldIsRequired))
    ),
    locationProvinceId: Yup.mixed().when(
      'CountryCode',
      (countryCode, schema) =>
        countryCodeIsIndonesia(countryCode)
          ? schema.required(formatMessage(errorMessages.thisFieldIsRequired))
          : schema.optional()
    ),
    locationCityId: Yup.mixed().when('CountryCode', (countryCode, schema) =>
      isHierarchicalLocationSupportedForCountryCode(countryCode)
        ? schema.required(formatMessage(errorMessages.thisFieldIsRequired))
        : schema.optional()
    ),
    address: Yup.string().when([], {
      is: () => !isSimplifiedOnboaringEnabled,
      then: Yup.string().required(
        formatMessage(errorMessages.thisFieldIsRequired)
      ),
      otherwise: Yup.string().optional(),
    }),
    acquisitionReferralCode: Yup.string()
      .optional()
      .matches(referralCodeRegex, {
        excludeEmptyString: true,
        message: formatMessage(errorMessages.invalidReferralCode),
      }),
  });
