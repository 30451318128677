import { defineMessages } from 'react-intl';

export const companyHeaderMessages = defineMessages({
  viewCompanyProfile: {
    id: 'interactive-edit-company-profile',
    defaultMessage: 'View Company Profile',
  },
  manageTeamMembers: {
    id: 'interactive-manage-team-members',
    defaultMessage: 'Manage Team Members',
  },
  viewJobPostUsage: {
    id: 'interactive-view-job-posts-usage',
    defaultMessage: 'View Job Posts Usage',
  },
  viewMySubscription: {
    id: 'interactive-view-my-subscription',
    defaultMessage: 'View My Subscription',
  },
});

export const verificationMessages = defineMessages({
  unverified: {
    id: 'text-unverified',
    defaultMessage: 'Unverified',
  },
  verifyCompany: {
    id: 'text-verify-company',
    defaultMessage: 'Verify Company',
  },
  uploadCompanyNPWP: {
    id: 'text-upload-company-npwp',
    defaultMessage: 'Upload Company NPWP',
  },
  uploadCompanyLegalDocument: {
    id: 'text-upload-company-legal-document',
    defaultMessage: 'Upload Company Legal Document',
  },
  ifYourCompany: {
    id: 'text-if-no-company-npwp',
    defaultMessage:
      "If your company doesn't have NPWP, you can upload your SIUP, NIB, or your personal NPWP.",
  },
  verifyYourCompany: {
    id: 'text-verify-uploading-legal-doc',
    defaultMessage:
      'Verify your company by uploading your legal document to start posting jobs.',
  },
  selectFile: {
    id: 'interactive-select-file',
    defaultMessage: 'Select File',
  },
  acceptableFormat: {
    id: 'text-acceptable-format-company-npwp',
    defaultMessage:
      'Acceptable formats: pdf, jpg, jpeg, and png. Maximum size: 50mb',
  },
  nib: {
    id: 'text-13-digit-nib',
    defaultMessage: '13 Digits NIB (Nomor Induk Berusaha)',
  },
  enterNIB: {
    id: 'text-enter-nib-optional',
    defaultMessage: 'Enter NIB (Optional)',
  },
  companyIdentificationNumber: {
    id: 'text-company-identification-number',
    defaultMessage: 'Company Identification Number',
  },
  enterCompanyIdentificationNumber: {
    id: 'text-enter-identification-number',
    defaultMessage: 'Enter identification number (Optional)',
  },
  invalidNIB: {
    id: 'text-you-entered-invalid-nib',
    defaultMessage: 'You have entered an invalid NIB. Please try again.',
  },
  documentUploaded: {
    id: 'text-documents-uploaded',
    defaultMessage: 'Documents Uploaded',
  },
  companyVerificationTakesTwoDays: {
    id: 'text-company-verification-two-days',
    defaultMessage: 'Company verification will take{br}around 2 business days.',
  },
  error: {
    id: 'text-error',
    defaultMessage: 'Error',
  },
  somethingWentWrong: {
    id: 'text-something-went-wrong',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
  verificationInProgress: {
    id: 'text-verification-in-progress-banner',
    defaultMessage: 'Verification In Progress',
  },
  viewDocuments: {
    id: 'interactive-view-documents',
    defaultMessage: 'View Documents',
  },
  verificationFailed: {
    id: 'interactive-verification-failed',
    defaultMessage: 'Verification Failed',
  },
  verificationUnsuccessful: {
    id: 'text-verification-unsuccessful',
    defaultMessage: 'Verification Unsuccessful',
  },
  verified: {
    id: 'interactive-verified',
    defaultMessage: 'Verified',
  },
  bannerInProgress: {
    id: 'text-job-posted-after-verification',
    defaultMessage:
      'Company verification will take around 2 business days. Your job posts will automatically be posted upon successful verification.',
  },
  bannerRejected: {
    id: 'text-document-rejected-please-reupload',
    defaultMessage:
      'Your document has been rejected. Please reupload it in order to verify your company',
  },
  tooltip: {
    id: 'text-verify-company-uploading-npwp',
    defaultMessage:
      'Verify your company by uploading your NPWP to begin posting jobs.',
  },
});
