import React, { useState } from 'react';
import { Flex } from 'glints-aries/es';
import { Icon, Typography } from 'glints-aries/es/@next';
import { Blue, Orange } from 'glints-aries/es/@next/utilities/colors';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from 'react-intl';
import styled from 'styled-components';

import { assertIsDefined } from '../../../common/helpers/assertIsDefined';
import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import {
  Company,
  VerificationMethod,
} from '../../../common/interfaces/entities';
import CompanyVerificationModalVariantController from '../../../modules/CompanyDashboardV2/CompanyHeader/components/CompanyVerificationModalVariantController';
import NodesJoin from '../../NodesJoin';
import { StickyContainer, StyledBanner } from '../styles.sc';

type Props = {
  setFixedBannerHeight: (height: number) => void;
  company: Pick<Company, 'verificationInfo'>;
};

const messages = defineMessages({
  verify: {
    id: 'interactive-verify',
    defaultMessage: 'Verify',
  },
});

const BannerWrapper = styled.div`
  .warning-icon {
    fill: ${Orange.S87};
  }
`;

const VerificationMethodToContentMessage: Record<
  VerificationMethod,
  MessageDescriptor | null
> = {
  [VerificationMethod.LEGAL_DOCUMENTS]: {
    id: 'text-get-a-verified-badge',
    defaultMessage:
      'Get a verified badge and post more jobs by verifying with Legal Documents.',
  },
  [VerificationMethod.PROOF_OF_OWNERSHIP]: null,
} as const;

export default function CompanyTierUpgradeBanner({
  setFixedBannerHeight,
  company: { verificationInfo },
}: Props) {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });
  assertIsDefined(verificationInfo);
  const { tierUpgrade } = verificationInfo;
  assertIsDefined(tierUpgrade);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);

  const [openVerificationModal, closeVerificationModal] = [
    () => setIsVerificationModalOpen(true),
    () => setIsVerificationModalOpen(false),
  ];
  const tierUpgradeVerificationMethod = tierUpgrade.availableTierUpgrade[0];
  const verificationMethodContentMessage =
    VerificationMethodToContentMessage[
      tierUpgradeVerificationMethod.verificationMethod
    ];

  if (!verificationMethodContentMessage) {
    throw new Error(
      `Verification method content message is not defined, ${tierUpgradeVerificationMethod.verificationMethod}`
    );
  }

  return (
    <StickyContainer ref={ref}>
      <CompanyVerificationModalVariantController
        isOpen={isVerificationModalOpen}
        onClose={closeVerificationModal}
      />
      <BannerWrapper>
        <StyledBanner type="fixed" status="warning" showIcon={false}>
          <Flex
            alignItems="flex-end"
            justifyContent="center"
            gap={space12}
            style={{
              width: '100%',
            }}
          >
            <Icon
              name="ri-error-warning-fill"
              className="warning-icon"
              fill={Orange.S87}
            />
            <Typography variant="body2" as="div">
              <NodesJoin separator={' '}>
                <FormattedMessage
                  {...verificationMethodContentMessage}
                  tagName="span"
                />
                <span onClick={openVerificationModal}>
                  <Typography
                    variant="body2"
                    color={Blue.S99}
                    as="span"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <FormattedMessage {...messages.verify} />
                  </Typography>
                </span>
              </NodesJoin>
            </Typography>
          </Flex>
        </StyledBanner>
      </BannerWrapper>
    </StickyContainer>
  );
}
