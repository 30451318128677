import { Banner, Divider, Modal, Typography } from 'glints-aries/es/@next';
import { Blue, Neutral, Red } from 'glints-aries/es/@next/utilities/colors';
import {
  space8,
  space12,
  space16,
  space32,
  space40,
} from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

import { WhatsAppGreen } from '../../../common/color';

export const StyledModal = styled(Modal)`
  .modal-content {
    min-width: 980px;
    max-width: 980px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`;
export const StyledLoadingModal = styled(StyledModal)`
  .modal-content {
    height: 500px;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`;
export const PaddedContainer = styled.div`
  padding: 0 20px;
`;

export const VerificationModalBanner = styled(Banner)`
  min-height: unset;
`;

export const TypographyWithBulletPoint = styled(Typography)`
  display: flex;
  column-gap: ${space8};
  ::before {
    content: '\u2022';
    padding-inline-start: 10px;
  }
`;

export const VerificationMethodCardStyles = {
  Container: styled.div`
    border: 1px solid ${Neutral.B85};
    border-radius: ${space8};
    display: flex;
    flex-direction: column;
    gap: ${space12};
    padding: 20px;
    position: relative;
    flex: 1;
    cursor: pointer;

    &[data-active='true'] {
      border-color: ${Blue.S99};
      background-color: ${Blue.S08};
    }

    &[data-view-only='true'] {
      flex-direction: row;
      gap: ${space40};
      cursor: default;
    }
  `,
  Divider: styled(Divider)`
    &[data-view-only='true'] {
      height: 100%;
      width: 1px;
    }
  `,
  TagContainer: styled.div`
    position: absolute;
    display: flex;
    margin: 0 auto;
    left: ${space8};
    right: 0;
    top: -10px;
    min-width: fit-content;
    z-index: 1;
  `,
};

export const FormContentStyles = {
  VisibilityContainer: styled.div<{
    visible: boolean;
  }>`
    display: ${props => (props.visible ? 'block' : 'none')};
  `,
  BackgroundContainer: styled.div`
    background-color: ${Neutral.B95};
  `,
  Container: styled.div`
    padding: 20px ${space32};
    display: flex;
    align-items: flex-start;
    gap: 20px;
  `,
  TypographyWithBulletPoint: TypographyWithBulletPoint,
  FullWidthCardContainer: styled.div`
    flex: 1;
  `,
  Asterisk: styled.span`
    ::before {
      color: ${Red.B93};
      content: '*';
    }
  `,
  WhatsAppVerificationStatusContainer: styled.div`
    padding: ${space16};
    background-color: ${Neutral.B99};

    button > svg {
      fill: ${WhatsAppGreen};
    }
  `,
  ImageUploadButton: styled.button`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: ${space8};
    border: 1px dashed ${Neutral.B68};
    background-color: ${Neutral.B99};
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &[data-image-selected='true'] {
      border-style: solid;
    }

    &[data-ignore-padding='true'] {
      padding: 0;
    }

    .remove-file-icon {
      position: absolute;
      bottom: 4px;
      left: 4px;
      border-radius: 100%;
      border: 1px solid ${Neutral.B68};
      background-color: ${Neutral.B100};
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &[data-error='true'] {
      border-color: ${Red.B93};
      color: ${Red.B93};
      svg {
        fill: ${Red.B93};
      }
    }
  `,
  RequiredSectionContainer: styled.div`
    .card-container {
      max-width: 249px;
    }
  `,
};
