export const ROUTES = {
  companyLocationUpdateRoute: 'company-location',
  upgradePlanRoute: 'upgrade-plan',
  dashboardRoute: 'dashboard',
  jobCreate: 'job/create',
  talentSearch: 'talent-search',
  onboarding: 'onboarding',
  logout: 'logout',
  companyReportStatus: 'company-report-status',
  emailVerification: 'email-verification',
  jobEnhance: 'job/enhance',
  manageCandidates: 'manage-candidates',
  recommendedTalents: 'recommended-talents',
  messages: 'messages',
  features: 'features',
  featuresHistory: 'features/history',
  featuresOrderHistory: 'features/order-history',
  login: 'login',
  signup: 'signup',
  forgotPassword: 'forgot-password',
};
