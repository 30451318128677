import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Greyscale,
  PrimaryColor,
  ScreenSize,
} from 'glints-aries/es';
import {
  Banner,
  Button,
  Divider,
  Icon,
  Modal,
  OutlineMonochromeButton,
  Typography,
  useAlert,
} from 'glints-aries/es/@next';
import { get } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { useGetAssetURL } from '../../../../common/asset-manager';
import { ASSET_TYPES, CompanyStatus } from '../../../../common/constants';
import useUploadFile from '../../../../common/hooks/requests/useUploadFile';
import { useAxiosWithAPI } from '../../../../common/hooks/useAxiosWithAPI';
import { Company } from '../../../../common/interfaces/entities';
import updateSessionCompany from '../../../../common/updateSessionCompany';
import { useAppDispatch } from '../../../../store';
import { verificationMessages } from '../companyHeaderMessages';
import { getFileMetadata } from '../helper';

type VNCompanyVerificationModalProps = {
  company: Company;
  isOpen: boolean;
  onClose: () => void;
};

const Styled = {
  Modal: styled(Modal)`
    max-width: 980px;

    @media (max-width: ${ScreenSize.tablet - 1}px) {
      .modal-content {
        width: 100%;
        padding: 24px 16px;
      }
    }
  `,
  ContentWrapper: styled.div`
    display: flex;
    gap: 40px;

    @media (max-width: ${ScreenSize.tablet - 1}px) {
      flex-direction: column;
    }
  `,
  SampleImageWrapper: styled.div`
    margin: 0 auto;
    max-width: 280px;
  `,
};

const VNCompanyVerificationModal = ({
  company,
  isOpen,
  onClose,
}: VNCompanyVerificationModalProps) => {
  const intl = useIntl();
  const alert = useAlert();
  const axios = useAxiosWithAPI();
  const dispatch = useAppDispatch();
  const [stagedLegalDocument, setStagedLegalDocument] = useState<string>();
  const {
    file,
    loading,
    error,
    uploadFile,
    reset: resetFile,
    setFile,
  } = useUploadFile({
    assetType: ASSET_TYPES.COMPANY_LEGAL_DOCUMENT,
    maxSizeInMB: 50,
    allowedMimeTypes: ['application/pdf', 'image/jpeg', 'image/png'],
    onSuccess: maskedFileName => {
      setStagedLegalDocument(maskedFileName);
    },
  });

  const { getAssetURL } = useGetAssetURL();

  useEffect(() => {
    const initFile = async () => {
      resetFile();

      if (company.legalDocument) {
        const metadata = await getFileMetadata(
          company.legalDocument,
          ASSET_TYPES.COMPANY_LEGAL_DOCUMENT
        );

        if (metadata) {
          setFile(
            new File(
              [],
              `${metadata.name} (${(metadata.size / 1024 / 1024).toFixed(
                2
              )} MB)`
            )
          );
        }
      }
    };

    setStagedLegalDocument(undefined);
    initFile();
  }, [company.id, company.legalDocument, setFile, resetFile]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const handleFileReset = () => {
    resetFile();
    setStagedLegalDocument(undefined);
  };

  const handleSubmit = async () => {
    if (!stagedLegalDocument) return;

    try {
      await axios.put(`/companies/${company.id}`, {
        data: {
          legalDocument: stagedLegalDocument,
        },
      });

      dispatch(updateSessionCompany(company.id) as any);

      onClose();
      alert.open({
        status: 'success',
        title: intl.formatMessage(verificationMessages.documentUploaded),
        content: intl.formatMessage(
          verificationMessages.companyVerificationTakesTwoDays,
          {
            br: <br />,
          }
        ),
      });
    } catch (error) {
      const errMessage =
        get(error, 'response.data.error.details.[0]') ??
        intl.formatMessage(verificationMessages.somethingWentWrong);

      alert.open({
        status: 'error',
        title: intl.formatMessage(verificationMessages.error),
        content: errMessage,
        zIndex: 1000,
      });
    }
  };

  const companyInReview = Boolean(
    company.status === CompanyStatus.UNVERIFIED && company.legalDocument
  );

  const docPreview = stagedLegalDocument ?? company.legalDocument;

  return (
    <Styled.Modal
      isOpen={isOpen}
      header={
        <FormattedMessage
          id="text-verify-company"
          defaultMessage="Verify Company"
        />
      }
      primaryAction={
        companyInReview
          ? {
              label: (
                <FormattedMessage
                  id="interactive-close"
                  defaultMessage="Close"
                />
              ),
              onClick: onClose,
            }
          : {
              label: (
                <FormattedMessage
                  id="interactive-send-for-verification"
                  defaultMessage="Send for verification"
                />
              ),
              disabled: !stagedLegalDocument,
              onClick: handleSubmit,
            }
      }
      onClose={onClose}
    >
      <Styled.ContentWrapper>
        <Styled.SampleImageWrapper>
          <img loading="lazy" src="/images/vn-bl-sample.jpg" />
        </Styled.SampleImageWrapper>

        <div>
          <If condition={companyInReview}>
            <Box mb={32}>
              <Banner status="info" dismissable={false}>
                <Typography variant="body1">
                  <FormattedMessage
                    id="text-verification-in-progress-desc"
                    defaultMessage="Thank you for submitting the information. Company verification will take around 2 business days. Your job posts will automatically be posted upon successful verification."
                  />
                </Typography>
              </Banner>
            </Box>
          </If>
          <Typography variant="body2">
            <FormattedMessage
              id="text-upload-company-npwp"
              defaultMessage="Upload Business License"
            />
            <span style={{ color: PrimaryColor.glintsred }}>*</span>
          </Typography>
          <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
            <FormattedMessage
              id="text-if-no-company-npwp"
              defaultMessage="Your data will be exclusively used for verification and will not be made public or disclosed."
            />
          </Typography>

          <Box mt={16}>
            <Choose>
              <When condition={file && docPreview}>
                {file && docPreview && (
                  <Flex justifyContent="space-between" alignItems="center">
                    <a
                      href={getAssetURL(
                        docPreview,
                        ASSET_TYPES.COMPANY_LEGAL_DOCUMENT
                      )}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Flex style={{ gap: 4 }}>
                        <Icon
                          name="ri-file-list-line"
                          width={20}
                          fill={Greyscale.devilsgrey}
                        />
                        <Typography variant="subtitle2">
                          {file?.name}
                        </Typography>
                      </Flex>
                    </a>
                    <OutlineMonochromeButton
                      disabled={companyInReview}
                      onClick={handleFileReset}
                    >
                      <Typography variant="button">
                        <FormattedMessage
                          id="interactive-remove"
                          defaultMessage="Remove"
                        />
                      </Typography>
                    </OutlineMonochromeButton>
                  </Flex>
                )}
              </When>

              <Otherwise>
                <Flex mb={8} style={{ gap: 12 }}>
                  <input
                    type="file"
                    id="modal-company-document"
                    accept="application/pdf, image/jpeg, image/png"
                    hidden={true}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="modal-company-document">
                    <Button
                      type="button"
                      loading={loading}
                      icon={<Icon name="ri-upload-line" />}
                      onClick={e => e.currentTarget.parentElement?.click()}
                    >
                      <Typography variant="button">
                        <FormattedMessage
                          id="interactive-select-file"
                          defaultMessage="Select File"
                        />
                      </Typography>
                    </Button>
                  </label>
                  <If condition={error}>
                    <Flex alignItems="center" style={{ gap: 4 }}>
                      <Icon
                        name="ri-error-warning-fill"
                        width={16}
                        fill={PrimaryColor.glintsred}
                      />
                      <Typography
                        variant="subtitle2"
                        color={PrimaryColor.glintsred}
                      >
                        {error}
                      </Typography>
                    </Flex>
                  </If>
                </Flex>

                <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
                  <FormattedMessage
                    id="text-acceptable-format-company-npwp"
                    defaultMessage="Acceptable formats: pdf, jpg, jpeg, and png. Maximum size: 50mb"
                  />
                </Typography>
              </Otherwise>
            </Choose>
          </Box>

          <Box my={32}>
            <Divider />
          </Box>

          <Typography variant="body2">
            <FormattedMessage
              id="text-company-verify-precaution-title"
              defaultMessage="Precaution"
            />
          </Typography>
          <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
            <FormattedMessage
              id="text-company-verify-precaution-desc"
              defaultMessage="1. Provide a valid Business License issued by your local authority.{br}2. Ensure that your company's legal name and business number are clearly displayed, as shown in the left image."
              values={{
                br: <br />,
              }}
            />
          </Typography>
        </div>
      </Styled.ContentWrapper>
    </Styled.Modal>
  );
};

export default VNCompanyVerificationModal;
