import React from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '../../../common/routes';
import ContactSupportButton from '../../../components/ContactSupportButton/ContactSupportButton';
import { getIsFeatureEnabled } from '../../../selectors/features';
import { UnleashFeatureNames } from '../../Unleash/featureNames';
import AuthPageMenuItemContent from './AuthPageMenuItemContent';
import { MenuItemsWithLogoutAndContactSupport } from './MenuItemsWithLogoutAndContactSupport/MenuItemsWithLogoutAndContactSupport';

const DefaultAlterNativeNavbarContentBasedOnPathName: Record<
  string,
  {
    navbarContent: JSX.Element | undefined;
    sidebarContent?: JSX.Element;
  }
> = {
  [`/${ROUTES.onboarding}`]: {
    navbarContent: <ContactSupportButton />,
  },
  [`/${ROUTES.emailVerification}`]: {
    navbarContent: <ContactSupportButton />,
  },

  [`/${ROUTES.companyReportStatus}`]: {
    navbarContent: <MenuItemsWithLogoutAndContactSupport />,
  },
};
export default function useGetAlternativeHeaderContent() {
  const isSimplifiedOnboardingEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empSimplifiedOnboardingFlow)
  );

  const memoizedAlternativeHeaderContent = React.useMemo(
    () =>
      isSimplifiedOnboardingEnabled
        ? {
            ...DefaultAlterNativeNavbarContentBasedOnPathName,
            [`/${ROUTES.login}`]: {
              navbarContent: <AuthPageMenuItemContent.NavbarContent />,
              sidebarContent: <AuthPageMenuItemContent.SidebarContent />,
            },
            [`/${ROUTES.signup}`]: {
              navbarContent: <AuthPageMenuItemContent.NavbarContent />,
              sidebarContent: <AuthPageMenuItemContent.SidebarContent />,
            },
          }
        : DefaultAlterNativeNavbarContentBasedOnPathName,
    [isSimplifiedOnboardingEnabled]
  );

  return memoizedAlternativeHeaderContent;
}
