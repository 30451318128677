import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';

import { setSessionCompany } from '../actions/session';
import config from '../config';
import { RootState } from '../store';
import axios from './axios-ts';

export default function updateSessionCompany(companyId?: string) {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const response = await axios(getState().session.token, config.API_BASE).get(
      `companies/${companyId}`
    );
    dispatch(setSessionCompany(response.data.data));
  };
}
