import { Banner, Modal } from 'glints-aries/es/@next';
import {
  space8,
  space12,
  space24,
} from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  max-width: 640px;
`;

export const StyledUploadDocument = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: ${space8};
  align-items: flex-start;
`;

export const StyledUploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space12};
  align-items: center;
`;

export const StyledFileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${space24} 0px;
`;

export const StyledFile = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
`;

export const StyledNIB = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  input {
    max-width: 60%;
  }
`;

export const StyledBanner = styled(Banner)`
  margin-bottom: 20px;
  min-height: 0px;
`;
