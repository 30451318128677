export const unverifiedCompanyRestrictionMessages = {
  header: {
    id: 'text-company-verification-required',
    defaultMessage: 'Company Verification Required',
  },
  needVerificationTalent: {
    id: 'text-company-needs-verification-talent',
    defaultMessage: `Your company needs to be verified before you can view the resume and chat with the talent.`,
  },
  needVerificationCandidate: {
    id: 'text-company-needs-verifications-candidate',
    defaultMessage:
      'Your company needs to be verified before you can view the resume and profile details of the candidate. After verification, you can also chat with the candidate directly.',
  },
  uploadDocuments: {
    id: 'text-please-ensure-you-upload',
    defaultMessage: `Please ensure you upload the necessary documents; if you have any questions, contact our support team via <link1>hello@glints.com</link1>`,
  },
  needVerificationAndUploadDocument: {
    id: 'text-your-company-needs-to',
    defaultMessage:
      'Your company needs to be verified in order to use this feature.{br}Please ensure you upload the necessary documents; if you have any questions, contact our support team via <link1>hello@glints.com</link1>',
  },
  close: {
    id: 'interactive-close',
    defaultMessage: 'Close',
  },
  verifyCompany: {
    id: 'text-verify-company',
    defaultMessage: 'Verify Company',
  },
};
