import React from 'react';
import { Flex } from 'glints-aries/es';
import { Card, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space12, space24 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { ASSET_TYPES } from '../../../common/constants';
import FileUploader from './FileUploader';
import { FormContentStyles } from './styles.sc';
import TextField from './TextField';

const requiredFieldsMessages: MessageDescriptor[] = [
  {
    id: 'text-company-npwp',
    defaultMessage: 'Company NPWP',
  },
  {
    id: 'text-company-npwp-number',
    defaultMessage: 'Company NPWP Number',
  },
  {
    id: 'text-company-nib-optional',
    defaultMessage: 'Company NIB (Optional)',
  },
];

export default function VerifyWithLegalDocumentsForm() {
  return (
    <FormContentStyles.Container>
      <FormContentStyles.RequiredSectionContainer>
        <Card>
          <Card.Section>
            <Flex flexDirection="column" gap={space12}>
              <Typography variant="body2">
                <FormattedMessage
                  id="text-required"
                  defaultMessage="Required"
                />
              </Typography>
              <Typography variant="body1" color={Neutral.B40} as="div">
                {requiredFieldsMessages.map((message, index) => (
                  <FormContentStyles.TypographyWithBulletPoint key={index}>
                    <FormattedMessage {...message} />
                  </FormContentStyles.TypographyWithBulletPoint>
                ))}
              </Typography>
            </Flex>
          </Card.Section>
        </Card>
      </FormContentStyles.RequiredSectionContainer>
      <FormContentStyles.FullWidthCardContainer>
        <Card>
          <Card.Section>
            <Flex flexDirection="column" gap={space24}>
              <FileUploader
                required={true}
                assetType={ASSET_TYPES.COMPANY_LEGAL_DOCUMENT}
                fieldName="verifyWithLegalDocuments.companyNPWPDoc"
                title={
                  <FormattedMessage
                    id="text-upload-company-npwp"
                    defaultMessage="Upload Company NPWP"
                  />
                }
                buttonText={
                  <FormattedMessage
                    id="interactive-select-file"
                    defaultMessage="Select File"
                  />
                }
              />
              <TextField
                title={
                  <FormattedMessage
                    id="text-company-npwp-number"
                    defaultMessage="Company NPWP Number"
                  />
                }
                isRequired={true}
                fieldName="verifyWithLegalDocuments.companyNPWPNumber"
              />
              <TextField
                title={
                  <FormattedMessage
                    id="text-13-digit-company-nib"
                    defaultMessage="13 Digit Company NIB - Optional"
                  />
                }
                helperText={
                  <FormattedMessage
                    id="text-providing-your-nib-speeds"
                    defaultMessage="Providing your NIB speeds up verification"
                  />
                }
                fieldName="verifyWithLegalDocuments.companyNIBNumber"
              />
            </Flex>
          </Card.Section>
        </Card>
      </FormContentStyles.FullWidthCardContainer>
    </FormContentStyles.Container>
  );
}
