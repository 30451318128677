import React from 'react';
import { BadgeStatusVariant } from 'glints-aries/es/@next/Badge';
import { IconNames } from 'glints-aries/es/@next/Icon/icons/icons';
import { compact } from 'lodash-es';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { SupportedCountry, URLS } from '../../common/constants';
import config from '../../config';

const solutionLabels = defineMessages({
  activeRecruitment: {
    id: 'modules.Platform.Constants.active.recruitment',
    defaultMessage: 'Active Recruitment',
  },
  strategicOffshoring: {
    id: 'modules.Platform.Constants.strategic.offshoring',
    defaultMessage: 'Strategic OffShoring',
  },
});

const SOLUTIONS_DETAILS: {
  [key: string]: {
    [country in SupportedCountry]?: MenuItem;
  } & { default: MenuItem };
} = {
  ACTIVE_RECRUITMENT: {
    id: {
      value: 'activeRecuitment',
      isExternal: true,
      label: solutionLabels.activeRecruitment,
      canBeDisabled: true,
      // eslint-disable-next-line
      to: `${URLS.signup.service.talentHunt.default}?utm_source=organic&utm_medium=banner&utm_campaign=talenthunt&utm_term=access`,
    },
    default: {
      value: 'activeRecuitment',
      label: solutionLabels.activeRecruitment,
      canBeDisabled: true,
      // eslint-disable-next-line
      to: '/talenthunt?utm_source=employersdashboard&utm_medium=ActiveRecruitmentButton&utm_campaign=TalentHunttraffic',
    },
  },
  STRATEGIC_OFFSHORING: {
    default: {
      value: 'strategicOffshoring',
      label: solutionLabels.strategicOffshoring,
      canBeDisabled: true,
      // eslint-disable-next-line
      to: '/talenthub?utm_source=employersdashboard&utm_medium=StrategicOffshoringbutton&utm_campaign=TalentHubtraffic',
    },
  },
};

const SOLUTIONS: { [country in SupportedCountry]?: MenuItem[] } & {
  default: MenuItem[];
} = {
  id: [SOLUTIONS_DETAILS.ACTIVE_RECRUITMENT.id as MenuItem],
  sg: [SOLUTIONS_DETAILS.STRATEGIC_OFFSHORING.default],
  tw: [SOLUTIONS_DETAILS.STRATEGIC_OFFSHORING.default],
  default: [
    SOLUTIONS_DETAILS.ACTIVE_RECRUITMENT.default,
    SOLUTIONS_DETAILS.STRATEGIC_OFFSHORING.default,
  ],
};

export const TALENT_HUNT = 'talenthunt';

/*
Fields for a menu item:
- disabled:       (optional) [boolean]
                  Whether this menu item is disabled or not
- children:       (optional) [array]
                  An array of children menu items. `type` is not required here.
- isPublicOnly:   (optional) [boolean]
                  Show this menu item only if no user is logged in
- label:          (required) [string]
                  Label of a menu item
- canBeDisabled:  (required if !isPublicOnly) [boolean]
                  Whether it's possible for this menu item to be disabled based on certain conditions
- to:             (required) [string]
                  URL of menu item
- type:           (required only if item has no children) [string]
                  Whether this menu item should be on the menu bar or under the user menu item
*/

export type MenuItem = {
  value: string;
  label:
    | {
        id: string;
        defaultMessage: string;
      }
    | string
    | React.ReactElement;
  type?: MenuType;
  isExternal?: boolean;
  canBeDisabled?: boolean;
  disabled?: boolean;
  optional?: boolean;
  isPublicOnly?: boolean;
  badge?: MenuBadge;
  icon?: IconNames;
  onClick?: () => void;
} & ({ to: string; children?: never } | { to?: never; children: MenuItem[] });

const menuItemLabels = defineMessages({
  createJob: {
    id: 'modules.Platform.MenuItems.create.job',
    defaultMessage: 'Create Job',
  },
  dashboard: {
    id: 'interactive-dashboard',
    defaultMessage: 'Dashboard',
  },
  companyDashboard: {
    id: 'modules.Platform.MenuItems.company.dashboard',
    defaultMessage: 'Company Dashboard',
  },
  ats: {
    id: 'modules.Platform.MenuItems.application.tracking.system',
    defaultMessage: 'Application Tracking System',
  },
  findTalent: {
    id: 'modules.Platform.MenuItems.find.talent',
    defaultMessage: 'Find Talent',
  },
  groupApplications: {
    id: 'modules.Platform.MenuItems.group.applications',
    defaultMessage: 'Applications',
  },
  groupJobs: {
    id: 'modules.Platform.MenuItems.manage.jobs',
    defaultMessage: 'Manage Jobs',
  },
  groupMembers: {
    id: 'modules.Platform.MenuItems.manage.members',
    defaultMessage: 'Manage Members',
  },
  companyProfile: {
    id: 'interactive-company-profile',
    defaultMessage: 'Company Profile',
  },
  companyTeam: {
    id: 'interactive-company-team',
    defaultMessage: 'Company Team',
  },
  onboarding: {
    id: 'modules.Platform.MenuItems.create.company',
    defaultMessage: 'Create Company',
  },
  resources: {
    id: 'interactive-resources',
    defaultMessage: 'Resources',
  },
  settings: {
    id: 'interactive-account-setting',
    defaultMessage: 'Account Settings',
  },
  solutions: {
    id: 'interactive-solutions',
    defaultMessage: 'Solutions',
  },
  eats: {
    id: 'modules.Platform.MenuItems.eats',
    defaultMessage: 'Manage Candidates',
  },
  cvFinder: {
    id: 'text-cv-finder',
    defaultMessage: 'CV Finder (Talent Search)',
  },
  features: {
    id: 'interactive-features',
    defaultMessage: 'FEATURES',
  },
});

export const MenuType = {
  general: 'general-nav',
  user: 'user-nav',
} as const;

export type MenuType = (typeof MenuType)[keyof typeof MenuType];

export type MenuBadge = {
  label: MessageDescriptor;
  status: BadgeStatusVariant;
};

export const MENU_ITEMS: {
  [key: string]: MenuItem;
} = {
  createJob: {
    value: 'createJob',
    label: menuItemLabels.createJob,
    canBeDisabled: true,
    to: '/job/create',
    type: MenuType.general,
  },
  dashboard: {
    value: 'dashboard',
    label: menuItemLabels.dashboard,
    canBeDisabled: false,
    to: '/dashboard',
    type: MenuType.general,
  },
  features: {
    value: 'features',
    label: menuItemLabels.features,
    canBeDisabled: false,
    to: '/features',
    type: MenuType.general,
  },
  cvFinder: {
    value: 'cvFinder',
    label: menuItemLabels.cvFinder,
    canBeDisabled: false,
    to: '/talent-search',
    type: MenuType.general,
  },
  findTalent: {
    value: 'findTalent',
    label: menuItemLabels.findTalent,
    canBeDisabled: false,
    to: '/talents',
    type: MenuType.general,
  },
  groupApplications: {
    value: 'groupApplications',
    label: menuItemLabels.groupApplications,
    canBeDisabled: false,
    to: '/group/applications',
    type: MenuType.general,
  },
  companyProfile: {
    value: 'companyProfile',
    label: menuItemLabels.companyProfile,
    canBeDisabled: true,
    to: '/company/edit',
    type: MenuType.user,
    icon: 'ri-building-line',
  },
  companyTeam: {
    value: 'companyTeam',
    label: menuItemLabels.companyTeam,
    canBeDisabled: true,
    to: '/company/team',
    type: MenuType.user,
    icon: 'ri-group2-line',
  },
  onboarding: {
    value: 'onboarding',
    label: menuItemLabels.onboarding,
    canBeDisabled: true,
    to: '/onboarding',
    type: MenuType.general,
  },
  settings: {
    value: 'settings',
    label: menuItemLabels.settings,
    canBeDisabled: false,
    to: '/settings',
    type: MenuType.user,
    icon: 'ri-settings-line',
  },
  solutions: {
    value: 'solutions',
    label: menuItemLabels.solutions,
    canBeDisabled: true,
    type: MenuType.general,
    children:
      SOLUTIONS?.[config?.COUNTRY as SupportedCountry] ?? SOLUTIONS.default,
  },
};

export const USER_WITHOUT_COMPANY_MENU_ITEMS = compact([
  MENU_ITEMS.dashboard,
  MENU_ITEMS.solutions,
  MENU_ITEMS.settings,
]);

export const COMPANY_MENU_ITEMS = compact([
  MENU_ITEMS.dashboard,
  MENU_ITEMS.features,
  MENU_ITEMS.cvFinder,
  MENU_ITEMS.solutions,
  MENU_ITEMS.settings,
  MENU_ITEMS.companyProfile,
  MENU_ITEMS.companyTeam,
]);

export const TALENTHUNT_MENU_ITEMS = compact([
  MENU_ITEMS.dashboard,
  MENU_ITEMS.applications,
  MENU_ITEMS.solutions,
  MENU_ITEMS.settings,
  MENU_ITEMS.companyProfile,
  MENU_ITEMS.companyTeam,
]);

export const RECRUITER_COMPANY_MENU_ITEMS = compact([
  MENU_ITEMS.dashboard,
  MENU_ITEMS.features,
  MENU_ITEMS.cvFinder,
  MENU_ITEMS.solutions,
  MENU_ITEMS.settings,
  MENU_ITEMS.companyProfile,
  MENU_ITEMS.companyTeam,
]);

export const RECRUITER_COMPANY_TALENTHUNT_MENU_ITEMS = compact([
  MENU_ITEMS.dashboard,
  MENU_ITEMS.applications,
  MENU_ITEMS.solutions,
  MENU_ITEMS.settings,
  MENU_ITEMS.companyProfile,
  MENU_ITEMS.companyTeam,
]);
