import React, { ReactNode } from 'react';
import { Flex } from 'glints-aries/es';
import { InlineError, TextInput, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space12 } from 'glints-aries/es/@next/utilities/spacing';
import { FieldPath, useController } from 'react-hook-form';
import styled from 'styled-components';

import {
  useCompanyVerificationFormContext,
  useIsViewOnlyMode,
} from './FormContext';
import { FormContentStyles } from './styles.sc';
import { CompanyVerificationFormState } from './types';

type TextFieldProps = {
  title: ReactNode;
  isRequired?: boolean;
  helperText?: ReactNode;
  fieldName: FieldPath<CompanyVerificationFormState>;
};
const TextFieldContainer = styled.div`
  max-width: 400px;
`;
export default function TextField({
  title,
  isRequired,
  helperText,
  fieldName,
}: TextFieldProps) {
  const { control } = useCompanyVerificationFormContext();
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
  });
  const isViewOnly = useIsViewOnlyMode();
  return (
    <Flex flexDirection="column" gap={space12}>
      <Typography variant="body2">
        {title}
        {isRequired && <FormContentStyles.Asterisk />}
      </Typography>
      <Flex flexDirection="column" gap={space4}>
        <TextFieldContainer>
          <TextInput
            name={fieldName}
            value={(value || undefined) as string | undefined}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            disabled={isViewOnly}
          />
        </TextFieldContainer>
        {error?.message && <InlineError text={error.message} />}
        <If condition={helperText}>
          <Typography variant="subtitle2" color={Neutral.B40}>
            {helperText}
          </Typography>
        </If>
      </Flex>
    </Flex>
  );
}
