import React, { Suspense, useState } from 'react';

import useGetCompanyVerificationInfo from '../../common/hooks/useGetCompanyVerificationInfo';
import CompanyVerificationModalVariantController from '../../modules/CompanyDashboardV2/CompanyHeader/components/CompanyVerificationModalVariantController';
import ErrorBoundaryWithCrashReporting from '../Error/ErrorBoundaryWithCrashReporting';
const CompanyVerificationRejectedModal = React.lazy(
  () => import('./CompanyVerificationRejectedModal')
);
const CompanyVerificationSuccessModal = React.lazy(
  () => import('./CompanyVerificationSuccessModal')
);

function RenderCompanyVerificationModalsComponent() {
  const {
    modals: {
      canShowCompanyVerificationRejectedModal,
      canShowCompanyVerificationSuccessModal,
    },
  } = useGetCompanyVerificationInfo();
  const [
    { closeRejectionModal, isCompanyVerificationModalOpen, closeSuccessModal },
    setState,
  ] = useState({
    closeRejectionModal: false,
    closeSuccessModal: false,
    isCompanyVerificationModalOpen: false,
  });

  const [openCompanyVerificationModal, closeCompanyVerificationModal] = [
    () => {
      setState(prevState => ({
        ...prevState,
        isCompanyVerificationModalOpen: true,
      }));
    },
    () => {
      setState(prevState => ({
        ...prevState,
        closeRejectionModal: true,
        isCompanyVerificationModalOpen: false,
      }));
    },
  ];
  return (
    <ErrorBoundaryWithCrashReporting fallback={null}>
      <Suspense fallback={null}>
        <CompanyVerificationModalVariantController
          isOpen={isCompanyVerificationModalOpen}
          onClose={closeCompanyVerificationModal}
        />
        {canShowCompanyVerificationRejectedModal && !closeRejectionModal && (
          <CompanyVerificationRejectedModal
            openVerificationModal={openCompanyVerificationModal}
            closeModal={() => {
              setState(prevState => ({
                ...prevState,
                closeRejectionModal: true,
              }));
            }}
          />
        )}
        {canShowCompanyVerificationSuccessModal && !closeSuccessModal && (
          <CompanyVerificationSuccessModal
            closeModal={() => {
              setState(prevState => ({
                ...prevState,
                closeSuccessModal: true,
              }));
            }}
          />
        )}
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}

export default function RenderCompanyVerificationModals() {
  return (
    <ErrorBoundaryWithCrashReporting fallback={null}>
      <Suspense fallback={null}>
        <RenderCompanyVerificationModalsComponent />
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}
