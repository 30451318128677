import React, { Suspense } from 'react';
import { ApolloError } from '@apollo/client';
import { Flex } from 'glints-aries/es';
import {
  ButtonGroup,
  OutlineButton,
  PrimaryButton,
  Spinner,
  Typography,
} from 'glints-aries/es/@next';
import { Blue } from 'glints-aries/es/@next/utilities/colors';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { compact } from 'lodash-es';
import { useWatch } from 'react-hook-form';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from 'react-intl';
import { createStateContext } from 'react-use';

import useGetCompanyVerificationInfo from '../../../common/hooks/useGetCompanyVerificationInfo';
import {
  CompanyVerificationMethod,
  VerificationMethod,
} from '../../../common/interfaces/entities';
import {
  useCompanyVerificationFormContext,
  useIsViewOnlyMode,
} from './FormContext';
import FormProvider from './FormProvider';
import useCompanyVerificationSubmittedModal from './hooks/useCompanyVerificationSubmittedModal';
import useGetCompanyVerificationMethods from './hooks/useGetCompanyVerificationMethods';
import useRequestCompanyVerification from './hooks/useRequestCompanyVerification';
import useSubmitButtonEnabled from './hooks/useSubmitButtonEnabled';
import {
  FormContentStyles,
  PaddedContainer,
  StyledLoadingModal,
  StyledModal,
  TypographyWithBulletPoint,
  VerificationModalBanner,
} from './styles.sc';
import {
  CompanyVerificationFormState,
  VerifyWithProofPersonalDocumentType,
} from './types';
import { getAssetFileNameFromURL, isURL } from './utils';
import { VerificationMethodCard } from './VerificationMethodCard';
import VerifyWithLegalDocumentsForm from './VerifyWithLegalDocumentsForm';
import VerifyWithProofOfOwnershipForm from './VerifyWithProofOfOwnershipForm';

const messages = defineMessages({
  modalTitle: {
    id: 'text-verify-company',
    defaultMessage: 'Verify Company',
  },
});

const verificationMethodToText: Record<VerificationMethod, MessageDescriptor> =
  {
    [VerificationMethod.LEGAL_DOCUMENTS]: {
      id: 'text-verify-with-legal-documents',
      defaultMessage: 'Verify with Legal Documents',
    },
    [VerificationMethod.PROOF_OF_OWNERSHIP]: {
      id: 'text-verify-with-proof-of',
      defaultMessage: 'Verify with Proof of Ownership',
    },
  } as const;

const [useErrorContext, ErrorProvider] = createStateContext<ApolloError | null>(
  null
);

type CompanyVerificationModalV2Props = {
  onClose: () => void;
};

function convertPopularityTagToMessageDescriptor(
  verificationMethods: CompanyVerificationMethod[]
) {
  return verificationMethods.map(verificationMethod => ({
    ...verificationMethod,
    popularityTag: verificationMethod.popularityTag
      ? ({
          id: verificationMethod.popularityTag.key,
          defaultMessage: verificationMethod.popularityTag.defaultMessage,
        } as MessageDescriptor)
      : null,
  }));
}

function RenderCompanyBenefits() {
  const { companyVerificationMethods } = useGetCompanyVerificationMethods();
  const { setValue, control } = useCompanyVerificationFormContext();
  const selectedVerificationMethod = useWatch({
    name: 'verificationMethod',
    control,
  });
  const { verificationInfo } = useGetCompanyVerificationInfo();
  const isViewOnly = useIsViewOnlyMode();
  const availableTierUpgrades =
    verificationInfo?.tierUpgrade?.availableTierUpgrade;
  const verificationMethodsFiltered = convertPopularityTagToMessageDescriptor(
    availableTierUpgrades ?? companyVerificationMethods
  ).filter(
    companyVerificationMethod =>
      !isViewOnly ||
      companyVerificationMethod.verificationMethod ===
        selectedVerificationMethod
  );

  return (
    <>
      <If condition={verificationMethodsFiltered.length > 1}>
        <Typography
          variant="body1"
          style={{
            marginTop: '20px ',
          }}
        >
          <FormattedMessage
            id="text-choose-verification-method"
            defaultMessage="Please choose a verification method to proceed."
          />
        </Typography>
      </If>

      <Flex
        gap={space12}
        style={{
          margin: '20px 0',
        }}
      >
        {verificationMethodsFiltered.map(props => (
          <VerificationMethodCard
            key={props.verificationMethod}
            {...props}
            active={selectedVerificationMethod === props.verificationMethod}
            onClick={
              isViewOnly
                ? undefined
                : () => {
                    setValue('verificationMethod', props.verificationMethod, {
                      shouldValidate: true,
                    });
                  }
            }
          />
        ))}
      </Flex>
    </>
  );
}

function FooterButtons({
  onClose,
}: Pick<CompanyVerificationModalV2Props, 'onClose'>) {
  const { requestCompanyVerificationRequest, loading } =
    useRequestCompanyVerification();
  const { handleSubmit } = useCompanyVerificationFormContext();
  const { openModal } = useCompanyVerificationSubmittedModal();
  const isViewOnly = useIsViewOnlyMode();
  const isSubmitEnabled = useSubmitButtonEnabled();
  const [, setError] = useErrorContext();

  if (isViewOnly) {
    return (
      <ButtonGroup>
        <PrimaryButton onClick={onClose}>
          <Typography variant="button">
            <FormattedMessage id="interactive-close" defaultMessage="Close" />
          </Typography>
        </PrimaryButton>
      </ButtonGroup>
    );
  }

  function getFileName(value: string) {
    return isURL(value) ? getAssetFileNameFromURL(value) || '' : value;
  }

  const onSubmit = handleSubmit(async (data: CompanyVerificationFormState) => {
    setError(null);
    await requestCompanyVerificationRequest({
      input:
        data.verificationMethod === VerificationMethod.LEGAL_DOCUMENTS
          ? {
              verificationMethod: data.verificationMethod,
              legalDocumentsData: {
                ...data.verifyWithLegalDocuments,
                companyNPWPDoc: getFileName(
                  data.verifyWithLegalDocuments.companyNPWPDoc
                ),
                companyNIBNumber:
                  data.verifyWithLegalDocuments.companyNIBNumber || null,
              },
            }
          : {
              verificationMethod: data.verificationMethod,
              proofOfOwnershipData: {
                personalNIBNumber:
                  data.verifyWithProofOfOwnership.personalNIBNumber || null,
                businessLocationType:
                  data.verifyWithProofOfOwnership.businessLocationType,
                ...(data.verifyWithProofOfOwnership.personalDocumentType ===
                VerifyWithProofPersonalDocumentType.NPWP
                  ? {
                      personalNPWPDoc: getFileName(
                        data.verifyWithProofOfOwnership.personalNPWPDoc
                      ),
                      personalNPWPNumber:
                        data.verifyWithProofOfOwnership.personalNPWPNumber,
                    }
                  : {
                      ktpDoc: getFileName(
                        data.verifyWithProofOfOwnership.ktpDoc
                      ),
                      ktpNumber: data.verifyWithProofOfOwnership.ktpNumber,
                    }),
                businessActivityImages: compact(
                  data.verifyWithProofOfOwnership.businessActivityImages
                ).map(image =>
                  isURL(image)
                    ? (getAssetFileNameFromURL(image) as string)
                    : image
                ),
              },
            },
      onCompleted: data => {
        onClose();
        if (data.lastVerificationRequest) {
          openModal({
            submittedDate: data.lastVerificationRequest.createdAt,
            isTierUpgrade: Boolean(data.tierUpgrade),
          });
        }
      },
      onError: error => {
        setError(error);
        document
          .getElementsByClassName('verification-modal-banner')[0]
          ?.scrollIntoView({
            behavior: 'smooth',
          });
      },
    });
  });

  return (
    <ButtonGroup>
      <OutlineButton onClick={onClose}>
        <Typography variant="button">
          <FormattedMessage id="text-cancel" defaultMessage="Cancel" />
        </Typography>
      </OutlineButton>
      <PrimaryButton
        disabled={!isSubmitEnabled}
        loading={loading}
        onClick={onSubmit}
      >
        <Typography variant="button">
          <FormattedMessage id="text-submit" defaultMessage="Submit" />
        </Typography>
      </PrimaryButton>
    </ButtonGroup>
  );
}

function RenderFormContent() {
  const selectedVerificationMethod = useWatch({
    name: 'verificationMethod',
    control: useCompanyVerificationFormContext().control,
  });
  return (
    <>
      <FormContentStyles.VisibilityContainer
        visible={
          selectedVerificationMethod === VerificationMethod.LEGAL_DOCUMENTS
        }
      >
        <VerifyWithLegalDocumentsForm />
      </FormContentStyles.VisibilityContainer>
      <FormContentStyles.VisibilityContainer
        visible={
          selectedVerificationMethod === VerificationMethod.PROOF_OF_OWNERSHIP
        }
      >
        <VerifyWithProofOfOwnershipForm />
      </FormContentStyles.VisibilityContainer>
    </>
  );
}
function LoadingModal() {
  return (
    <StyledLoadingModal isOpen={true}>
      <Spinner size="large" fill={Blue.S99} />
    </StyledLoadingModal>
  );
}

function RenderRejectionReason({
  rejectionReasonText,
}: {
  rejectionReasonText: string;
}) {
  const chunks = compact(rejectionReasonText.split('\n'));

  return (
    <div>
      {chunks.map((value, index) => (
        <TypographyWithBulletPoint
          key={`rejection-reason-${index}`}
          variant="body2"
        >
          {value}
        </TypographyWithBulletPoint>
      ))}
    </div>
  );
}

function VerificationBanner() {
  const { verificationInfo } = useGetCompanyVerificationInfo();
  const [submissionError] = useErrorContext();

  return (
    <VerificationModalBanner
      dismissable={false}
      status={
        verificationInfo?.isRejected ||
        verificationInfo?.tierUpgrade?.isUpgradeRejected ||
        submissionError
          ? 'critical'
          : undefined
      }
      className="verification-modal-banner"
    >
      <Choose>
        <When condition={submissionError}>
          <Typography variant="body1">
            <FormattedMessage
              id="text-something-went-wrong"
              defaultMessage="Something went wrong. Please try again later."
            />
          </Typography>
        </When>
        <When
          condition={
            verificationInfo?.lastVerificationRequest?.rejectionReasonText
          }
        >
          {verificationInfo?.lastVerificationRequest?.rejectionReasonText && (
            <Flex flexDirection="column">
              <Typography variant="body1">
                <FormattedMessage
                  id="text-we-were-unable-to-verify"
                  defaultMessage="We were unable to verify the information provided due to the following reason:"
                />
              </Typography>
              <RenderRejectionReason
                rejectionReasonText={
                  verificationInfo.lastVerificationRequest.rejectionReasonText
                }
              />
            </Flex>
          )}
        </When>
        <Otherwise>
          <FormattedMessage
            id="text-verification-will-take-around"
            defaultMessage="Verification will take around 2 business days"
          />
        </Otherwise>
      </Choose>
    </VerificationModalBanner>
  );
}

function RenderTitle() {
  const { verificationInfo } = useGetCompanyVerificationInfo();
  const verificationMethod = useWatch({
    name: 'verificationMethod',
    control: useCompanyVerificationFormContext().control,
  });
  const message = verificationInfo?.tierUpgrade
    ? verificationMethodToText[verificationMethod]
    : messages.modalTitle;

  return <FormattedMessage {...message} />;
}

function ModalComponent({ onClose }: CompanyVerificationModalV2Props) {
  return (
    <FormProvider>
      <ErrorProvider>
        <StyledModal
          isOpen={true}
          customActions={<FooterButtons onClose={onClose} />}
          onClose={onClose}
          closeOnClickOutside={true}
          header={<RenderTitle />}
        >
          <PaddedContainer>
            <VerificationBanner />
            <RenderCompanyBenefits />
          </PaddedContainer>
          <FormContentStyles.BackgroundContainer>
            <RenderFormContent />
          </FormContentStyles.BackgroundContainer>
        </StyledModal>
      </ErrorProvider>
    </FormProvider>
  );
}

export default function CompanyTierVerificationModal(
  props: CompanyVerificationModalV2Props
) {
  return (
    <Suspense fallback={<LoadingModal />}>
      <ModalComponent {...props} />
    </Suspense>
  );
}
