import React, { useEffect } from 'react';
import { Flex } from 'glints-aries/es';
import { Card, Icon, OutlineButton, Typography } from 'glints-aries/es/@next';
import { Green, Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space12,
  space24,
} from 'glints-aries/es/@next/utilities/spacing';
import { useController, useWatch } from 'react-hook-form';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';

import { WhatsAppGreen } from '../../../common/color';
import { ASSET_TYPES } from '../../../common/constants';
import { BusinessVerificationLocationType } from '../../../common/interfaces/entities';
import { User } from '../../../models/User';
import { getUser } from '../../../selectors/user';
import { Variant } from '../WhatsappVerificationModal/components/VerificationSuccessStep/constants';
import { useWhatsappVerificationModal } from '../WhatsappVerificationModal/useWhatsappVerificationModal';
import FileUploader from './FileUploader';
import { useCompanyVerificationFormContext } from './FormContext';
import MultiFileUploader from './MultiFileUploader';
import RadioGroup from './RadioGroup';
import { FormContentStyles } from './styles.sc';
import TextField from './TextField';
import { VerifyWithProofPersonalDocumentType } from './types';
import VerificationPopover from './VerificationPopover';

const requiredFieldsMessages: MessageDescriptor[] = [
  {
    id: 'text-whatsapp-verification',
    defaultMessage: 'WhatsApp Verification',
  },
  {
    id: 'text-personal-npwp-or-ktp',
    defaultMessage: 'Personal NPWP/KTP',
  },
  {
    id: 'text-personal-npwp-ktp-number',
    defaultMessage: 'Personal NPWP/KTP Number',
  },
  {
    id: 'text-personal-nib-optional',
    defaultMessage: 'Personal NIB (Optional)',
  },
  {
    id: 'text-offline-online-bussiness-activity-images',
    defaultMessage: 'Offline/Online Business Activity Images',
  },
];

const personalDocumentTypeToText = {
  [VerifyWithProofPersonalDocumentType.NPWP]: {
    optionText: {
      id: 'text-personal-npwp',
      defaultMessage: 'Personal NPWP',
    },
    documentNumberText: {
      id: 'text-personal-npwp-number',
      defaultMessage: 'Personal NPWP Number',
    },
    tooltip: {
      title: {
        id: 'text-personal-npwp',
        defaultMessage: 'Personal NPWP',
      },
      description: {
        id: 'text-colored-newest-format-preferred',
        defaultMessage:
          'Colored, newest format preferred (e-NPWP) that has the QR code.',
      },
      images: [
        {
          src: 'images/tier-verification/personal-npwp-image.png',
        },
      ],
    },
  },
  [VerifyWithProofPersonalDocumentType.KTP]: {
    optionText: {
      id: 'text-personal-ktp',
      defaultMessage: 'Personal KTP',
    },
    documentNumberText: {
      id: 'text-personal-ktp-number',
      defaultMessage: 'Personal KTP Number',
    },
    tooltip: {
      title: {
        id: 'text-personal-ktp',
        defaultMessage: 'Personal KTP',
      },
      description: {
        id: 'text-colored-with-full-id',
        defaultMessage: 'Colored with full ID in view',
      },
      images: [
        {
          src: 'images/tier-verification/personal-ktp-image.png',
        },
      ],
    },
  },
} as const;

const businessLocationTypeToText = {
  [BusinessVerificationLocationType.ONLINE]: {
    optionText: {
      id: 'text-online-business-activity',
      defaultMessage: 'Online Business Activity Images (Min {count})',
    },
    tooltip: {
      title: {
        id: 'text-online-business-activity-title',
        defaultMessage: 'Online Business Activity Images',
      },
      description: {
        id: 'text-online-business-activity-requirement',
        defaultMessage:
          'Attaching online signage of business operations (photos and/or videos). Example: Business Instagram Account, Shopee Seller Account, and others similar.',
      },
      images: [
        {
          src: 'images/tier-verification/online-business-activity-image-1.png',
          width: '182px',
        },
        {
          src: 'images/tier-verification/online-business-activity-image-2.png',
          width: '182px',
        },
      ],
      imagesDirection: 'row' as const,
    },
  },
  [BusinessVerificationLocationType.OFFLINE]: {
    optionText: {
      id: 'text-offline-business-activity',
      defaultMessage: 'Offline Business Activity Images (Min {count})',
    },
    tooltip: {
      title: {
        id: 'text-offline-business-activity-title',
        defaultMessage: 'Offline Business Activity Images',
      },
      description: {
        id: 'text-offline-business-activity-requirement',
        defaultMessage:
          'Attaching offline business activity operations (photos and/or videos). Example: Business Buildings, Store Operations, and others similar.',
      },
      images: [
        {
          src: 'images/tier-verification/offline-business-activity-image-1.png',
        },
        {
          src: 'images/tier-verification/offline-business-activity-image-2.png',
        },
      ],
      imagesDirection: 'column' as const,
    },
  },
};

const MIN_BUSINESS_ACTIVITY_IMAGES = 2;

function RenderPersonalDocumentFields() {
  const selectedDocumentType = useWatch({
    control: useCompanyVerificationFormContext().control,
    name: 'verifyWithProofOfOwnership.personalDocumentType',
  });
  return (
    <>
      <Flex flexDirection="column" gap={space12}>
        <RadioGroup
          fieldName="verifyWithProofOfOwnership.personalDocumentType"
          title={
            <FormattedMessage
              id="text-choose-1-option"
              defaultMessage="Choose 1 Option"
            />
          }
          isRequired={true}
          options={Object.values(VerifyWithProofPersonalDocumentType).map(
            documentType => ({
              label: (
                <FormattedMessage
                  {...personalDocumentTypeToText[documentType].optionText}
                />
              ),
              tooltip: (
                <VerificationPopover
                  title={
                    <FormattedMessage
                      {...personalDocumentTypeToText[documentType].tooltip
                        .title}
                    />
                  }
                  items={[
                    {
                      title: (
                        <FormattedMessage
                          id="text-requirements"
                          defaultMessage="Requirements"
                        />
                      ),
                      items: [
                        <FormattedMessage
                          key={`${documentType}.tooltip`}
                          {...personalDocumentTypeToText[documentType].tooltip
                            .description}
                        />,
                      ],
                    },
                  ]}
                  images={personalDocumentTypeToText[
                    documentType
                  ].tooltip.images.slice()}
                />
              ),
              value: documentType,
            })
          )}
        />
        <FileUploader
          key={`${selectedDocumentType}.doc`}
          assetType={
            selectedDocumentType === VerifyWithProofPersonalDocumentType.NPWP
              ? ASSET_TYPES.PERSONAL_NPWP_DOCUMENT
              : ASSET_TYPES.KTP_DOCUMENT
          }
          fieldName={
            selectedDocumentType === VerifyWithProofPersonalDocumentType.NPWP
              ? 'verifyWithProofOfOwnership.personalNPWPDoc'
              : 'verifyWithProofOfOwnership.ktpDoc'
          }
          buttonText={
            <FormattedMessage
              id="interactive-select-file"
              defaultMessage="Select File"
            />
          }
          title={null}
          hideTitle={true}
        />
      </Flex>

      <TextField
        fieldName={
          selectedDocumentType === VerifyWithProofPersonalDocumentType.KTP
            ? 'verifyWithProofOfOwnership.ktpNumber'
            : 'verifyWithProofOfOwnership.personalNPWPNumber'
        }
        key={`${selectedDocumentType}.docNumber`}
        title={
          <FormattedMessage
            {...personalDocumentTypeToText[selectedDocumentType]
              .documentNumberText}
          />
        }
        isRequired={true}
      />
    </>
  );
}

export default function VerifyWithProofOfOwnershipForm() {
  return (
    <FormContentStyles.Container>
      <FormContentStyles.RequiredSectionContainer>
        <Card>
          <Card.Section>
            <Flex flexDirection="column" gap={space12}>
              <Typography variant="body2">
                <FormattedMessage
                  id="text-required"
                  defaultMessage="Required"
                />
              </Typography>
              <Typography variant="body1" color={Neutral.B40} as="div">
                {requiredFieldsMessages.map((message, index) => (
                  <FormContentStyles.TypographyWithBulletPoint key={index}>
                    <FormattedMessage {...message} />
                  </FormContentStyles.TypographyWithBulletPoint>
                ))}
              </Typography>
            </Flex>
          </Card.Section>
        </Card>
      </FormContentStyles.RequiredSectionContainer>
      <FormContentStyles.FullWidthCardContainer>
        <Card>
          <Card.Section>
            <Flex flexDirection="column" gap={space24}>
              <WhatsAppVerificationStatus />
              <RenderPersonalDocumentFields />
              <TextField
                fieldName="verifyWithProofOfOwnership.personalNIBNumber"
                title={
                  <FormattedMessage
                    id="text-personal-nib"
                    defaultMessage="Personal NIB"
                  />
                }
                helperText={
                  <FormattedMessage
                    id="text-providing-your-nib-speeds"
                    defaultMessage="Providing your NIB speeds up verification"
                  />
                }
              />
              <RadioGroup
                fieldName="verifyWithProofOfOwnership.businessLocationType"
                direction="column"
                title={
                  <FormattedMessage
                    id="text-choose-1-option"
                    defaultMessage="Choose 1 Option"
                  />
                }
                isRequired={true}
                options={Object.values(BusinessVerificationLocationType).map(
                  locationType => ({
                    label: (
                      <FormattedMessage
                        {...businessLocationTypeToText[locationType].optionText}
                        values={{
                          count: MIN_BUSINESS_ACTIVITY_IMAGES,
                        }}
                      />
                    ),
                    tooltip: (
                      <VerificationPopover
                        title={
                          <FormattedMessage
                            {...businessLocationTypeToText[locationType].tooltip
                              .title}
                          />
                        }
                        items={[
                          {
                            title: (
                              <FormattedMessage
                                id="text-requirements"
                                defaultMessage="Requirements"
                              />
                            ),
                            items: [
                              <FormattedMessage
                                key={`${locationType}.tooltip`}
                                {...businessLocationTypeToText[locationType]
                                  .tooltip.description}
                              />,
                            ],
                          },
                        ]}
                        imagesDirection={
                          businessLocationTypeToText[locationType].tooltip
                            .imagesDirection
                        }
                        images={businessLocationTypeToText[
                          locationType
                        ].tooltip.images.slice()}
                      />
                    ),
                    value: locationType,
                  })
                )}
              />
              <MultiFileUploader
                title={null}
                hideTitle={true}
                assetType={ASSET_TYPES.BUSINESS_IMAGE}
                fieldName="verifyWithProofOfOwnership.businessActivityImages"
                maxImages={5}
              />
            </Flex>
          </Card.Section>
        </Card>
      </FormContentStyles.FullWidthCardContainer>
    </FormContentStyles.Container>
  );
}

function WhatsAppVerificationStatus() {
  const { control } = useCompanyVerificationFormContext();
  const user: User = useSelector(getUser);
  const { openModal, whatsappVerificationModal } =
    useWhatsappVerificationModal();
  const {
    field: {
      value: isWhatsappVerified,
      onChange: setWhatsappVerificationStatus,
    },
  } = useController({
    name: 'verifyWithProofOfOwnership.isWhatsAppVerified',
    control,
    defaultValue: Boolean(user.isWhatsappVerified),
  });

  useEffect(() => {
    setWhatsappVerificationStatus(user.isWhatsappVerified);
  }, [setWhatsappVerificationStatus, user.isWhatsappVerified]);

  return (
    <>
      {whatsappVerificationModal}
      <Flex flexDirection="column" gap={space12}>
        <Typography variant="body2">
          <FormattedMessage
            id="text-whatsapp-verification"
            defaultMessage="WhatsApp Verification"
            tagName="span"
          />
          <FormContentStyles.Asterisk />
        </Typography>
        <FormContentStyles.WhatsAppVerificationStatusContainer>
          <Flex justifyContent="space-between" alignItems="center">
            <div>
              <Typography variant="caption">
                <FormattedMessage
                  id="text-verification-status"
                  defaultMessage="Verification Status"
                />
              </Typography>
              <Flex gap={space4}>
                <Icon
                  name="ri-checkbox-circle-fill"
                  fill={isWhatsappVerified ? Green.B61 : Neutral.B85}
                  width={20}
                  height={20}
                />
                <Typography variant="subtitle2">
                  <Choose>
                    <When condition={isWhatsappVerified}>
                      <FormattedMessage
                        id="text-verified"
                        defaultMessage="Verified"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="text-unverified"
                        defaultMessage="Unverified"
                      />
                    </Otherwise>
                  </Choose>
                </Typography>
              </Flex>
            </div>
            <If condition={!isWhatsappVerified}>
              <OutlineButton
                onClick={() =>
                  openModal({
                    successStepVariant: Variant.NOTIFICATION,
                    successButtonText: {
                      id: 'interactive-continue-with-verification',
                      defaultMessage: 'Continue with Verification',
                    },
                  })
                }
                icon={
                  <Icon
                    name="ri-whatsapp-fill"
                    width={20}
                    fill={WhatsAppGreen}
                  />
                }
              >
                <Typography variant="button">
                  <FormattedMessage
                    id="text-verify-whatsapp"
                    defaultMessage="Verify WhatsApp"
                  />
                </Typography>
              </OutlineButton>
            </If>
          </Flex>
        </FormContentStyles.WhatsAppVerificationStatusContainer>
      </Flex>
    </>
  );
}
