import React, { ReactNode } from 'react';
import { Flex } from 'glints-aries/es';
import {
  OutlineButton,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space8, space12 } from 'glints-aries/es/@next/utilities/spacing';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import { Link } from 'react-router-dom';

import { CompanyStatus } from '../../../../common/constants';
import { useGetSessionCompany } from '../../../../common/hooks/useGetSessionCompany';
import { EmployerPurchaseProductType } from '../../../../common/interfaces/entities';
import { ROUTES } from '../../../../common/routes';
import { useCompanyVerificationModalContext } from '../../../../components/CompanyVerificationModals/CompanyVerificationModalProvider';
import VIPProfilePicture from '../../../../components/GlintsPicture/VIPProfilePicture';
import GlintsVIPBenefits from '../../SharedComponents/GlintsVIPBenefits';
import { GlintsVIPCustomModalHeader } from '../../SharedComponents/GlintsVIPCustomModalHeader';
import GlintsVIPFreeCreditBenefit from '../../SharedComponents/GlintsVIPFreeCreditBenefit';
import {
  PurchaseOrderPurchaseType,
  VIPMembershipPurchaseType,
} from '../../types';
import { CreditProductPurchaseOrderForAfterPayment } from '../types';
import { StyledButtonGroup } from './styles.sc';

const headerMessages: Record<VIPMembershipPurchaseType, MessageDescriptor> =
  defineMessages({
    [VIPMembershipPurchaseType.PURCHASED]: {
      id: 'text-vip-membership-activated',
      defaultMessage: 'VIP Membership Activated',
    },
    [VIPMembershipPurchaseType.EXTENDED]: {
      id: 'text-vip-membership-extended',
      defaultMessage: 'VIP Membership Extended',
    },
    [VIPMembershipPurchaseType.PENDING]: {
      id: 'text-vip-membership-pending',
      defaultMessage: 'VIP Membership Pending',
    },
  });

const titleMessages: Record<VIPMembershipPurchaseType, MessageDescriptor> =
  defineMessages({
    [VIPMembershipPurchaseType.PURCHASED]: {
      id: 'text-you-are-now-a',
      defaultMessage: 'You are now a VIP member!',
    },
    [VIPMembershipPurchaseType.EXTENDED]: {
      id: 'text-your-vip-membership-has',
      defaultMessage: 'Your VIP membership has been extended!',
    },
    [VIPMembershipPurchaseType.PENDING]: {
      id: 'text-vip-membership-pending-verification',
      defaultMessage: 'VIP Membership Pending Verification',
    },
  });

type Props = {
  creditProductPurchaseOrder: Pick<
    CreditProductPurchaseOrderForAfterPayment,
    | 'companyProductInventory'
    | 'purchaseProductSpecificDetails'
    | 'creditBenefit'
    | 'purchaseType'
    | 'activeSubscriptionInformation'
  >;
  onClose: () => void;
};

const ModalTitle = ({
  onClose,
  creditProductPurchaseOrder: { purchaseProductSpecificDetails },
}: Props) => {
  if (!purchaseProductSpecificDetails) {
    throw new Error('purchaseProductSpecificDetails is required');
  }

  return (
    <GlintsVIPCustomModalHeader
      onClose={onClose}
      title={headerMessages[purchaseProductSpecificDetails.purchaseType]}
    />
  );
};

const ModalFooter = ({ onClose }: { onClose: () => void }) => {
  const { sessionCompany } = useGetSessionCompany();
  const isCompanyVerified = sessionCompany?.status === CompanyStatus.VERIFIED;
  const { openCompanyVerificationModal } = useCompanyVerificationModalContext();

  const handleOpenCompanyVerificationModal = () => {
    onClose();
    openCompanyVerificationModal();
  };

  return (
    <StyledButtonGroup>
      <OutlineButton onClick={onClose}>
        <Typography variant="button">
          <FormattedMessage id="interactive-close" defaultMessage="Close" />
        </Typography>
      </OutlineButton>

      <Choose>
        <When condition={isCompanyVerified}>
          <Link
            to={`/${ROUTES.features}?tab=PURCHASES&purchasedTab=${EmployerPurchaseProductType.VIP_MEMBERSHIP}`}
          >
            <PrimaryButton onClick={onClose}>
              <Typography variant="button">
                <FormattedMessage
                  id="interactive-view-membership-details"
                  defaultMessage="View Membership Details"
                />
              </Typography>
            </PrimaryButton>
          </Link>
        </When>
        <Otherwise>
          <PrimaryButton onClick={handleOpenCompanyVerificationModal}>
            <Typography variant="button">
              <FormattedMessage
                id="text-verify-company"
                defaultMessage="Verify Company"
              />
            </Typography>
          </PrimaryButton>
        </Otherwise>
      </Choose>
    </StyledButtonGroup>
  );
};

const Bold = (...chunks: ReactNode[]) => (
  <Typography variant="body2" as="span" color={Neutral.B40}>
    {chunks}
  </Typography>
);

function GlintsVIPSuccessModalContentComponent({
  creditProductPurchaseOrder: {
    purchaseProductSpecificDetails,
    companyProductInventory,
    creditBenefit,
    purchaseType,
    activeSubscriptionInformation,
  },
}: Props) {
  const { formatDate } = useIntl();

  if (!purchaseProductSpecificDetails) {
    throw new Error('purchaseProductSpecificDetails is required');
  }

  const propsForGlintsVIPBenefitsComponent = creditBenefit
    ? ({
        includeFreeCreditBenefit: true,
        freeCreditAmount: creditBenefit.amount,
      } as const)
    : {};

  const isPurchaseTypePending =
    purchaseProductSpecificDetails.purchaseType ===
    VIPMembershipPurchaseType.PENDING;

  return (
    <div>
      <Flex flexDirection="column" alignItems="center">
        <Flex justifyContent="center" style={{ marginBottom: space12 }}>
          <VIPProfilePicture
            profilePictureSize={100}
            vipLogoWidth={42}
            vipLogoOffset={20}
            hasStarsBackground={!isPurchaseTypePending}
            hasPendingOrder={isPurchaseTypePending}
          />
        </Flex>
        <Typography variant="body2" color={Neutral.B18}>
          <FormattedMessage
            {...titleMessages[purchaseProductSpecificDetails.purchaseType]}
          />
        </Typography>
        <Typography variant="body1" color={Neutral.B40} as="div">
          <Choose>
            <When condition={isPurchaseTypePending}>
              <FormattedMessage
                id="text-your-vip-membership-will"
                defaultMessage="Your VIP membership will be activated when your company is verified.{br}
                To unlock VIP benefits, please verify your company."
                values={{ br: <br /> }}
              />
            </When>
            <When
              condition={
                purchaseType === PurchaseOrderPurchaseType.SUBSCRIPTION
              }
            >
              <Choose>
                <When
                  condition={
                    purchaseProductSpecificDetails.purchaseType ===
                    VIPMembershipPurchaseType.EXTENDED
                  }
                >
                  <FormattedMessage
                    id="text-your-subscription-will-begin"
                    defaultMessage="Your subscription will begin after your current membership ends."
                    tagName="p"
                  />
                  {activeSubscriptionInformation?.nextPaymentChargeDate && (
                    <FormattedMessage
                      id="text-thereafter-you-will-be"
                      defaultMessage="Thereafter, you will be billed monthly on <b>{date}</b>."
                      values={{
                        date: formatDate(
                          activeSubscriptionInformation.nextPaymentChargeDate,
                          {
                            month: 'short',
                            year: 'numeric',
                            day: '2-digit',
                          }
                        ),
                        b: Bold,
                      }}
                    />
                  )}
                </When>
                <Otherwise>
                  {activeSubscriptionInformation?.nextPaymentChargeDate && (
                    <FormattedMessage
                      id="text-you-will-be-billed"
                      defaultMessage="You will be billed monthly on <b>{date}</b>."
                      tagName="p"
                      values={{
                        date: formatDate(
                          activeSubscriptionInformation.nextPaymentChargeDate,
                          {
                            month: 'short',
                            year: 'numeric',
                            day: '2-digit',
                          }
                        ),
                        b: Bold,
                      }}
                    />
                  )}
                </Otherwise>
              </Choose>
            </When>
            <When
              condition={
                companyProductInventory?.trialInformationDetail &&
                companyProductInventory.trialInformationDetail.isActive &&
                companyProductInventory.trialInformationDetail.isExtended &&
                companyProductInventory.trialInformationDetail.endDate
              }
            >
              {companyProductInventory?.trialInformationDetail.endDate && (
                <FormattedMessage
                  id="text-your-membership-will-begin"
                  defaultMessage="
              Your membership will begin after your current trial ends on <bold>{date}</bold>."
                  values={{
                    date: formatDate(
                      companyProductInventory?.trialInformationDetail.endDate,
                      {
                        month: 'short',
                        year: 'numeric',
                        day: '2-digit',
                      }
                    ),
                    bold: Bold,
                  }}
                />
              )}
            </When>
            <Otherwise>
              <FormattedMessage
                id="text-your-membership-will-expire"
                defaultMessage="Your membership will expire on <bold>{date}</bold>."
                values={{
                  date: formatDate(companyProductInventory?.expiryDateTime, {
                    month: 'short',
                    year: 'numeric',
                    day: '2-digit',
                  }),
                  bold: Bold,
                }}
              />
            </Otherwise>
          </Choose>
        </Typography>
        <If
          condition={
            purchaseProductSpecificDetails.purchaseType !==
            VIPMembershipPurchaseType.PENDING
          }
        >
          <Typography variant="body1" color={Neutral.B40}>
            <FormattedMessage
              id="text-more-details-can-be"
              defaultMessage="More details can be found under Features > Purchased > VIP."
            />
          </Typography>
        </If>
      </Flex>
      <Choose>
        <When
          condition={
            purchaseProductSpecificDetails.purchaseType === 'PURCHASED'
          }
        >
          <>
            <Typography
              variant="body1"
              color={Neutral.B18}
              style={{ marginBottom: space8, marginTop: 20 }}
            >
              <FormattedMessage
                id="text-these-benefits-have-been"
                defaultMessage="These benefits have been unlocked:"
              />
            </Typography>
            <GlintsVIPBenefits
              includeComingSoon={true}
              {...propsForGlintsVIPBenefitsComponent}
            />
          </>
        </When>
        <When
          condition={
            purchaseProductSpecificDetails.purchaseType === 'EXTENDED' &&
            creditBenefit
          }
        >
          {creditBenefit && (
            <>
              <Typography
                variant="body1"
                color={Neutral.B18}
                style={{ marginBottom: space8, marginTop: 20 }}
              >
                <FormattedMessage
                  id="text-these-benefits-have-been"
                  defaultMessage="These benefits have been unlocked:"
                />
              </Typography>

              <GlintsVIPFreeCreditBenefit
                amount={creditBenefit.amount}
                style={{ width: '940px' }}
              />
            </>
          )}
        </When>
      </Choose>
    </div>
  );
}

export const GlintsVIPSuccessModalContent = Object.assign(
  GlintsVIPSuccessModalContentComponent,
  {
    ModalTitle,
    ModalFooter,
  }
);
