import React from 'react';
import { Tooltip, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../common/routes';

type Props = {
  isCompanyVerified: boolean;
  destination?: string;
  children?: React.ReactNode;
};

export const ConditionalProductUsageComponent = ({
  isCompanyVerified,
  destination = ROUTES.dashboardRoute,
  children,
}: Props) => (
  <Choose>
    <When condition={isCompanyVerified}>
      <Link to={`/${destination}`}>{children}</Link>
    </When>
    <Otherwise>
      <Tooltip
        preferredPosition="top-right"
        zIndex={1000}
        style={{ width: 'fit-content' }}
        content={
          <Typography variant="subtitle2">
            <FormattedMessage
              id="text-to-use-this-feature"
              defaultMessage="To use this feature, please verify your company."
            />
          </Typography>
        }
      >
        {children}
      </Tooltip>
    </Otherwise>
  </Choose>
);
