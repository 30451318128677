import { MessageDescriptor } from 'react-intl';

export const Variant = {
  LOGIN_OTP: 'LOGIN_OTP',
  NOTIFICATION: 'NOTIFICATION',
} as const;

export type Variant = (typeof Variant)[keyof typeof Variant];

export const Messages: Record<
  Variant,
  { body1: MessageDescriptor; body2: MessageDescriptor }
> = {
  [Variant.LOGIN_OTP]: {
    body1: {
      id: 'text-successful-otp-whatsapp',
      defaultMessage:
        'You have successfully opted in to receive your login OTP via Whatsapp.',
    },
    body2: {
      id: 'text-change-phone-number-via',
      defaultMessage:
        'If you would like to change your phone number, you can do so via: {br}<bold>Account Settings > Sign In & Security</bold>',
    },
  },
  [Variant.NOTIFICATION]: {
    body1: {
      id: 'text-you-have-successfully-opted',
      defaultMessage:
        'You have successfully opted in to receive important notifications and updates.',
    },
    body2: {
      id: 'text-customize-what-kind-of',
      defaultMessage:
        'Customize what kind of notifications you would like to receive in:{br}<bold>Account Settings > Notification Preferences</bold>',
    },
  },
};
